import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  Divider,
  Modal,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
  Tab,
  Tabs,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DailyIframe from '@daily-co/daily-js';
import axios from 'axios';
import ToastService from '../../Service/toast.service';
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { UserContext } from '../../Context/UserContext';

const AppointmentModal = ({ isOpen, onClose, appointment, onFollowUp, onReschedule, currentUserId }) => {
  const { isLoggedIn, currentUser, signOutUser } = useContext(UserContext);

  const [notes, setNotes] = useState('');
  const [isVideoCallActive, setIsVideoCallActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pastNotes, setPastNotes] = useState([]);
  const [pastPrescriptions, setPastPrescriptions] = useState([]);
  const [callFrame, setCallFrame] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const patient = appointment?.patientId?.userId || {};
  const doctor = appointment?.doctorId?.userId || {};

  const quillModules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
  };

  const quillFormats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ];

  useEffect(() => {
    if (appointment?.patientId) {
      fetchPastRecords();
    }

    return () => {
      if (callFrame) {
        callFrame.leave().then(() => callFrame.destroy());
      }
    };
  }, [appointment, callFrame]);

  const fetchPastRecords = async () => {
    setLoading(true);
    try {
      const { data: notesData } = await axios.get(`/api/patients/${appointment.patientId._id}/notes`);
      const { data: prescriptionsData } = await axios.get(`/api/patients/${appointment.patientId._id}/prescriptions`);
      setPastNotes(notesData);
      setPastPrescriptions(prescriptionsData);
    } catch (error) {
      console.error('Error fetching past records:', error);
      setErrorList(['Failed to fetch past records. Please try again later.']);
      setErrorDialogueBoxOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleAddNote = async () => {
    if (!notes.trim()) {
      setErrorList(['Note cannot be empty.']);
      setErrorDialogueBoxOpen(true);
      return;
    }
    try {
      const newNote = {
        doctor: `Dr. ${doctor.firstName} ${doctor.lastName}`,
        timestamp: new Date().toISOString(),
        content: notes,
      };

      await axios.post(`/api/patients/${appointment.patientId._id}/notes`, newNote);
      setPastNotes((prevNotes) => [newNote, ...prevNotes]);
      setNotes('');
      ToastService.success('Note added successfully.');
    } catch (error) {
      console.error('Error adding note:', error);
      setErrorList(['Failed to add note. Please try again later.']);
      setErrorDialogueBoxOpen(true);
    }
  };

  const handleStartVideoCall = () => {
    const container = document.getElementById('video-call-container');
    if (!container) {
      console.error('Video call container not found');
      return;
    }

    const frame = DailyIframe.createFrame(container, {
      showLeaveButton: true,
      iframeStyle: {
        width: '100%',
        height: '100%',
        border: 'none',
      },
    });

    frame
      .join({ url: 'https://adv-portal.daily.co/consultation-01' })
      .then(() => setCallFrame(frame))
      .catch((error) => {
        console.error('Error starting video call:', error);
        setErrorList(['Failed to start video call. Please try again later.']);
        setErrorDialogueBoxOpen(true);
      });

    setIsVideoCallActive(true);
  };

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  const handleCloseAppointment = async () => {
    try {
      await axios.put(`/api/appointments/${appointment._id}/close`);
      ToastService.success('Appointment closed successfully.');
      onClose();
    } catch (error) {
      console.error('Error closing appointment:', error);
      setErrorList(['Failed to close appointment. Please try again later.']);
      setErrorDialogueBoxOpen(true);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          bgcolor: 'background.paper',
          position: 'fixed',
          top: 0,
          left: 0,
          overflowY: 'auto',
          borderRadius: 0,
          boxShadow: 24,
          p: 4,
        }}
      >
        { }
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
            background: 'white',
            p: 2,
            borderRadius: 1,
          }}
        >
          <Typography variant="h5">Appointment Details</Typography>
          <Box sx={{ background: 'none' }}>
          {(currentUser?.userType == "Doctor" || currentUser?.userType === "Admin") &&
            <Tooltip title="Add Prescription">
              <IconButton
                onClick={() => window.open('/account/prescriptions', '_blank')}
                sx={{ color: 'grey', mr: 1 }}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </Tooltip>
            }
            {(currentUser?.userType == "Doctor" || currentUser?.userType === "Admin") &&
              <Tooltip title="Schedule Follow-Up">
                <IconButton
                  sx={{ color: 'grey', mr: 1 }}
                  onClick={() => {
                    onFollowUp();
                  }}
                >
                  <EventNoteIcon />
                </IconButton>
              </Tooltip>
            }
            <Tooltip title="Reschedule">
              <IconButton
                sx={{ color: 'grey', mr: 1 }}
                onClick={() => {
                  onReschedule();
                }}
              >
                <ScheduleIcon />
              </IconButton>
            </Tooltip>
            {(currentUser?.userType == "Doctor" || currentUser?.userType === "Admin") && <Tooltip title="Submit for Closure">
              <IconButton
                sx={{ color: 'grey', mr: 1 }}
                onClick={handleCloseAppointment}
              >
                <CheckCircleIcon />
              </IconButton>
            </Tooltip>
            }
            <Button variant="contained" color="primary" onClick={onClose}>
              Close
            </Button>
          </Box>
        </Box>

        { }
        <div className="row">
          { }
          <div className="col-md-4">
            <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
              <Tab label="Details" />
              <Tab label="History" />
              <Tab label="Notes" />
            </Tabs>
            {activeTab === 0 && (
              <Box sx={{ background: 'none' }}>
                <Typography variant="h6" gutterBottom>
                  Patient Details
                </Typography>
                <div className="form-group mb-3">
                  <label htmlFor="patientName">Name</label>
                  <input
                    type="text"
                    id="patientName"
                    className="form-control"
                    value={patient.firstName || 'N/A'}
                    readOnly
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="patientSurname">Surname</label>
                  <input
                    type="text"
                    id="patientSurname"
                    className="form-control"
                    value={patient.lastName || 'N/A'}
                    readOnly
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="patientContact">Contact Number</label>
                  <input
                    type="text"
                    id="patientContact"
                    className="form-control"
                    value={appointment?.patientId?.contactNumber || 'N/A'}
                    readOnly
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="patientEmail">Email</label>
                  <input
                    type="email"
                    id="patientEmail"
                    className="form-control"
                    value={appointment?.patientId?.email || 'N/A'}
                    readOnly
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="patientAddress">Address</label>
                  <input
                    type="text"
                    id="patientAddress"
                    className="form-control"
                    value={appointment?.patientId?.address || 'N/A'}
                    readOnly
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="patientID">ID Number</label>
                  <input
                    type="text"
                    id="patientID"
                    className="form-control"
                    value={appointment?.patientId?.idNumber || 'N/A'}
                    readOnly
                  />
                </div>
              </Box>
            )}
            {activeTab === 1 && (
              <Box sx={{ background: 'none' }}>
                <Typography variant="h6" gutterBottom>
                  Patient History
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Past Notes
                </Typography>
                <div
                  className="past-records mb-3"
                  style={{
                    maxHeight: '150px',
                    overflowY: 'auto',
                    border: '1px solid #ccc',
                    padding: '10px',
                    background: 'white',
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : pastNotes.length ? (
                    pastNotes.map((note, index) => (
                      <Typography key={index} sx={{ mb: 1 }}>
                        {note.content}
                      </Typography>
                    ))
                  ) : (
                    <Typography color="textSecondary">No notes available.</Typography>
                  )}
                </div>
                <Typography variant="subtitle1" gutterBottom>
                  Past Prescriptions
                </Typography>
                <div
                  className="past-prescriptions"
                  style={{
                    maxHeight: '150px',
                    overflowY: 'auto',
                    border: '1px solid #ccc',
                    padding: '10px',
                    background: 'white',
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : pastPrescriptions.length ? (
                    pastPrescriptions.map((prescription, index) => (
                      <Typography key={index} sx={{ mb: 1 }}>
                        {prescription.content}
                      </Typography>
                    ))
                  ) : (
                    <Typography color="textSecondary">No prescriptions available.</Typography>
                  )}
                </div>
              </Box>
            )}
            {activeTab === 2 && (
              <Box sx={{ background: 'none' }}>
                <Typography variant="h6" gutterBottom>
                  Add Notes
                </Typography>
                <div className="form-group mb-3">
                  <ReactQuill
                    theme="snow"
                    value={notes}
                    onChange={setNotes}
                    placeholder="Enter your notes here..."
                    modules={quillModules}
                    formats={quillFormats}
                    style={{ height: '100%', marginBottom: '20px' }}
                  />
                </div>
                <Button variant="contained" color="primary" onClick={handleAddNote}>
                  Add Note
                </Button>
              </Box>
            )}
          </div>

          { }
          <div className="col-md-8">
            <Box className="video-call-section" sx={{ background: '#efefef', p: 3, borderRadius: 1 }}>
              <Typography variant="h6" gutterBottom>
                Video Call
              </Typography>
              <div
                id="video-call-container"
                className="video-call-container"
                style={{
                  height: '400px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'white',
                  borderRadius: '4px',
                }}
              >
                {isVideoCallActive ? null : (
                  <Button variant="contained" color="success" onClick={handleStartVideoCall}>
                    Start Video Call
                  </Button>
                )}
              </div>
            </Box>
          </div>

          { }
          <Box className="notes-thread-section mt-4" sx={{ background: 'white', borderRadius: 1, padding: 2, mt: 3 }}>
            <Typography variant="h6" gutterBottom>
              Notes Thread
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <div
              className="notes-thread"
              style={{
                maxHeight: '200px',
                overflowY: 'auto',
                border: '1px solid #ccc',
                padding: '10px',
                backgroundColor: '#f9f9f9',
              }}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : pastNotes.length ? (
                pastNotes.map((note, index) => (
                  <div key={index} className="mb-2 p-2" style={{ borderBottom: '1px solid #ddd' }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {`Added by: ${note.doctor} | ${new Date(note.timestamp).toLocaleString()}`}
                    </Typography>
                    <Typography
                      variant="body1"
                      dangerouslySetInnerHTML={{ __html: note.content }}
                    ></Typography>
                  </div>
                ))
              ) : (
                <Typography color="textSecondary">No notes available.</Typography>
              )}
            </div>
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default AppointmentModal;
