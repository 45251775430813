import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import Box from '@mui/material/Box';
import DoctorTable from '../MUITable/DoctorTable';
import ToastService from '../../Service/toast.service';

function DoctorList() {
    const params = new URLSearchParams(window.location.search);
    const name = params.get('name');

    const [doctors, setDoctors] = useState([]);
    const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);

    const handleDialogueOpen = () => {
        setErrorDialogueBoxOpen(true);
    };

    const handleDialogueClose = () => {
        setErrorList([]);
        setErrorDialogueBoxOpen(false);
    };

    useEffect(() => {
        if (name) {
            getDoctorsByName(name);
        } else {
            getAllDoctors();
        }
    }, [name]);

    const getAllDoctors = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        try {
            const response = await axios.get(`${baseApi}/doctors`);
            console.log("Fetched all doctors:", response.data);
            setDoctors(response.data);
        } catch (error) {
            handleFetchError(error, "Failed to fetch all doctors.");
        }
    };

    const fetchAppointments = async (doctorId) => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
        
        try {
            const response = await axios.get(`${baseApi}/appointments`, {
                params: { doctorId },
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching appointments:", error);
            return [];
        }
    };
    
    const getDoctorsByName = async (searchName) => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        try {
            const response = await axios.get(`${baseApi}/doctors`, {
                params: {
                    name: searchName,
                },
            });
            console.log(`Fetched doctors with name "${searchName}":`, response.data);
            setDoctors(response.data);
        } catch (error) {
            handleFetchError(error, "Failed to fetch doctors by name.");
        }
    };

    const deleteDoctor = async (id) => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        try {
            await axios.delete(`${baseApi}/doctors/${id}`);
            ToastService.success("Doctor deleted successfully.");
            getAllDoctors();
        } catch (error) {
            handleFetchError(error, "Failed to delete the doctor.");
        }
    };

    const handleFetchError = (error, fallbackMessage) => {
        const errors = [];
        if (error.response) {
            if (error.response.data && typeof error.response.data === 'object') {
                errors.push(error.response.data.message || fallbackMessage);
            } else {
                errors.push(fallbackMessage);
            }
        } else if (error.request) {
            errors.push("No response from server. Please try again later.");
        } else {
            errors.push(error.message || fallbackMessage);
        }

        setErrorList(errors);
        handleDialogueOpen();
    };

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-sm-3 col-3">
                            <h4 className="page-title fw-bold">Doctors</h4>
                        </div>
                        <div className="col-sm-9 col-9 text-right m-b-20 d-flex justify-content-end">
                            <Link to="/account/doctors/add" className="btn btn-primary float-right btn-rounded">
                                <i className="fa fa-plus"></i> Add Doctor
                            </Link>
                        </div>
                    </div>
                    <form action="/account/doctors" name="doctorFilter">
                        <div className="row filter-row">
                            <div className="col-sm-4 col-md-4">
                                <div className="form-floating">
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        placeholder="Doctor Name"
                                    />
                                    <label className="focus-label">Doctor Name</label>
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-4">
                                <button type="submit" className="btn btn-primary btn-block">Search</button>
                            </div>
                        </div>
                    </form>
                    <DoctorTable
                        doctorList={doctors.map((doctor) => ({
                            ...doctor,
                            userId: doctor.userId || {},
                        }))}
                        deleteDoctor={deleteDoctor}
                        fetchAppointments={fetchAppointments}
                    />
                </div>
                <ErrorDialogueBox
                    open={errorDialogueBoxOpen}
                    handleToClose={handleDialogueClose}
                    ErrorTitle="Error: Doctor List"
                    ErrorList={errorList}
                />
            </div>
        </Box>
    );
}

export default DoctorList;
