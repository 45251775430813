import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Alert } from "@mui/material";
import ToastService from "../../Service/toast.service";

const PasswordResetDialog = ({ open, handleClose }) => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const handlePasswordReset = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";

        if (!email) {
            ToastService.error("Please enter your email address.");
            return;
        }

        try {
            setLoading(true);
            const response = await fetch(`${baseApi}/api/password-reset/request`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email }),
                credentials: 'include',
            });

            console.log('Reset password response:', response);

            const data = await response.json();
            console.log('Reset password data:', data);

            if (!response.ok) {
                throw new Error(data.error || 'Failed to send reset email');
            }

            ToastService.success("Password reset email sent successfully!");
            handleClose();
            setEmail("");
        } catch (error) {
            console.error("Error resetting password:", error);
            ToastService.error(error.message || "An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handlePasswordReset();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Reset Password</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        margin="dense"
                        label="Email Address"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={loading}
                        required
                        autoFocus
                    />
                    <DialogActions>
                        <Button onClick={handleClose} disabled={loading}>
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={loading}
                        >
                            {loading ? "Sending..." : "Reset Password"}
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default PasswordResetDialog;
