
import axios from 'axios';

const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

export const activityLogService = {
    getUserLogs: async (page = 1, limit = 10) => {
        try {
            const response = await axios.get(`${baseApi}/activity-logs/user`, {
                params: { page, limit },
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            throw error.response?.data || error.message;
        }
    },

    getSystemLogs: async (page = 1, limit = 10) => {
        try {
            const response = await axios.get(`${baseApi}/activity-logs/system`, {
                params: { page, limit },
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            throw error.response?.data || error.message;
        }
    },

    createActivityLog: async (logData) => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.post(`${baseApi}/activity-logs`, logData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            throw error.response?.data || error.message;
        }
    }
};
