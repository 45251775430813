
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Box from "@mui/material/Box";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AlertSnackbar = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ErrorDialogueBox(props) {
    const errorList = props.ErrorList || [];

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleCopy = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                setSnackbarMessage("Copied to clipboard!");
                setSnackbarOpen(true);
            })
            .catch((err) => {
                console.error("Could not copy text: ", err);
                setSnackbarMessage("Failed to copy!");
                setSnackbarOpen(true);
            });
    };

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.handleToClose}
                TransitionComponent={Transition}
                keepMounted
                PaperProps={{ sx: { minWidth: "20%" } }}
            >
                <DialogTitle>{props.ErrorTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {errorList.length > 0 ? (
                            errorList.map((err, index) => (
                                <Box
                                    key={index}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    mb={1}
                                    sx={{ backgroundColor: 'white' }}
                                >
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            wordBreak: "break-word",
                                            background: "#efefef",
                                            padding: "5px 10px",
                                        }}
                                    >
                                        {err}
                                    </Typography>
                                    <Tooltip title="Click to copy" arrow>
                                        <IconButton
                                            aria-label={`Copy error message: ${err}`}
                                            onClick={() => handleCopy(err)}
                                            size="small"
                                        >
                                            <ContentCopyIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            ))
                        ) : (
                            <Typography variant="body1" align="center">
                                No error details available.
                            </Typography>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleToClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <AlertSnackbar
                    onClose={handleSnackbarClose}
                    severity={
                        snackbarMessage === "Copied to clipboard!" ? "success" : "error"
                    }
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </AlertSnackbar>
            </Snackbar>
        </>
    );
}

export default ErrorDialogueBox;
