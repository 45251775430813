
import React, { useContext, useState } from 'react';
import styles from './Login.module.css';
import { useNavigate } from "react-router-dom";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import { UserContext } from '../../Context/UserContext';
import PasswordResetDialog from '../MUIDialogueBox/PasswordResetDialog';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    IconButton,
    Alert,
    CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useActivityLogger from '../../hooks/useActivityLogger';

function Login() {
    const navigate = useNavigate();
    const { logActivity } = useActivityLogger();
    const [passwordResetDialogOpen, setPasswordResetDialogOpen] = useState(false);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const { signInUser } = useContext(UserContext);

    const [resetEmail, setResetEmail] = useState('');
    const [resetError, setResetError] = useState('');
    const [resetSuccess, setResetSuccess] = useState('');
    const [isResetSubmitting, setIsResetSubmitting] = useState(false);

    /**
     * Handle error dialog opening and closing
     * @since 0.1
     */
    const handleDialogueOpen = () => {
        setErrorDialogueBoxOpen(true);
    };
    const handleDialogueClose = () => {
        setErrorList([]);
        setErrorDialogueBoxOpen(false);
    };

    /**
     * Handle password reset dialog opening and closing
     * @since 0.1
     */
    const handlePasswordResetOpen = () => {
        setPasswordResetDialogOpen(true);
        setResetEmail('');
        setResetError('');
        setResetSuccess('');
    };
    const handlePasswordResetClose = () => {
        setPasswordResetDialogOpen(false);
    };

    /**
     * Handle login form submission
     * @since 0.1
     * @param {} event 
     */
    const handleSubmit = async (event) => {
        event.preventDefault();
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        const user = {
            email: email.trim(),
            password: password,
        };

        try {
            const response = await fetch(`${baseApi}/login`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user)
            });

            const data = await response.json();

            console.log("Login response data:", data);

            if (response.ok && data.message === "success") {
                signInUser(data.user, data.token);
                await logActivity('Loggedin', 'User logged in.');
                navigate("/account");
            } else {
                setErrorList(data.errors || ["An unexpected error occurred."]);
                handleDialogueOpen();
            }
        } catch (error) {
            console.error("Error during login:", error);
            setErrorList(["Failed to connect to the server. Please try again later."]);
            handleDialogueOpen();
        }
    };

    /**
     * Handle password reset form submission
     * @since 0.1
     * @param {} event 
     */
    const handlePasswordResetSubmit = async (event) => {
        event.preventDefault();
        setIsResetSubmitting(true);
        setResetError('');
        setResetSuccess('');

        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        if (!resetEmail) {
            setResetError('Please enter your email address.');
            setIsResetSubmitting(false);
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(resetEmail)) {
            setResetError('Please enter a valid email address.');
            setIsResetSubmitting(false);
            return;
        }

        try {
            const response = await fetch(`${baseApi}/password-reset`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: resetEmail.trim() })
            });

            const data = await response.json();

            if (response.ok && data.message === "success") {
                setResetSuccess("A password reset link has been sent to your email.");
            } else {
                setResetError(data.errors ? data.errors.join(', ') : "Failed to send reset link.");
            }
        } catch (error) {
            console.error("Error during password reset:", error);
            setResetError("Failed to connect to the server. Please try again later.");
        } finally {
            setIsResetSubmitting(false);
        }
    };

    /**
     * Signup button click action
     * @since 0.1
     */
    const signUpClicked = (e) => {
        e.preventDefault();
        navigate("/signup");
    }

    return (
        <div className="bg-white dark-bg-primary" id={styles.loginBody}>
            <div className={styles.greenLayer1}>
                <div id={styles.loginFormDiv}>
                    <p>Welcome back! Please login to your account</p>
                    {}
                    <form onSubmit={handleSubmit} className="col-8 col-sm-8 col-md-6 col-lg-6" name="loginForm" id="loginForm">
                        <div className='form-floating mt-3 col-12 mx-2'>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="name@example.com"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                required
                                className="form-control"
                            />
                            <label htmlFor="email" >Email</label>
                        </div>
                        <div className='form-floating mt-4 col-12 mx-2'>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                                className="form-control"
                                required
                                placeholder="password"
                            />
                            <label htmlFor="password">Password</label>
                        </div>
                        <div className='d-flex flex-column flex-md-row mx-2 mt-5 justify-content-between'>
                            <button className='col-12 col-md-6' id={styles.loginBtn} type="submit">Login</button>
                            <button
                                className={["col-12 col-md-6 mt-3 mt-md-0", styles.signUpBtn].join(" ")}
                                onClick={signUpClicked}
                                type="button"
                            >
                                Sign Up
                            </button>
                        </div>
                    </form>
                    {}
                    <p>
                        Or{" "}
                        <Button
                            variant="text"
                            color="primary"
                            onClick={handlePasswordResetOpen}
                            className="text-primary dark-text-primary"
                            style={{ padding: 0 }}
                        >
                            reset your password
                        </Button>
                    </p>
                </div>
            </div>

            {}
            <ErrorDialogueBox
                open={errorDialogueBoxOpen}
                handleToClose={handleDialogueClose}
                ErrorTitle="Login Error"
                ErrorList={errorList}
            />

            {}
            <PasswordResetDialog
                open={passwordResetDialogOpen}
                handleClose={() => setPasswordResetDialogOpen(false)}
            />
        </div>
    );

}

export default Login;
