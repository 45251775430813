import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const Onboarding = ({ open, onClose, patientId, patientProfile }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState(() => ({
    firstName: patientProfile?.userId?.firstName || '',
    lastName: patientProfile?.userId?.lastName || '',
    gender: patientProfile?.gender || '',
    dob: patientProfile?.dob || '',
    idNumber: patientProfile?.idNumber || '',
    address: patientProfile?.address || '',
    phone: patientProfile?.phone || '',
    nextOfKinMobile: patientProfile?.nextOfKinMobile || '',
    isMinor: patientProfile?.isMinor || false,
    parentName: patientProfile?.parentName || '',
    parentSurname: patientProfile?.parentSurname || '',
    parentIdNumber: patientProfile?.parentIdNumber || '',
    conditions: patientProfile?.conditions || '',
    chronicIllnesses: patientProfile?.chronicIllnesses || '',
    surgeries: patientProfile?.surgeries || '',
    hospitalisations: patientProfile?.hospitalisations || '',
    allergies: patientProfile?.allergies || '',
    familyMedicalHistory: patientProfile?.familyMedicalHistory || {
      heartDisease: false,
      hypertension: false,
      diabetes: false,
      cancer: false,
      stroke: false,
      mentalHealth: false,
      other: '',
    },
    occupation: patientProfile?.occupation || '',
    maritalStatus: patientProfile?.maritalStatus || '',
    children: patientProfile?.children || '',
    smoke: patientProfile?.smoke || false,
    drugs: patientProfile?.drugs || false,
    alcohol: patientProfile?.alcohol || false,
    exercise: patientProfile?.exercise || false,
    dietaryRestrictions: patientProfile?.dietaryRestrictions || '',
    menstrualHistory: patientProfile?.menstrualHistory || {
      firstPeriodAge: '',
      lastPeriodDate: '',
      regularityDuration: '',
      pregnancies: '',
      births: '',
      complications: '',
    },
    gynaecologicalHistory: patientProfile?.gynaecologicalHistory || {
      conditionsOrSurgeries: '',
      lastPapSmearDate: '',
      papSmearResults: '',
    },
  }));

  const steps = [
    'Personal Details',
    'Health History',
    'Family Medical History',
    'Social History',
    'Women\'s Health',
    'Gynaecological History',
  ];

  useEffect(() => {
    if (open && patientProfile) {
      setFormData({
        firstName: patientProfile?.userId?.firstName || '',
        lastName: patientProfile?.userId?.lastName || '',
        gender: patientProfile?.gender || '',
        dob: patientProfile?.dob || '',
        idNumber: patientProfile?.idNumber || '',
        address: patientProfile?.address || '',
        phone: patientProfile?.phone || '',
        nextOfKinMobile: patientProfile?.nextOfKinMobile || '',
        isMinor: patientProfile?.isMinor || false,
        parentName: patientProfile?.parentName || '',
        parentSurname: patientProfile?.parentSurname || '',
        parentIdNumber: patientProfile?.parentIdNumber || '',
        conditions: patientProfile?.conditions || '',
        chronicIllnesses: patientProfile?.chronicIllnesses || '',
        surgeries: patientProfile?.surgeries || '',
        hospitalisations: patientProfile?.hospitalisations || '',
        allergies: patientProfile?.allergies || '',
        familyMedicalHistory: patientProfile?.familyMedicalHistory || {
          heartDisease: false,
          hypertension: false,
          diabetes: false,
          cancer: false,
          stroke: false,
          mentalHealth: false,
          other: '',
        },
        occupation: patientProfile?.occupation || '',
        maritalStatus: patientProfile?.maritalStatus || '',
        children: patientProfile?.children || '',
        smoke: patientProfile?.smoke || false,
        drugs: patientProfile?.drugs || false,
        alcohol: patientProfile?.alcohol || false,
        exercise: patientProfile?.exercise || false,
        dietaryRestrictions: patientProfile?.dietaryRestrictions || '',
        menstrualHistory: patientProfile?.menstrualHistory || {
          firstPeriodAge: '',
          lastPeriodDate: '',
          regularityDuration: '',
          pregnancies: '',
          births: '',
          complications: '',
        },
        gynaecologicalHistory: patientProfile?.gynaecologicalHistory || {
          conditionsOrSurgeries: '',
          lastPapSmearDate: '',
          papSmearResults: '',
        },
      });
    }
  }, [open, patientProfile]);

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      await onFinish();
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleToggle = (field) =>
    setFormData({ ...formData, [field]: !formData[field] });

  const handleFamilyHistoryToggle = (field) => {
    setFormData((prev) => ({
      ...prev,
      familyMedicalHistory: {
        ...prev.familyMedicalHistory,
        [field]: !prev.familyMedicalHistory[field],
      },
    }));
  };

  const onFinish = async () => {
    try {
      const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
      await axios.patch(`${baseApi}/patients/${patientId}`, formData);
      console.log('Patient profile updated successfully');
      onClose();
    } catch (error) {
      console.error('Failed to update patient profile:', error);
    }
  };
  
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Gender</label>
                <select
                  className="form-control"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Date of Birth</label>
                <input
                  type="date"
                  className="form-control"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>ID Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="idNumber"
                  value={formData.idNumber}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Residential Address</label>
                <input
                  type="text"
                  className="form-control"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Next of Kin Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  name="nextOfKinMobile"
                  value={formData.nextOfKinMobile}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    name="isMinor"
                    checked={formData.isMinor}
                    onChange={() => handleToggle('isMinor')}
                  />{' '}
                  Is the patient a minor?
                </label>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Conditions</label>
                <input
                  type="text"
                  className="form-control"
                  name="conditions"
                  value={formData.conditions}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Chronic Illnesses</label>
                <input
                  type="text"
                  className="form-control"
                  name="chronicIllnesses"
                  value={formData.chronicIllnesses}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Surgeries</label>
                <input
                  type="text"
                  className="form-control"
                  name="surgeries"
                  value={formData.surgeries}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Hospitalisations</label>
                <input
                  type="text"
                  className="form-control"
                  name="hospitalisations"
                  value={formData.hospitalisations}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Allergies</label>
                <input
                  type="text"
                  className="form-control"
                  name="allergies"
                  value={formData.allergies}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="row">
            {Object.keys(formData.familyMedicalHistory).map((key) => (
              <div className="col-sm-6" key={key}>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      name={key}
                      checked={formData.familyMedicalHistory[key]}
                      onChange={() => handleFamilyHistoryToggle(key)}
                    />{' '}
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </label>
                </div>
              </div>
            ))}
          </div>
        );
      case 3:
        return (
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Occupation</label>
                <input
                  type="text"
                  className="form-control"
                  name="occupation"
                  value={formData.occupation}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Marital Status</label>
                <select
                  className="form-control"
                  name="maritalStatus"
                  value={formData.maritalStatus}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                  <option value="divorced">Divorced</option>
                </select>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Age at First Period</label>
                <input
                  type="text"
                  className="form-control"
                  name="menstrualHistory.firstPeriodAge"
                  value={formData.menstrualHistory.firstPeriodAge}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Conditions or Surgeries</label>
                <input
                  type="text"
                  className="form-control"
                  name="gynaecologicalHistory.conditionsOrSurgeries"
                  value={formData.gynaecologicalHistory.conditionsOrSurgeries}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        );
      case 6:
        return (
          <div className="row">
            <div className="col-md-12 text-center">
              <h3 className="text-success">Profile Completed Successfully!</h3>
              <p>Your medical history has been saved successfully.</p>
              <button
                className="btn btn-primary"
                onClick={onClose}
                style={{ marginTop: '20px' }}
              >
                Close
              </button>
            </div>
          </div>
        );
      default:
        return <Typography>Unknown step</Typography>;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      key={patientId}
      sx={{
        '& .MuiDialog-paper': {
          width: '70%',
          maxHeight: '80vh',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px 24px',
          borderBottom: '1px solid #ddd',
          backgroundColor: '#f5f5f5',
        }}
      >
        <Typography variant="h6" component="div">
          Medical History Onboarding
        </Typography>
        <IconButton onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Box>
      <Stepper activeStep={activeStep} sx={{ padding: 2 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ padding: 3 }}>{renderStepContent(activeStep)}</Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 3 }}>
        <button className="btn btn-secondary" disabled={activeStep === 0} onClick={handleBack}>
          Back
        </button>
        <button className="btn btn-primary" onClick={handleNext}>
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </button>
      </Box>
    </Dialog>
  );
};

export default Onboarding;
