import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ErrorDialogueBox from "../MUIDialogueBox/ErrorDialogueBox";
import axios from "axios";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import { UserContext } from "../../Context/UserContext";
import { savedbMedia } from "../../Service/dbMediaService";


function DoctorProfile() {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [department, setDepartment] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [userId, setUserId] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [passwordMatchDisplay, setPasswordMatchDisplay] = useState("none");
  const [passwordValidationMessage, setPasswordValidationMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const handleDialogueOpen = () => {
    setErrorDialogueBoxOpen(true);
  };

  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  const handleProfileImageChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      const maxSizeInMB = 5;

      if (!allowedTypes.includes(file.type)) {
        alert("Only JPG, JPEG, and PNG files are allowed.");
        return;
      }

      if (file.size > maxSizeInMB * 1024 * 1024) {
        alert(`File size exceeds ${maxSizeInMB}MB.`);
        return;
      }

      setProfileImagePreview(URL.createObjectURL(file));

      try {
        const response = await savedbMedia(file);

        const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";

        if (response && response.mediaId) {
          const mediaUrl = `${baseApi}/media/${response.mediaId}`;
          setProfileImage(mediaUrl);
        } else {
          alert("Failed to upload profile image.");
        }
      } catch (error) {
        alert("An error occurred while uploading the profile image.");
        console.error(error);
      }
    }
  };

  const getDoctorById = async () => {
    const baseApi =
      process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";

    try {
      let doctorUserId = currentUser.userId;
      const response = await axios.get(`${baseApi}/profile/doctor/${doctorUserId}`);
      setDoctorId(response.data._id);
      setFirstName(response.data.userId.firstName);
      setLastName(response.data.userId.lastName);
      setEmail(response.data.userId.email);
      setUsername(response.data.userId.username);
      setPassword(response.data.userId.password);
      setConfirmPassword(response.data.userId.password);
      setPhone(response.data.phone);
      setDepartment(response.data.department);
      setUserId(response.data.userId._id);
    } catch (error) {
      console.error("Error fetching doctor profile:", error);
      setErrorList(["Failed to fetch doctor profile"]);
      handleDialogueOpen();
    }
  };

  const updateDoctorUser = async (e) => {
    e.preventDefault();
    const baseApi =
      process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";

    try {
      const updatePayload = {
        firstName,
        lastName,
        username,
        email,
        phone,
        password,
        confirmPassword,
        department,
        userId,
        profileImage,
      };

      await axios.patch(`${baseApi}/profile/doctor/${doctorId}`, updatePayload, {
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      navigate("/account/profile");
    } catch (error) {
      console.error(error.response?.data?.errors || "Error updating profile");
      setErrorList(error.response?.data?.errors || ["Failed to update profile"]);
      handleDialogueOpen();
    }
  };

  useEffect(() => {
    if (password && password.length > 0 && password.trim().length <= 6) {
      setPasswordValidationMessage("Password Length must be greater than 6 characters");
    } else {
      setPasswordValidationMessage("");
    }
    setPasswordMatchDisplay(password === confirmPassword ? "none" : "block");
  }, [password, confirmPassword]);

  useEffect(() => {
    getDoctorById();
  }, []);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="page-title">Update Profile</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 d-flex flex-column align-items-center justify-content-start">
                <div
                  style={{
                    width: 150,
                    height: 150,
                    borderRadius: "50%",
                    backgroundColor: "#f0f0f0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                    marginBottom: 10,
                  }}
                >
                  {profileImagePreview ? (
                    <img
                      src={profileImagePreview}
                      alt="Profile Preview"
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : doctorId && profileImage ? (
                    <img
                      src={profileImage}
                      alt="Profile"
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <PersonIcon style={{ fontSize: 50, color: "#9e9e9e" }} />
                  )}
                </div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleProfileImageChange}
                  style={{ display: "none" }}
                  id="upload-profile-image"
                />
                <label htmlFor="upload-profile-image">
                  <button className="btn btn-primary">
                    {profileImagePreview ? "Replace Image" : "Upload Image"}
                  </button>
                </label>
              </div>
              <div className="col-lg-9">
                <form id="editdoctorForm" name="editdoctorForm" onSubmit={updateDoctorUser}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          First Name <span className="text-danger">*</span>
                        </label>
                        <input
                          name="firstName"
                          className="form-control"
                          type="text"
                          required
                          value={firstName}
                          onChange={(event) => setFirstName(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          name="lastName"
                          className="form-control"
                          type="text"
                          required
                          value={lastName}
                          onChange={(event) => setLastName(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Username <span className="text-danger">*</span>
                        </label>
                        <input
                          name="username"
                          className="form-control"
                          type="text"
                          required
                          value={username}
                          onChange={(event) => setUsername(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          name="email"
                          className="form-control"
                          type="email"
                          required
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Password</label>
                        <div className="password-field">
                          <input
                            name="password"
                            className="form-control"
                            type={showPassword ? "text" : "password"}
                            required
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                          />
                          <a
                            type="button"
                            className="btn btn-text-primary"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? "Hide" : "Show"}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <div className="password-field">
                          <input
                            name="confirmPassword"
                            className="form-control"
                            type={showConfirmPassword ? "text" : "password"}
                            required
                            value={confirmPassword}
                            onChange={(event) => setConfirmPassword(event.target.value)}
                          />
                          <a
                            type="button"
                            className="btn btn-text-primary"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          >
                            {showConfirmPassword ? "Hide" : "Show"}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Phone </label>
                        <input
                          name="phone"
                          className="form-control"
                          type="text"
                          value={phone}
                          onChange={(event) => setPhone(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Department</label>
                        <select
                          disabled
                          name="department"
                          className="form-select"
                          value={department}
                          onChange={(event) => setDepartment(event.target.value)}
                        >
                          <option value="Cardiology">Cardiology</option>
                          <option value="Gynecology">Gynecology</option>
                          <option value="Hematology">Hematology</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="m-t-20 text-left">
                    <button type="submit" className="btn btn-primary submit-btn">
                      Update Profile
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Edit Doctor"
          ErrorList={errorList}
        />
      </div>
    </Box>
  );
}

export default DoctorProfile;
