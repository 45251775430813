import React, { useState, useContext, useEffect } from "react";
import axios from 'axios';
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { ThemeContext } from "../../Context/ThemeContext";

const currencies = ["USD", "EUR", "GBP", "INR", "AUD", "CAD"];

export default function SettingsPage() {
  /**
   * Settings use states
   * @since 0.1
   */
  const [isLoading, setIsLoading] = useState(true); 
  const { themeSettings, setThemeSettings } = useContext(ThemeContext);
  const [localSettings, setLocalSettings] = useState(themeSettings);
  const [settings, setSettings] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const baseApi = process.env.REACT_BASE_APP_API_URL || "https://my.docotela.co.za/api";

  /**
   * useEffect to fetch settings data on load
   * @since 0.1
   */
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        setIsLoading(true); 
        const response = await axios.get(`${baseApi}/settings`);
        if (response.data) {
          setSettings(response.data); 
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
      } finally {
        setIsLoading(false); 
      }
    };
  
    fetchSettings();
  }, []);

  /**
   * Handle tab changes
   * @since 0.1
   */
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  /**
   * Handle input changes
   * @since 0.1
   * @param {*} field 
   * @param {*} value 
   */
  const handleInputChange = (field, value) => {
    setSettings((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  /**
   * Handle changing store details
   * @since 0.1
   * @param {*} field 
   * @param {*} value 
   */
  const handleStoreDetailsChange = (field, value) => {
    setSettings((prev) => ({
      ...prev,
      storeDetails: {
        ...prev.storeDetails,
        [field]: value,
      },
    }));
  };

  /**
   * Handle submit settings form
   * @since 0.1
   * @param {*} event 
   */
  const handleSubmit = async (event) => {
    event.preventDefault(); 
    try {
      await axios.post(`${baseApi}/settings`, settings); 
      setThemeSettings({
        primaryColor: settings.primaryColor,
        secondaryColor: settings.secondaryColor,
        tertiaryColor: settings.tertiaryColor,
        fontColor: settings.fontColor,
        accentColor: settings.accentColor,
        linkColor: settings.linkColor,
      });
      alert("Settings saved successfully!");
    } catch (error) {
      console.error("Error saving settings:", error);
      alert("Failed to save settings. Please try again.");
    }
  };  

  /**
   * Rendering settings tab content
   * @since 0.1
   * @returns 
   */
  const renderTabContent = () => {
    switch (tabIndex) {
      case 0:
        return (
          <div>
            <h4 className="form-section-header mt-3">Theme Colour</h4>
            <hr/>
            <div className="row">
              <div className="col-sm-3">
                <div className="form-group">
                  <label>Primary Color:</label>
                  <input
                    type="color"
                    className="form-control form-control-color"
                    value={localSettings.primaryColor}
                    onChange={(e) =>
                      setLocalSettings((prev) => ({
                        ...prev,
                        primaryColor: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group">
                  <label>Secondary Color:</label>
                  <input
                    type="color"
                    className="form-control form-control-color"
                    value={localSettings.secondaryColor}
                    onChange={(e) =>
                      setLocalSettings((prev) => ({
                        ...prev,
                        secondaryColor: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group">
                  <label>Tertiary Color:</label>
                  <input
                    type="color"
                    className="form-control form-control-color"
                    value={localSettings.tertiaryColor}
                    onChange={(e) =>
                      setLocalSettings((prev) => ({
                        ...prev,
                        tertiaryColor: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group">
                  <label>Font Color:</label>
                  <input
                    type="color"
                    className="form-control form-control-color"
                    value={localSettings.fontColor}
                    onChange={(e) =>
                      setLocalSettings((prev) => ({
                        ...prev,
                        fontColor: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group">
                  <label>Accent Color:</label>
                  <input
                    type="color"
                    className="form-control form-control-color"
                    value={localSettings.accentColor}
                    onChange={(e) =>
                      setLocalSettings((prev) => ({
                        ...prev,
                        accentColor: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group">
                  <label>Link Color:</label>
                  <input
                    type="color"
                    className="form-control form-control-color"
                    value={localSettings.linkColor}
                    onChange={(e) =>
                      setLocalSettings((prev) => ({
                        ...prev,
                        linkColor: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>

            {}
            <h4 className="form-section-header mt-4">Font Settings</h4>
            <hr/>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Primary Font:</label>
                  <select
                    className="form-control"
                    value={localSettings.primaryFont}
                    onChange={(e) =>
                      setLocalSettings((prev) => ({
                        ...prev,
                        primaryFont: e.target.value,
                      }))
                    }
                  >
                    <option value="Roboto">Roboto</option>
                    <option value="Arial">Arial</option>
                    <option value="Lato">Lato</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Open Sans">Open Sans</option>
                  </select>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>Body Font:</label>
                  <select
                    className="form-control"
                    value={localSettings.bodyFont}
                    onChange={(e) =>
                      setLocalSettings((prev) => ({
                        ...prev,
                        bodyFont: e.target.value,
                      }))
                    }
                  >
                    <option value="Roboto">Roboto</option>
                    <option value="Arial">Arial</option>
                    <option value="Lato">Lato</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Open Sans">Open Sans</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            <h4 className="form-section-header mt-3">Store Details</h4>
            <hr/>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Store Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={settings.storeDetails.storeName}
                    onChange={(e) =>
                      handleStoreDetailsChange("storeName", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Store Domain</label>
                  <input
                    type="text"
                    className="form-control"
                    value={settings.storeDetails.domain}
                    onChange={(e) =>
                      handleStoreDetailsChange("domain", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Contact Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={settings.storeDetails.contactNumber}
                    onChange={(e) =>
                      handleStoreDetailsChange("contactNumber", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Email Address</label>
                  <input
                    type="text"
                    className="form-control"
                    value={settings.storeDetails.emailAddress}
                    onChange={(e) =>
                      handleStoreDetailsChange("emailAddress", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Physical Address</label>
                  <input
                    type="text"
                    className="form-control"
                    value={settings.storeDetails.physicalAddress}
                    onChange={(e) =>
                      handleStoreDetailsChange("physicalAddress", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Billing Address</label>
                  <input
                    type="text"
                    className="form-control"
                    value={settings.storeDetails.billingAddress}
                    onChange={(e) =>
                      handleStoreDetailsChange("billingAddress", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <h4 className="form-section-header mt-4">Maps API</h4>
            <hr/>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Google Maps API Key</label>
                  <input
                    type="text"
                    className="form-control"
                    value={settings.googleMapsApiKey}
                    onChange={(e) =>
                      handleInputChange("googleMapsApiKey", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Google Maps Service JSON</label>
                  <input
                    type="text"
                    className="form-control"
                    value={settings.googleServiceJSON}
                    onChange={(e) =>
                      handleInputChange("googleServiceJSON", e.target.value)
                    }
                  />
                </div>
              </div>
              <h4 className="form-section-header mt-4">Google Mail</h4>
              <hr/>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Google SMTP User</label>
                  <input
                    type="text"
                    className="form-control"
                    value={settings.googleSmtpUser}
                    onChange={(e) =>
                      handleInputChange("googleSmtpUser", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Google SMTP Password</label>
                  <input
                    type="text"
                    className="form-control"
                    value={settings.googleSmtpPassword}
                    onChange={(e) =>
                      handleInputChange("googleSmtpPassword", e.target.value)
                    }
                  />
                </div>
              </div>
              <h4 className="form-section-header mt-4">Microsoft 365</h4>
              <hr/>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Microsoft API Key</label>
                  <input
                    type="text"
                    className="form-control"
                    value={settings.microsoftApiKey}
                    onChange={(e) =>
                      handleInputChange("microsoftApiKey", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Microsoft API Secret</label>
                  <input
                    type="text"
                    className="form-control"
                    value={settings.microsoftApiSecret}
                    onChange={(e) =>
                      handleInputChange("microsoftApiSecret", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row">
            <h4 className="form-section-header mt-4">Payments</h4>
            <hr/>
                <div className="col-sm-6">
                <div className="form-group">
                  <label>PayPal API Client ID</label>
                  <input
                    type="text"
                    className="form-control"
                    value={settings.paypalClientId}
                    onChange={(e) =>
                      handleInputChange("paypalClientId", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Payfast Merchant ID</label>
                  <input
                    type="text"
                    className="form-control"
                    value={settings.payfastMerchantId}
                    onChange={(e) =>
                      handleInputChange("payfastMerchantId", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Payfast Merchant Key</label>
                  <input
                    type="text"
                    className="form-control"
                    value={settings.payfastMerchantKey}
                    onChange={(e) =>
                      handleInputChange("payfastMerchantKey", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            <Typography variant="h5" sx={{ marginBottom: 3, fontWeight: "bold" }}>
              Settings
            </Typography>
            <Tabs value={tabIndex} onChange={handleTabChange}>
              <Tab label="Theme Settings" />
              <Tab label="Store Settings" />
              <Tab label="Integrations" />
            </Tabs>
            {/* Settings form start */}
            <form id="settingsForm" name="settingsForm" onSubmit={handleSubmit}>
              {renderTabContent()}
              <div className="m-t-20 text-left">
                <button
                  id="saveSettings"
                  type="button"
                  className="btn btn-primary submit-btn"
                  onClick={handleSubmit}
                >
                  Save Settings
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Box>
  );
}
