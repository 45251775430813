import React, { useEffect, useState } from 'react';
import Header from '../Layout/Header/Header';
import Sidebar from '../Layout/Sidebar/Sidebar';
import { Link, Outlet } from "react-router-dom";
import axios from "axios";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import UserTable from '../MUITable/UserTable';
import ToastService from '../../Service/toast.service';

function UserList() {
    const params = new URLSearchParams(window.location.search);
    const role = params.get('role');
    const name = params.get('name');
    const [users, setUser] = useState([]);
    const [searchrole, setRole] = useState([]);
    const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const handleDialogueOpen = () => {
        setErrorDialogueBoxOpen(true)
    };
    const handleDialogueClose = () => {
        setErrorList([]);
        setErrorDialogueBoxOpen(false)
    };

    useEffect(() => {
        getUsers();
    }, []
    );

    /**
     * Get a list of the users
     * @since 0.1
     */
    const getUsers = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        const response = await axios.get(`${baseApi}/users`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("token")}`
            },
            params: {
                role: role,
                name: name
            }
        });
        setUser(response.data);
    };

    /**
     * Delete a single user record
     * @since 0.1
     * @param {*} id - User Id
     */
    const deleteUser = async (id) => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        try {
            await axios.delete(`${baseApi}/users/${id}`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            getUsers();
            ToastService.success("User deleted successful.")
        } catch (error) {
            setErrorList(error);
            handleDialogueOpen();
        }
    };


    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-sm-3 col-3">
                            <h4 className="page-title fw-bold">User</h4>
                        </div>
                        <div className=" col-sm-9 col-9 text-right m-b-20 d-flex justify-content-end">
                            <Link to="/account/users/add" className="btn btn-primary float-right btn-rounded">
                                <i className="fa fa-plus"></i> Add User
                            </Link>
                        </div>
                    </div>
                    {/* Search form start */}
                    <form action="/users" name="userFilter" >
                        <div className="row filter-row pb-4">

                            <div className="col-sm-4 col-md-4">
                                <div className="form-floating ">
                                    <input name="name" type="text" id="empNameSearch" className="form-control" placeholder='Name' />
                                    <label htmlFor='empNameSearch'>User Name</label>
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-4">
                                <div className="form-floating">
                                    <select name="role" className="form-select floating">
                                        <option value="">All</option>
                                        <option value="Doctor">Doctor</option>
                                        <option value="Admin">Admin</option>
                                        <option value="Patient">Patient</option>
                                        <option value="Corporate">Corporate</option>
                                    </select>
                                    <label htmlFor='role' className="focus-label">Role</label>
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-4">
                                <button type="submit" className="btn btn-primary btn-block"> Search </button>
                            </div>
                        </div>
                    </form>
                    {/* User table component */}
                    <UserTable userList={users} deleteUser={deleteUser} />
                    {
                    }
                </div>
                {/* Error dialog box */}
                <ErrorDialogueBox
                    open={errorDialogueBoxOpen}
                    handleToClose={handleDialogueClose}
                    ErrorTitle="Error: Add User"
                    ErrorList={errorList}
                />
            </div>
        </Box>


    )
}

export default UserList;
