import React from 'react';
import {
  useMediaQuery,
  Paper,
  Box,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from '@mui/material';

const ResponsiveTable = ({ columns, rows, renderActions }) => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (isMobile) {
    return (
      <Box>
        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
         <Box>
         {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
           <Card
             key={rowIndex}
             sx={{
               mb: 1.5,
               boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',
               borderRadius: '10px',
               padding: 2,
             }}
           >
             <Box display="flex" justifyContent="space-between" alignItems="top" sx={{ mb: 1, background: 'white' }}>
               <Typography
                 variant="h6"
                 sx={{
                   fontWeight: 'bold',
                   color: '#4a4a4a',
                   fontSize: '16px',
                 }}
               >
                 {row.Name || 'N/A'}
               </Typography>
               <Box alignItems="top" sx={{ background: 'white' }}>{renderActions(row.actionsID)}</Box>
             </Box>
             <CardContent sx={{ padding: 0 }}>
               <Box display="flex" flexWrap="wrap" sx={{ mb: 1, background: 'white' }}>
                 {columns
                   .filter((column) => column.id !== 'actionsID') // Exclude the actions column
                   .map((column, colIndex) => (
                     <Box
                       key={`${rowIndex}-${colIndex}`}
                       sx={{
                         width: '50%',
                         mb: 1,
                         display: 'flex',
                         flexDirection: 'column',
                         background: 'white'
                       }}
                     >
                       <Typography
                         variant="subtitle2"
                         sx={{
                           fontWeight: 'bold',
                           color: '#4a4a4a',
                           textTransform: 'uppercase',
                           fontSize: '12px',
                         }}
                       >
                         {column.label}:
                       </Typography>
                       <Typography
                         variant="body2"
                         sx={{
                           fontSize: '14px',
                           color: '#333',
                         }}
                       >
                         {row[column.id] || 'N/A'}
                       </Typography>
                     </Box>
                   ))}
               </Box>
             </CardContent>
           </Card>
         ))}
       </Box>
       
        ))}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            '& .MuiTablePagination-toolbar': {
              justifyContent: 'flex-end',
            },
          }}
        />
      </Box>
    );
  }

  return (
    <Paper
      sx={{
        width: '100%',
        overflow: 'hidden',
        marginTop: 2,
        boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
      }}
    >
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    minWidth: column.minWidth,
                    fontWeight: 'bold',
                    backgroundColor: '#f5f5f5',
                    color: '#333',
                    textTransform: 'uppercase',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
              <TableRow hover key={rowIndex}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{
                      fontSize: '14px',
                      color: '#333',
                      borderBottom: '1px solid #e0e0e0',
                    }}
                  >
                    {column.id === 'actionsID'
                      ? renderActions(row[column.id])
                      : row[column.id] || 'N/A'}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          '& .MuiTablePagination-toolbar': {
            justifyContent: 'flex-end',
          },
        }}
      />
    </Paper>
  );
};

export default ResponsiveTable;
