import React, { useState } from 'react';
import { Modal, Box, Button, TextField } from '@mui/material';
import axios from 'axios';
import ToastService from '../../Service/toast.service';

const RescheduleModal = ({ isOpen, onClose, appointmentId, onRescheduleSuccess }) => {
    const [newDate, setNewDate] = useState('');
    const [newTime, setNewTime] = useState('');

    const handleSubmit = async () => {
        if (!newDate || !newTime) {
            alert('Both date and time are required.');
            return;
        }

        try {
            const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
            const response = await axios.put(
                `${baseApi}/appointments/${appointmentId}`,
                { appointmentDate: newDate, appointmentTime: newTime },
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );

            if (response.data.message === 'success') {
                ToastService.success('Appointment rescheduled successfully.');
                setNewDate('');
                setNewTime('');
                onRescheduleSuccess();
                onClose();
            } else {
                throw new Error('Failed to reschedule appointment.');
            }
        } catch (error) {
            console.error('Error rescheduling appointment:', error.response?.data || error.message);
            ToastService.error('Failed to reschedule appointment. Please try again.');
        }
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box sx={{ padding: 2, backgroundColor: 'white', borderRadius: 1, width: 400, margin: 'auto', marginTop: '20%' }}>
                <h2>Reschedule Appointment</h2>
                <TextField
                    label="New Date"
                    type="date"
                    fullWidth
                    value={newDate}
                    onChange={(e) => setNewDate(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    label="New Time"
                    type="time"
                    fullWidth
                    value={newTime}
                    onChange={(e) => setNewTime(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Button onClick={handleSubmit} variant="contained" color="primary">
                        Submit
                    </Button>
                    <Button onClick={onClose} variant="outlined" color="secondary">
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default RescheduleModal;
