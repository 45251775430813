import React, { useState, useEffect } from "react";
import FrontendHeaderNav from '../Layout/Header/FrontendHeader'
import {
    Avatar,
    ListItemText,
    ListItemAvatar,
    Box,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Button,
    Stepper,
    Step,
    StepLabel,
    Typography,
    Grid,
    TextField,
    CircularProgress,
    MenuItem,
    FormControl,
    InputLabel,
    Paper,
    Select,
} from "@mui/material";
import moment from "moment";
import axios from "axios";

const BookingSimple = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [doctors, setDoctors] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState("");
    const [availableSlots, setAvailableSlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [viewMode, setViewMode] = useState("5-day");
    const [visibleDays, setVisibleDays] = useState([]);
    const [currentStartIndex, setCurrentStartIndex] = useState(0); 
    const [patientInfo, setPatientInfo] = useState({
        name: "",
        email: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [patients, setPatients] = useState([]);

    const steps = ["Select Doctor", "Choose Slot", "Enter Details", "Confirm Booking"];
    const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";

    const placeholderImage = "https://via.placeholder.com/40";

    const renderSlotGrid = (visibleDays) => {
        const startHour = 8; // Start time
        const endHour = 18; // End time
        const timeSlots = [];
    
        // Generate time slots
        for (let hour = startHour; hour <= endHour; hour++) {
            timeSlots.push(`${hour}:00`);
        }
    
        return (
            <Box sx={{ overflow: "auto", maxHeight: "600px", maxWidth: "100%" }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>Time</TableCell>
                            {visibleDays.map((day) => (
                                <TableCell key={day} sx={{ minWidth: "100px", textAlign: "center", fontWeight: "bold" }}>
                                    {moment(day).format("ddd, MMM D")}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {timeSlots.map((time) => (
                            <TableRow key={time}>
                                <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>{time}</TableCell>
                                {visibleDays.map((day) => {
                                    const slot = availableSlots.find(
                                        (slot) =>
                                            moment(slot.startTime).format("YYYY-MM-DD") === day &&
                                            moment(slot.startTime).format("HH:mm") === time
                                    );
                                    return (
                                        <TableCell
                                            key={day + time}
                                            onClick={() => slot && setSelectedSlot(slot)}
                                            sx={{
                                                backgroundColor: slot ? "lightgreen" : "white",
                                                border: "1px solid #ddd",
                                                cursor: slot ? "pointer" : "default",
                                                textAlign: "center",
                                                minWidth: "100px",
                                            }}
                                        >
                                            {slot ? "Available" : ""}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        );
    };    

    useEffect(() => {
        const fetchDoctorsAndPatients = async () => {
            setIsLoading(true);
            try {
                const [doctorsResponse, patientsResponse] = await Promise.all([
                    axios.get(`${baseApi}/doctors`, {
                        headers: { "Content-Type": "application/json" },
                    }),
                    axios.get(`${baseApi}/patients`, {
                        headers: { "Content-Type": "application/json" },
                    }),
                ]);
                console.log("Doctors Response:", doctorsResponse.data);
                setDoctors(doctorsResponse.data?.doctors || []);
                setPatients(patientsResponse.data || []);
            } catch (error) {
                console.error("Error fetching doctors or patients:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchDoctorsAndPatients();
    }, []);

    useEffect(() => {
        if (activeStep === 1 && selectedDoctor) {
            fetchAvailableSlots();
        }
    }, [activeStep, selectedDoctor]);

    const fetchAvailableSlots = async () => {
        if (!selectedDoctor) return;
        setIsLoading(true);
        try {
            const response = await axios.get(`${baseApi}/appointments/synced-slots?doctorId=${selectedDoctor}`, {
                headers: { "Content-Type": "application/json" },
            });
            setAvailableSlots(
                (response.data?.slots || []).map((slot) => ({
                    title: "Available",
                    start: new Date(slot.startTime),
                    end: new Date(slot.endTime),
                }))
            );
        } catch (error) {
            console.error("Error fetching slots:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
    const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

    const handleConfirmBooking = async () => {
        setIsLoading(true);
        try {
            await axios.post(
                `${baseApi}/bookings/add`,
                {
                    doctorId: selectedDoctor,
                    appointmentDate: moment(selectedSlot.start).format("YYYY-MM-DD"),
                    appointmentTime: moment(selectedSlot.start).format("HH:mm"),
                    patientName: patientInfo.name,
                    patientEmail: patientInfo.email,
                },
                {
                    headers: { "Content-Type": "application/json" },
                }
            );
            handleNext();
        } catch (error) {
            console.error("Error creating booking:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <FrontendHeaderNav />
            <Box sx={{ minWidth: '100vh !important', backgroundColor: 'white', mt: 0, pt: 0 }}>
                {}
                <Box
                    sx={{
                        height: '500px',
                        mt: 0,
                        backgroundImage: "url('https://docotela.co.za/wp-content/uploads/2024/09/Advantages-and-Disadvantages-of-Using-Online-Doctor-Services.webp')",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        color: "#fff",
                        marginBottom: '5rem'
                    }}
                >
                    <Typography variant="h3" gutterBottom>
                        Book Your Appointment
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2 }}>
                        <Button variant="contained" color="primary" size="large">
                            Learn More
                        </Button>
                        <Button variant="outlined" color="inherit" size="large">
                            Contact Us
                        </Button>
                    </Box>
                </Box>

                {}
                <Box sx={{ maxWidth: '800px', margin: "0 auto", mt: 5, backgroundColor: 'white' }}>
                    <h3>Book your appointment</h3>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    <Box sx={{ mt: 3, mb: 2, backgroundColor: 'white', minHeight: '60vh' }}>
                        {activeStep === 0 && (
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography>Select a Doctor</Typography>
                                    {isLoading ? (
                                        <CircularProgress />
                                    ) : (
                                        <FormControl fullWidth>
                                            <InputLabel id="doctor-select-label">Select Doctor</InputLabel>
                                            <Select
                                                labelId="doctor-select-label"
                                                value={selectedDoctor}
                                                onChange={(e) => setSelectedDoctor(e.target.value)}
                                                label="Select Doctor"
                                            >
                                                <MenuItem value="" disabled>
                                                    Select Doctor
                                                </MenuItem>
                                                {doctors.map((doctor) => (
                                                    <MenuItem key={doctor._id} value={doctor._id}>
                                                        {`${doctor.userId.firstName} ${doctor.userId.lastName}`}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={handleNext}
                                    >
                                        Next
                                    </Button>
                                </Grid>
                            </Grid>
                        )}

                        {activeStep === 1 && (
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography>Select an Available Slot</Typography>
                                    {isLoading ? (
                                        <CircularProgress />
                                    ) : (
                                        <Paper sx={{ padding: 2, height: 500, overflow: "auto" }}>
                                            {renderSlotGrid()}
                                        </Paper>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                    >
                                        Next
                                    </Button>
                                    <Button variant="outlined" onClick={handleBack} sx={{ ml: 2 }}>
                                        Back
                                    </Button>
                                </Grid>
                            </Grid>
                        )}

                        {activeStep === 2 && (
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography>Enter Patient Details</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Patient Name"
                                        fullWidth
                                        value={patientInfo.name}
                                        onChange={(e) => setPatientInfo({ ...patientInfo, name: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Patient Email"
                                        fullWidth
                                        value={patientInfo.email}
                                        onChange={(e) => setPatientInfo({ ...patientInfo, email: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                    >
                                        Next
                                    </Button>
                                    <Button variant="outlined" onClick={handleBack} sx={{ ml: 2 }}>
                                        Back
                                    </Button>
                                </Grid>
                            </Grid>
                        )}

                        {activeStep === 3 && (
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography>Confirm Booking</Typography>
                                    <Typography>
                                        Doctor: {doctors.find((doc) => doc.id === selectedDoctor)?.name}
                                    </Typography>
                                    <Typography>Slot: {moment(selectedSlot.start).format("LLL")}</Typography>
                                    <Typography>Patient Name: {patientInfo.name}</Typography>
                                    <Typography>Patient Email: {patientInfo.email}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {isLoading ? (
                                        <CircularProgress />
                                    ) : (
                                        <>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleConfirmBooking}
                                            >
                                                Confirm
                                            </Button>
                                            <Button variant="outlined" onClick={handleBack} sx={{ ml: 2 }}>
                                                Back
                                            </Button>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        )}

                        {activeStep === 4 && (
                            <Box>
                                <Typography variant="h5">Booking Confirmed!</Typography>
                                <Typography>Thank you for booking. A confirmation email has been sent.</Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
            <footer>
                <p>&copy; 2025 Docotela. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default BookingSimple;
