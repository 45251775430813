import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ConfirmDeleteDialogue from '../MUIDialogueBox/ConfirmDeleteDialogue';
import { useNavigate } from 'react-router-dom';

const columns = [
    { id: 'Name', label: 'Name', minWidth: 170 },
    { id: 'Email', label: 'Email', minWidth: 170 },
    { id: 'Phone', label: 'Phone', minWidth: 170 },
    { id: 'Department', label: 'Department', minWidth: 170 },
    { id: 'Address', label: 'Address', minWidth: 170 },
    { id: 'AvailableSlots', label: 'Available Slots', minWidth: 200 },
    { id: 'actionsID', label: 'Actions', minWidth: 100 },
];

function createData(Name, Email, Phone, Department, Address, AvailableSlots, actionsID) {
    return { Name, Email, Phone, Department, Address, AvailableSlots, actionsID };
}

function DoctorTable({ doctorList, deleteDoctor, fetchAppointments }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [doctorToDelete, setDoctorToDelete] = useState(null);
    const [availableSlots, setAvailableSlots] = useState({});
    const navigate = useNavigate();

    const handleDeleteClick = (id) => {
        setDoctorToDelete(id);
    };

    const handleConfirmDelete = () => {
        if (doctorToDelete) {
            deleteDoctor(doctorToDelete);
            setDoctorToDelete(null);
        }
    };

    const handleCancelDelete = () => {
        setDoctorToDelete(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const rows = doctorList.map((doctor) => {
        const user = doctor.userId || {};
        const doctorAvailableSlots = availableSlots[doctor._id] || [];
        
        const formattedSlots = doctorAvailableSlots.length
            ? `${doctorAvailableSlots[0]} +${doctorAvailableSlots.length - 1}`
            : "No available slots";
        
        return {
            Name: `${user.firstName || "N/A"} ${user.lastName || "N/A"}`,
            Email: user.email || "N/A",
            Phone: doctor.phone || "N/A",
            Department: doctor.department || "N/A",
            Address: doctor.address || "N/A",
            AvailableSlots: formattedSlots,
            actionsID: doctor._id,
        };
    });

    useEffect(() => {
        doctorList.forEach((doctor) => {
            fetchAppointments(doctor._id)
                .then((slots) => {
                    setAvailableSlots((prev) => ({
                        ...prev,
                        [doctor._id]: slots,
                    }));
                })
                .catch((error) => console.error("Error fetching slots:", error));
        });
    }, [doctorList, fetchAppointments]);

    return (
        <Paper
            sx={{
                width: '100%',
                overflow: 'hidden',
                marginTop: 2,
                boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)',
            }}
        >
            <TableContainer>
                <Table stickyHeader aria-label="doctors table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align="left"
                                    style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.length > 0 ? (
                            rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.actionsID}>
                                        {columns.map((column) => {
                                            const value = row[column.id];

                                            if (column.id === 'actionsID') {
                                                return (
                                                    <TableCell key={column.id} align="left">
                                                        <Tooltip title="Edit" placement="top" arrow>
                                                            <IconButton
                                                                aria-label={`edit doctor ${row.Name}`}
                                                                onClick={() =>
                                                                    navigate(`/account/doctors/edit/${value}`)
                                                                }
                                                            >
                                                                <EditIcon
                                                                    style={{ color: '#ff6600', fontSize: 24 }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete" placement="top" arrow>
                                                            <IconButton
                                                                aria-label={`delete doctor ${row.Name}`}
                                                                onClick={() => handleDeleteClick(value)}
                                                            >
                                                                <DeleteIcon
                                                                    style={{ color: 'red', fontSize: 24 }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                );
                                            } else {
                                                return (
                                                    <TableCell key={column.id} align="left">
                                                        {value}
                                                    </TableCell>
                                                );
                                            }
                                        })}
                                    </TableRow>
                                ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    No doctors found.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    '& p': {
                        marginTop: 'auto',
                        marginBottom: 'auto',
                    },
                }}
            />
            <ConfirmDeleteDialogue
                title="Confirm Delete"
                message="Are you sure you want to delete this doctor? This action cannot be undone."
                open={Boolean(doctorToDelete)}
                handleClose={handleCancelDelete}
                handleDelete={handleConfirmDelete}
            />
        </Paper>
    );
}

DoctorTable.propTypes = {
    doctorList: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string.isRequired,
            userId: PropTypes.shape({
                firstName: PropTypes.string,
                lastName: PropTypes.string,
                email: PropTypes.string,
            }),
            phone: PropTypes.string,
            department: PropTypes.string,
            address: PropTypes.string,
        })
    ).isRequired,
    deleteDoctor: PropTypes.func.isRequired,
    fetchAppointments: PropTypes.func.isRequired,
};

export default DoctorTable;
