import React, { useContext, useState } from 'react';
import { UserContext } from '../../Context/UserContext';
import { Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDeleteDialogue from '../MUIDialogueBox/ConfirmDeleteDialogue';
import { useNavigate } from 'react-router-dom';
import ResponsiveTable from '../MUITable/ResponsiveTable';

const createColumns = (isAdmin) => {
    const baseColumns = [
        { id: 'Company', label: 'Company', minWidth: 170 },
        { id: 'Name', label: 'Name', minWidth: 170 },
        { id: 'Description', label: 'Description', minWidth: 170 },
        { id: 'Price', label: 'Price', minWidth: 100 },
    ];
    if (isAdmin) {
        baseColumns.push({ id: 'actionsID', label: 'Actions', minWidth: 100 });
    }
    return baseColumns;
};

const createData = (Company, Name, Description, Price, actionsID) => ({
    Company, Name, Description, Price, actionsID,
});

export default function UserTable({ productList, deleteProduct }) {
    const { currentUser } = useContext(UserContext);
    const columns = createColumns(currentUser?.userType === 'Admin');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openConfirmDeleteDialogue, setOpenConfirmDeleteDialogue] = useState(false);
    const navigate = useNavigate();

    const handleDeleteDialogueOpen = () => {
        setOpenConfirmDeleteDialogue(true);
    };

    const handleDeleteDialogueClose = () => {
        setOpenConfirmDeleteDialogue(false);
    };

    const rows = productList.map((product) =>
        createData(product.company, product.name, product.description, product.price, product._id)
    );

    const renderActions = (actionsID) => (
        <>
            <Tooltip title="Edit" placement="top" arrow>
                <EditIcon
                    style={{ color: '#ff6600', fontSize: 30, cursor: 'pointer' }}
                    onClick={() => navigate(`/account/products/edit/${actionsID}`)}
                />
            </Tooltip>
            <Tooltip title="Delete" placement="top" arrow>
                <DeleteIcon
                    style={{ color: 'red', fontSize: 30, cursor: 'pointer' }}
                    onClick={handleDeleteDialogueOpen}
                />
            </Tooltip>
            <ConfirmDeleteDialogue
                title="Confirm Delete"
                message="Are you sure you want to delete this record? This action cannot be undone."
                open={openConfirmDeleteDialogue}
                handleClose={handleDeleteDialogueClose}
                handleDelete={() => {
                    deleteProduct(actionsID);
                    handleDeleteDialogueClose();
                }}
            />
        </>
    );

    return (
        <ResponsiveTable
            rows={rows}
            columns={columns}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            renderActions={renderActions}
        />
    );
}
