import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import axios from 'axios';
import Box from '@mui/material/Box';

function EditOrder() {
  const navigate = useNavigate();
  const { id } = useParams();


  const [email, setEmail] = useState('');
  const [productName, setProductName] = useState('');
  const [paid, setPaid] = useState(false);
  const [status, setStatus] = useState('pending');
  const [orderType, setOrderType] = useState('');
  const [price, setPrice] = useState(0);
  const [billingFrequency, setBillingFrequency] = useState('');
  const [vat, setVat] = useState(0);
  const [total, setTotal] = useState(0);

  const [bookingId, setBookingId] = useState('');

  const [billingName, setBillingName] = useState('');
  const [billingSurname, setBillingSurname] = useState('');
  const [mobile, setMobile] = useState('');
  const [physicalAddress, setPhysicalAddress] = useState('');
  const [billingAddress, setBillingAddress] = useState('');

  const [transactionId, setTransactionId] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('credit_card');

  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const handleDialogueOpen = () => setErrorDialogueBoxOpen(true);
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  useEffect(() => {
    getOrderById();
  }, []);

  const getOrderById = async () => {
    try {
      const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
      const response = await axios.get(`${baseApi}/orders/${id}`);
      const data = response.data;

      setEmail(data.email || '');
      setProductName(data.productName || '');
      setPaid(data.paid || false);
      setStatus(data.status || 'pending');
      setOrderType(data.orderType || '');
      setPrice(data.price || 0);
      setBillingFrequency(data.billingFrequency || '');
      setVat(data.vat || 0);
      setTotal(data.total || 0);

      if (data.bookingDetails) {
        setBookingId(data.bookingDetails.bookingId || '');
      }

      if (data.billingDetails) {
        setBillingName(data.billingDetails.name || '');
        setBillingSurname(data.billingDetails.surname || '');
        setMobile(data.billingDetails.mobile || '');
        setPhysicalAddress(data.billingDetails.physicalAddress || '');
        setBillingAddress(data.billingDetails.billingAddress || '');
      }

      if (data.paymentDetails) {
        setTransactionId(data.paymentDetails.transactionId || '');
        setPaymentMethod(data.paymentDetails.paymentMethod || 'credit_card');
      }
    } catch (error) {
      console.error('Error fetching order data:', error);
      setErrorList([error.message || 'Failed to fetch order data']);
      handleDialogueOpen();
    }
  };

  const updateOrder = async (e) => {
    e.preventDefault();
    try {
      const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
      await axios.patch(`${baseApi}/orders/${id}`, {
        email,
        productName,
        paid,
        status,
        orderType,
        price,
        billingFrequency,
        vat,
        total,
        bookingDetails: {
          bookingId,
        },
        billingDetails: {
          name: billingName,
          surname: billingSurname,
          email,
          mobile,
          physicalAddress,
          billingAddress,
        },
        paymentDetails: {
          transactionId,
          paymentMethod,
        },
      });
      navigate('/account/orders');
    } catch (error) {
      console.error(error);
      setErrorList(error.response?.data?.errors || [error.message]);
      handleDialogueOpen();
    }
  };

  const handlePriceVatChange = (updatedPrice, updatedVat) => {
    setPrice(updatedPrice);
    setVat(updatedVat);
    setTotal(updatedPrice + updatedVat);
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="page-title fw-bold">Edit Order - {id}</h3>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <form id="editOrderForm" name="editOrderForm" onSubmit={updateOrder}>
                  {}
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Email<span className="text-danger">*</span></label>
                        <input
                          className="form-control"
                          type="email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Product Name <span className="text-danger">*</span></label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          value={productName}
                          onChange={(e) => setProductName(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>Paid?</label>
                        <select
                          className="form-control"
                          value={paid}
                          onChange={(e) => setPaid(e.target.value === 'true')}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>Status</label>
                        <select
                          className="form-control"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="pending">Pending</option>
                          <option value="processing">Processing</option>
                          <option value="completed">Completed</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>Order Type</label>
                        <input
                          className="form-control"
                          type="text"
                          value={orderType}
                          onChange={(e) => setOrderType(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  {}
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Price</label>
                        <input
                          className="form-control"
                          type="number"
                          step="0.01"
                          value={price}
                          onChange={(e) => handlePriceVatChange(parseFloat(e.target.value) || 0, vat)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Billing Frequency</label>
                        <input
                          className="form-control"
                          type="text"
                          value={billingFrequency}
                          onChange={(e) => setBillingFrequency(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>VAT</label>
                        <input
                          className="form-control"
                          type="number"
                          step="0.01"
                          value={vat}
                          onChange={(e) =>
                            handlePriceVatChange(price, parseFloat(e.target.value) || 0)
                          }
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Total</label>
                        <input
                          className="form-control"
                          type="number"
                          step="0.01"
                          value={total}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  {}
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Booking ID</label>
                        <input
                          className="form-control"
                          type="text"
                          value={bookingId}
                          onChange={(e) => setBookingId(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  {}
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>First Name <span className="text-danger">*</span></label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          value={billingName}
                          onChange={(e) => setBillingName(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>Surname <span className="text-danger">*</span></label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          value={billingSurname}
                          onChange={(e) => setBillingSurname(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>Mobile</label>
                        <input
                          className="form-control"
                          type="text"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Physical Address</label>
                        <textarea
                          className="form-control"
                          value={physicalAddress}
                          onChange={(e) => setPhysicalAddress(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Billing Address</label>
                        <textarea
                          className="form-control"
                          value={billingAddress}
                          onChange={(e) => setBillingAddress(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  {}
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Transaction ID</label>
                        <input
                          className="form-control"
                          type="text"
                          value={transactionId}
                          onChange={(e) => setTransactionId(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Payment Method</label>
                        <select
                          className="form-control"
                          value={paymentMethod}
                          onChange={(e) => setPaymentMethod(e.target.value)}
                        >
                          <option value="credit_card">Credit Card</option>
                          <option value="paypal">PayPal</option>
                          <option value="bank_transfer">Bank Transfer</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  {}
                  <div className="m-t-20 text-left">
                    <button type="submit" className="btn btn-primary submit-btn">
                      Update Order
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {}
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Edit Order"
          ErrorList={errorList}
        />
      </div>
    </Box>
  );
}

export default EditOrder;
