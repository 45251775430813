import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Image as ImageIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
  InsertDriveFile as FileIcon,
} from "@mui/icons-material";
import ToastService from "../../Service/toast.service";

const MediaLibrary = () => {
  const [mediaFiles, setMediaFiles] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isGalleryView, setIsGalleryView] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [currentFolder, setCurrentFolder] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchMedia();
  }, [selectedCategory]);

  const fetchMedia = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    setLoading(true);

    try {
      const response = await fetch(`${baseApi}/media/list`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setMediaFiles(data);
    } catch (error) {
      console.error('Error fetching media:', error);
      ToastService.error('Failed to fetch media files');
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (!files.length) return;

    const formData = new FormData();
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
  
    Array.from(files).forEach((file) => {
      formData.append('files', file);
    });
  
    try {
      setLoading(true);
      const response = await fetch(`${baseApi}/media/upload`, {
        method: 'POST',
        body: formData,
        credentials: 'include',
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      setMediaFiles(prev => [...data, ...prev]);
      ToastService.success('Files uploaded successfully');
    } catch (error) {
      console.error('Error uploading files:', error);
      ToastService.error('Failed to upload files');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteFile = async (id) => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";
    
    try {
      const response = await fetch(`${baseApi}/media/delete/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Delete failed');
      }

      setMediaFiles(mediaFiles.filter((file) => file._id !== id));
      ToastService.success('File deleted successfully');
    } catch (error) {
      console.error('Error deleting file:', error);
      ToastService.error(error.message || 'Failed to delete file');
    }
  };

  const getFileIcon = (fileType) => {
    if (fileType.startsWith('image/')) {
      return <ImageIcon />;
    }
    return <FileIcon />;
  };

  const filteredFiles = mediaFiles.filter(
    (file) =>
      file.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (selectedCategory === "all" || file.category === selectedCategory)
  );

  return (
    <Box display="flex" height="100vh" bgcolor="#f5f5f5" width="100%">
      {}
      <Box
        width="250px"
        bgcolor="white"
        boxShadow="2"
        p={2}
        display="flex"
        flexDirection="column"
        borderRight="1px solid #ddd"
      >
        <Typography variant="h6" gutterBottom>
          Categories
        </Typography>
        <Select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          fullWidth
          size="small"
          sx={{ mb: 2 }}
        >
          <MenuItem value="all">All Files</MenuItem>
          <MenuItem value="images">Images</MenuItem>
          <MenuItem value="documents">Documents</MenuItem>
          <MenuItem value="videos">Videos</MenuItem>
          <MenuItem value="others">Others</MenuItem>
        </Select>
      </Box>

      {}
      <Box flex={1} p={3}>
        {}
        <Box display="flex" alignItems="center" mb={3}>
          <TextField
            placeholder="Search files..."
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ flex: 1, maxWidth: "400px", marginRight: "16px" }}
          />
          <Button
            variant="contained"
            component="label"
            startIcon={<AddIcon />}
            sx={{ marginRight: 2 }}
          >
            Upload
            <input type="file" hidden multiple onChange={handleFileUpload} />
          </Button>
          <IconButton onClick={() => setIsGalleryView(!isGalleryView)}>
            {isGalleryView ? <ViewListIcon /> : <ViewModuleIcon />}
          </IconButton>
        </Box>

        {}
        {loading && (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        )}

        {}
        {!loading && (
          <>
            {isGalleryView ? (
              <Grid container spacing={2}>
                {filteredFiles.map((file) => (
                  <Grid item xs={6} sm={4} md={3} key={file._id}>
                    <Box
                      border="1px solid #ddd"
                      borderRadius="8px"
                      overflow="hidden"
                      position="relative"
                      bgcolor="white"
                    >
                      {file.type.startsWith('image/') ? (
                        <img
                          src={file.url}
                          alt={file.name}
                          style={{
                            width: "100%",
                            height: "150px",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          height="150px"
                          bgcolor="#f5f5f5"
                        >
                          <FileIcon style={{ fontSize: 48, color: '#757575' }} />
                        </Box>
                      )}
                      <Box p={1}>
                        <Typography variant="body2" noWrap>
                          {file.name}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {(file.size / 1024).toFixed(2)} KB
                        </Typography>
                      </Box>
                      <IconButton
                        size="small"
                        onClick={() => handleDeleteFile(file._id)}
                        sx={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          background: "#fff",
                          boxShadow: 1,
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <List>
                {filteredFiles.map((file) => (
                  <ListItem
                    key={file._id}
                    divider
                    secondaryAction={
                      <IconButton onClick={() => handleDeleteFile(file._id)}>
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemIcon>
                      {getFileIcon(file.type)}
                    </ListItemIcon>
                    <ListItemText
                      primary={file.name}
                      secondary={`${file.type} - ${(file.size / 1024).toFixed(2)} KB`}
                    />
                  </ListItem>
                ))}
              </List>
            )}

            {}
            {filteredFiles.length === 0 && (
              <Box textAlign="center" mt={5}>
                <Typography variant="h6" color="textSecondary">
                  No media files found
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default MediaLibrary;
