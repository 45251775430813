import { createTheme } from "@mui/material/styles";

const createCustomTheme = (settings) => {
  return createTheme({
    palette: {
      primary: {
        main: settings.primaryColor || "#1976d2",
        light: "#63a4ff",
        dark: "#004ba0",
        contrastText: "#ffffff",
      },
      secondary: {
        main: settings.secondaryColor || "#ff5722",
        light: "#ff8a50",
        dark: "#c41c00",
        contrastText: "#ffffff",
      },
      tertiary: {
        main: settings.tertiaryColor || "#673ab7",
        light: "#8e99f3",
        dark: "#320b86",
        contrastText: "#ffffff",
      },
      text: {
        primary: settings.fontColor || "#4E5D71",
        secondary: "#757575",
      },
      background: {
        default: "#f5f5f5",
      },
    },
    typography: {
      fontFamily: "'Roboto', 'Arial', sans-serif",
    },
  });
};

export default createCustomTheme;
