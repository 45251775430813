import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import Box from '@mui/material/Box';
import EmployeeTable from '../MUITable/EmployeeTable';
import ToastService from '../../Service/toast.service';

function EmployeeList() {
    const params = new URLSearchParams(window.location.search);
    const name = params.get('name');

    const [employees, setEmployees] = useState([]);
    const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);

    const handleDialogueOpen = () => {
        setErrorDialogueBoxOpen(true);
    };

    const handleDialogueClose = () => {
        setErrorList([]);
        setErrorDialogueBoxOpen(false);
    };

    useEffect(() => {
        if (name) {
            getEmployees(name);
        } else {
            getAllEmployees();
        }
    }, [name]);

    const getAllEmployees = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        try {
            const response = await axios.get(`${baseApi}/employees`);
            console.log("Fetched all employees:", response.data);
            setEmployees(response.data);
        } catch (error) {
            handleFetchError(error, "Failed to fetch all employees.");
        }
    };

    const getEmployees = async (searchName) => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        try {
            const response = await axios.get(`${baseApi}/employees`, {
                params: {
                    name: searchName,
                },
            });
            console.log(`Fetched employees with name "${searchName}":`, response.data);
            setEmployees(response.data);
        } catch (error) {
            handleFetchError(error, "Failed to fetch employees by name.");
        }
    };

    const deleteEmployee = async (id) => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        try {
            await axios.delete(`${baseApi}/employees/${id}`);
            ToastService.success("Employee deleted successfully.");
            getAllEmployees();
        } catch (error) {
            handleFetchError(error, "Failed to delete the employee.");
        }
    };

    const handleFetchError = (error, fallbackMessage) => {
        const errors = [];
        if (error.response) {
            if (error.response.data && typeof error.response.data === 'object') {
                errors.push(error.response.data.message || fallbackMessage);
            } else {
                errors.push(fallbackMessage);
            }
        } else if (error.request) {
            errors.push("No response from server. Please try again later.");
        } else {
            errors.push(error.message || fallbackMessage);
        }

        setErrorList(errors);
        handleDialogueOpen();
    };

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-sm-3 col-3">
                            <h4 className="page-title fw-bold">Employee</h4>
                        </div>
                        <div className="col-sm-9 col-9 text-right m-b-20 d-flex justify-content-end">
                            <Link to="/account/employee/add" className="btn btn-primary float-right btn-rounded">
                                <i className="fa fa-plus"></i> Add Employee
                            </Link>
                        </div>
                    </div>
                    <form action="/account/employees" name="userFilter">
                        <div className="row filter-row">
                            <div className="col-sm-4 col-md-4">
                                <div className="form-floating">
                                    <input type="text" name="name" className="form-control" placeholder="Employee Name" />
                                    <label className="focus-label">Employee Name</label>
                                </div>
                            </div>

                            <div className="col-sm-4 col-md-4">
                                <button type="submit" className="btn btn-primary btn-block">Search</button>
                            </div>
                        </div>
                    </form>
                    <EmployeeTable
                        employeeList={employees.map((employee) => ({
                            ...employee,
                            userId: employee.userId || {},
                        }))}
                        deleteEmployee={deleteEmployee}
                    />
                </div>
                <ErrorDialogueBox
                    open={errorDialogueBoxOpen}
                    handleToClose={handleDialogueClose}
                    ErrorTitle="Error: Employee List"
                    ErrorList={errorList}
                />
            </div>
        </Box>
    );
}

export default EmployeeList;
