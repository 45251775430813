
import styles from './Dashboard.module.css';
import { React, useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import { UserContext } from '../../Context/UserContext'
import axios from "axios";
import moment from "moment"
import { NavLink } from 'react-router-dom';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import Button from '@mui/material/Button';
import Onboarding from '../Modal/OnboardingModal';

export default function CustomerDashboard() {
	const [open, setOpen] = useState(false);
	const { currentUser } = useContext(UserContext);
	const [firstEventInFuture, setFirstEventInFuture] = useState({});
	const [bookings, setBooking] = useState([]);

	useEffect(() => {
		const hasSeenWizard = localStorage.getItem('hasSeenWizard');
		if (!hasSeenWizard) {
		  setOpen(true); 
		  localStorage.setItem('hasSeenWizard', 'true');
		}
	  }, []);

	/**
	 * Get app month
	 * @since 0.1
	 * @param {} dateOfJoining 
	 * @returns 
	 */
	const getAppMonth = (dateOfJoining) => {
		if (!dateOfJoining) {
			return;
		}
		let month = new Date(dateOfJoining.slice(0, -1)).getMonth();
		let monthList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
		return monthList[month];
	}

	/**
	 * Get app date 
	 * @since 0.1
	 * @param {} dateOfJoining 
	 * @returns 
	 */
	const getAppDate = (dateOfJoining) => {
		if (!dateOfJoining) {
			return;
		}
		let date = new Date(dateOfJoining.slice(0, -1)).getDate();
		return date;
	}

	/**
	 * Get app year
	 * @since 0.1
	 * @param {} dateOfJoining 
	 * @returns 
	 */
	const getAppYear = (dateOfJoining) => {
		if (!dateOfJoining) {
			return;
		}
		let year = new Date(dateOfJoining.slice(0, -1)).getFullYear();
		return year;
	}

	/**
	 * Get booked slots
	 * @since 0.1
	 */
	const getBookedSlots = async () => {
		const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

		let response = await axios.post(`${baseApi}/Events`,
			{
				'isTimeSlotAvailable': false
			},
			{
				headers: {
					authorization: `Bearer ${localStorage.getItem("token")}`
				}
			}
		);
		if (response.data.message == "success") {
			let aptms = response.data.Events;
			console.log("aptms", aptms);
			const futureEvents = aptms.filter(event => {
				const eventDate = new Date(event.eventDate.slice(0, -1));
				const now = new Date();
				return eventDate > now;
			});
			console.log("futureEvents", futureEvents);

			if (futureEvents && futureEvents.length > 0) {
				const sortedEvents = futureEvents.sort((a, b) => {
					const aDate = new Date(a.eventDate.slice(0, -1));
					const bDate = new Date(b.eventDate.slice(0, -1));
					return aDate - bDate;
				});
				console.log("sortedEvents", sortedEvents)
				let firstApp = sortedEvents.find(event => {
					const eventDate = new Date(event.eventDate.slice(0, -1));
					const now = new Date();
					return eventDate > now;
				});
				console.log(firstApp)
				setFirstEventInFuture(firstApp)
			}
		}
		else {
		}


	}

	/**
	 * Get booking 
	 * @since 0.1
	 */
	const getBooking = async () => {
		const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

		let response = await axios.post(`${baseApi}/bookings`, {},
			{
				headers: {
					authorization: `Bearer ${localStorage.getItem("token")}`
				}
			}
		);
		if (response.data.message == "success") {
			let respBooking = response.data.bookings;
			let newResp = respBooking.sort((a, b) => {
				const timeA = new Date(`${moment(new Date(a.eventId.eventDate.slice(0, -1))).format('MM/DD/YYYY')} ${a.eventId.eventTime}`);
				const timeB = new Date(`${moment(new Date(b.eventId.eventDate.slice(0, -1))).format('MM/DD/YYYY')} ${b.eventId.eventTime}`);
				return timeB - timeA;
			});
			setBooking(newResp);
		}
	};

	/**
	 * Use effect to call booking functions
	 * @since 0.1
	 */
	useEffect(() => {
		getBookedSlots();
		getBooking();
	}, []);


	return (
		<Box className={styles.dashboardBody} component="main" sx={{ flexGrow: 1, p: 3 }}>
			<div id={styles.welcomeBanner}>
				<div className='text-white'>
					<h3 >Welcome!</h3>
					<br />
					<h4> {currentUser.firstName} {currentUser.lastName} </h4>
					<br />
					<div class={styles.horizontalLine}></div>
					At Green Hills, we believe that every customer deserves the highest quality care possible.
					<br />
					Our commitment to excellence in healthcare is matched only by our compassion for those we serve.
				</div>
			</div>

			<div className='row mt-5 justify-content-center'>
				<div className='col-md-6 col-sm-12'>
					<div className='customCustomerApt mx-auto' >
						<div className='topicHeader'>
							<h3 className='text-center'>Upcoming Event</h3>
						</div>
						<div className='topicContent'>
							{firstEventInFuture.eventDate && <div className='contentCard'>
								<div className='apDate'>
									<p className='date'>{getAppDate(firstEventInFuture.eventDate)}</p>
									<p>{getAppMonth(firstEventInFuture.eventDate)}</p>
									<p>{getAppYear(firstEventInFuture.eventDate)}</p>
								</div>
								<div className='apDetails'>
									<p className='py-2'>
										<span className='fw-bold'>Location Name </span>: {firstEventInFuture?.employeeId?.userId.firstName} {firstEventInFuture?.employeeId?.userId.lastName}
									</p>
									<p className='py-2'>
										<span className='fw-bold'>Department </span>: {firstEventInFuture?.employeeId?.department}
									</p>
									<p className='py-2'>
										<span className='fw-bold'>Time</span>: {firstEventInFuture?.eventTime}
									</p>
								</div>
							</div>}
							{!firstEventInFuture.eventDate && <div className='contentCard-empty'>
								<p className='fw-bolder'>You have no upcoming Events</p>
								<p className='mt-5'>Would you like to book a new Event?</p>
								<Button
									variant="contained"
									color="success"
									className='my-3'
									startIcon={<BookOnlineIcon />}
									component={NavLink}
									to="/account/appointments"
								>
									Book Now
								</Button>
							</div>}
						</div>
					</div>
				</div>
				<div className='col-md-6 col-sm-12'>
					<div className='customCustomerApt mx-auto' >
						<div className='topicHeader'>
							<h3 className='text-center'>Customer History</h3>
						</div>
						<div className='topicContent'>
							{bookings[0]?.eventId &&
								<div className='contentCard'>
									<div className='apDate'>
										<p className='date'>{getAppDate(bookings[0]?.eventId?.eventDate)}</p>
										<p>{getAppMonth(bookings[0]?.eventId?.eventDate)}</p>
										<p>{getAppYear(bookings[0]?.eventId?.eventDate)}</p>
									</div>
									<div className='apDetails'>
										<p className='py-2'>
											<span className='fw-bold'>Location Name </span>: {bookings[0]?.eventId?.employeeId?.userId?.firstName} {bookings[0]?.eventId?.employeeId?.userId?.lastName}
										</p>
										<p className='py-2'>
											<span className='fw-bold'>Department </span>: {bookings[0]?.eventId?.employeeId?.department}
										</p>
										<p className='py-2'>
											<span className='fw-bold'> Location's Remarks </span> : {bookings[0]?.remarks}
										</p>
									</div>
								</div>
							}
							{!bookings[0]?.eventId && <div className='contentCard-empty'>
								<p className='fw-bolder'>You have no medical history.</p>
							</div>
							}
						</div>
					</div>
				</div>
			</div>
			<Onboarding open={open} onClose={() => setOpen(false)} />
		</Box>
	);
}
