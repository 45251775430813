import React, { useState, useEffect } from "react";
import { Box, Tabs, Tab, Grid, Button, Checkbox, TextField } from "@mui/material";
import axios from 'axios';

const ConfigPage = () => {

  /**
   * Config use states
   * @since 0.1
   */
  const [tabIndex, setTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [menuConfig, setMenuConfig] = useState([
    { id: 1, name: "Dashboard", path: "account", visible: true },
    { id: 2, name: "Appointments", path: "account/appointments", visible: true },
    { id: 3, name: "Prescriptions", path: "account/prescriptions", visible: true },
    { id: 4, name: "Posts", path: "account/posts", visible: true },
    { id: 5, name: "Pages", path: "account/pages", visible: true },
  ]);

  const [systemConfig, setSystemConfig] = useState({
    HOSTNAME: "",
    LOGO_URL: "",
    COMPANY_NAME: "",
    HOME_BANNER_TEXT: "",
    PRIMARY_COLOUR: "",
    SECONDARY_COLOUR: "",
    TERTIARY_COLOUR: "",
  });

  const [securityConfig, setSecurityConfig] = useState({
    API_BASE_URL: "",
    GOOGLE_API_KEY: "",
    PAYPAL_CLIENT_ID: "",
    PAYFAST_MERCHANT_ID: "",
    SECRET_KEY: "",
  });

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://my.docotela.co.za/api";

  /**
   * useEffect to fetch config data on load
   * @since 0.1
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const systemConfigResponse = await axios.get(`${API_BASE_URL}/system-config`);
        setSystemConfig(systemConfigResponse.data);

        const menuConfigResponse = await axios.get(`${API_BASE_URL}/menu-config`);
        setMenuConfig(menuConfigResponse.data);

        const securityConfigResponse = await axios.get(`${API_BASE_URL}/security-config`);
        setSecurityConfig(securityConfigResponse.data);

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching configuration data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  /**
   * Handle config tabs change
   * @since 0.1
   * @param {*} event 
   * @param {*} newValue 
   */
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  /**
   * Handle saving config settings for system, menu and security
   * @since 0.1
   */
  const handleSave = async () => {
    try {
      await axios.post(`${API_BASE_URL}/system-config`, systemConfig);
      await axios.post(`${API_BASE_URL}/menu-config`, { menuConfig });
      await axios.post(`${API_BASE_URL}/security-config`, securityConfig);
      alert("Configuration saved successfully!");
    } catch (error) {
      console.error("Error saving configuration data:", error);
      alert("Failed to save configuration.");
    }
  };

  /**
   * Toggle menu item visibility on admin screen
   * @since 0.1
   * @param {*} id 
   * 
   * TODO: Disabled, re-enable
   */
  const toggleMenuItemVisibility = (id) => {
    setMenuConfig((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, visible: !item.visible } : item
      )
    );
  };

  /**
   * Update menu item display name
   * @since 0.1
   * @param {*} id 
   * @param {*} newName 
   */
  const updateMenuItemName = (id, newName) => {
    setMenuConfig((prev) =>
      prev.map((item) => (item.id === id ? { ...item, name: newName } : item))
    );
  };

  /**
   * Render tab content
   * @since 0.1
   * @returns 
   */
  const renderTabContent = () => {
    switch (tabIndex) {
      case 0:
        return (
          <Grid container spacing={2}>
            {Object.keys(systemConfig).map((key) => (
              <Grid item xs={12} sm={6} key={key}>
                <div className="form-group">
                  <label className="form-label">{key}</label>
                  <TextField
                    fullWidth
                    value={systemConfig[key]}
                    onChange={(e) =>
                      setSystemConfig((prev) => ({
                        ...prev,
                        [key]: e.target.value,
                      }))
                    }
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        );

      case 1:
        return (
          <Grid container spacing={2}>
            {menuConfig.map((item) => (
              <Grid
                item
                xs={12}
                sm={6}
                key={item.id}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  checked={item.visible}
                  onChange={() => toggleMenuItemVisibility(item.id)}
                />
                <TextField
                  fullWidth
                  value={item.name}
                  onChange={(e) => updateMenuItemName(item.id, e.target.value)}
                />
              </Grid>
            ))}
          </Grid>
        );

      case 2:
        return (
          <Grid container spacing={2}>
            {Object.keys(securityConfig).map((key) => (
              <Grid item xs={12} sm={6} key={key}>
                <div className="form-group">
                  <label className="form-label">{key}</label>
                  <TextField
                    fullWidth
                    value={securityConfig[key]}
                    onChange={(e) =>
                      setSecurityConfig((prev) => ({
                        ...prev,
                        [key]: e.target.value,
                      }))
                    }
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        );

      case 3:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <div className="form-group">
                <label>
                  First Name <span className="text-danger">*</span>
                </label>
                <input
                  name="firstName"
                  className="form-control"
                  type="text"
                  required
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </div>
            </Grid>
          </Grid>
        );

      default:
        return null;
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            <div className="row mb-3">
              <div className="col-sm-6">
                <h4 className="page-title fw-bold">Configuration Page</h4>
              </div>
              <div className="col-sm-6 text-right">
                <button onClick={handleSave} className="btn btn-primary">
                  Save Configuration
                </button>
              </div>
            </div>

            {/* Tabs start */}
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="System Config" />
              <Tab label="Menu Config" />
              <Tab label="Security Config" />
              <Tab label="User Info" />
            </Tabs>

            {/* Render tab content */}
            <div className="mt-3">{isLoading ? "Loading..." : renderTabContent()}</div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default ConfigPage;
