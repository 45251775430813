import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import Box from '@mui/material/Box';
import TeamTable from '../MUITable/TeamTable';
import ToastService from '../../Service/toast.service';

function TeamList() {
    const params = new URLSearchParams(window.location.search);
    const name = params.get('name');

    /**
     * Team use states
     * @since 0.1
     */
    const [teams, setTeam] = useState([]);
    const [users, setUsers] = useState([]);
    const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    
    /**
     * Handle error dialog open
     * @since 0.1
     */
    const handleDialogueOpen = () => {
        setErrorDialogueBoxOpen(true)
    };
    
    /**
     * Handle error dialog close
     * @since 0.1
     */
    const handleDialogueClose = () => {
        setErrorList([]);
        setErrorDialogueBoxOpen(false)
    };

    /**
     * useEffect to get team and users
     * @since 0.1
     */
    useEffect(() => {
        getTeams();
        getUsers();  
    }, []);

    /**
     * Get a list of the teams
     * @since 0.1
     */
    const getTeams = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
        const response = await axios.get(`${baseApi}/teams`, { params: { name: name } });
        setTeam(response.data);
    };

    /**
     * Get a list of users
     * @since 0.1
     * @returns 
     */
    const getUsers = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    
        const token = localStorage.getItem('token');
    
        if (!token) {
            console.error("Authentication token is missing. Please log in.");
            return;
        }
    
        try {
            const response = await axios.get(`${baseApi}/users`, {
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${token}`,
                }
            });
    
            setUsers(response.data);
            console.log("Users fetched:", response.data);
    
        } catch (error) {
            console.error("Error fetching users:", error);
    
            if (error.response && error.response.status === 401) {
                console.log("Unauthorized! Please log in again.");
            }
        }
    };

    /**
     * Delete a single team record
     * @since 0.1
     * @param {*} id 
     */
    const deleteTeam = async (id) => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
        try {
            await axios.delete(`${baseApi}/teams/${id}`);
            ToastService.success("Team deleted successfully.")
            getTeams();
        } catch (error) {
            const errors = [];
            if (error.response) {
                errors.push(error.response.data.message || "An error occurred.");
            } else if (error.request) {
                errors.push("No response from server. Please try again later.");
            } else {
                errors.push(error.message || "An unexpected error occurred.");
            }
            setErrorList(errors);
            handleDialogueOpen();
        }
    };

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-sm-4 col-3">
                            <h4 className="page-title fw-bold">Team</h4>
                        </div>
                        <div className="col-sm-8 col-9 text-right m-b-20">
                            <Link to="/account/teams/add" className="btn btn-primary float-right btn-rounded">
                                <i className="fa fa-plus"></i> Add Team
                            </Link>
                        </div>
                    </div>
                    {/* Search form start */}
                    <form action="/account/teams" name="userFilter">
                        <div className="row filter-row">
                            <div className="col-sm-4 col-md-4">
                                <div className="form-floating">
                                    <input type="text" name="name" className="form-control" placeholder="Team Name" />
                                    <label className="focus-label">Team Name</label>
                                </div>
                            </div>

                            <div className="col-sm-4 col-md-4">
                                <button type="submit" className="btn btn-primary btn-block"> Search </button>
                            </div>
                        </div>
                    </form>

                    {/* Team table component */}
                    <TeamTable teamList={teams} deleteTeam={deleteTeam} userList={users} />
                </div>

                {/* Error dialog box */}
                <ErrorDialogueBox
                    open={errorDialogueBoxOpen}
                    handleToClose={handleDialogueClose}
                    ErrorTitle="Error: Add Team"
                    ErrorList={errorList}
                />
            </div>
        </Box>
    );
}

export default TeamList;
