import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import Box from '@mui/material/Box';
import ToastService from '../../Service/toast.service';

function Addemployee() {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [department, setDepartment] = useState('');
  const [corporate, setCorporate] = useState('');
  const [team, setTeam] = useState('');

  const [corporates, setCorporates] = useState([]);
  const [teams, setTeams] = useState([]);

  const [loadingCorporates, setLoadingCorporates] = useState(true);
  const [loadingTeams, setLoadingTeams] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  const [passwordMatchDisplay, setPasswordMatchDisplay] = useState('none');
  const [passwordValidationMessage, setPasswordValidationMessage] = useState('');

  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const handleDialogueOpen = () => {
    setErrorDialogueBoxOpen(true);
  };

  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  useEffect(() => {
    const fetchCorporatesAndTeams = async () => {
      const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
      try {
        const [corporatesRes, teamsRes] = await Promise.all([
          fetch(`${baseApi}/corporates`),
          fetch(`${baseApi}/teams`)
        ]);

        if (!corporatesRes.ok || !teamsRes.ok) {
          throw new Error('Failed to fetch corporates or teams');
        }

        const corporatesData = await corporatesRes.json();
        const teamsData = await teamsRes.json();

        console.log('Corporates:', corporatesData);
        console.log('Teams:', teamsData);

        setCorporates(corporatesData);
        setTeams(teamsData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setFetchError(error.message);
      } finally {
        setLoadingCorporates(false);
        setLoadingTeams(false);
      }
    };

    fetchCorporatesAndTeams();
  }, []);

  const addemployee = async (event) => {
    event.preventDefault();

    if (!corporate) {
      setErrorList(['Please select a corporate.']);
      handleDialogueOpen();
      return;
    }

    if (!team) {
      setErrorList(['Please select a team.']);
      handleDialogueOpen();
      return;
    }

    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

    const employee = {
      firstName,
      lastName,
      username,
      email,
      phone,
      password,
      confirmPassword,
      department,
      corporateId: corporate,
      teamId: team,
    };

    console.log("Submitting employee data:", employee);

    try {
      const response = await fetch(`${baseApi}/employees`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(employee),
      });

      const data = await response.json();
      console.log("Server response:", data);

      if (response.ok && data.message === "success") {
        ToastService.success("Employee added successfully.");
        navigate("/account/employee");
      } else {
        setErrorList(data.errors || ["Failed to add employee."]);
        handleDialogueOpen();
      }
    } catch (error) {
      setErrorList([error.message || "An unexpected error occurred."]);
      handleDialogueOpen();
    }
  };

  useEffect(() => {
    if (password.length > 0 && password.trim().length <= 6) {
      setPasswordValidationMessage('Password length must be greater than 6 characters');
    } else {
      setPasswordValidationMessage('');
    }

    if (password === confirmPassword) {
      setPasswordMatchDisplay('none');
    } else {
      setPasswordMatchDisplay('block');
    }
  }, [password, confirmPassword]);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="page-title">Add Employee</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <form id="addemployeeForm" name='addemployeeForm' onSubmit={addemployee}>
                  <div className="row">
                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>First Name <span className="text-danger">*</span></label>
                        <input
                          name="firstName"
                          className="form-control"
                          type="text"
                          required
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          name="lastName"
                          className="form-control"
                          type="text"
                          required
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Username <span className="text-danger">*</span></label>
                        <input
                          name="username"
                          className="form-control"
                          type="text"
                          required
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Email <span className="text-danger">*</span></label>
                        <input
                          name="email"
                          className="form-control"
                          type="email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          name="password"
                          className="form-control"
                          type="password"
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        {passwordValidationMessage && (
                          <small className="text-danger">{passwordValidationMessage}</small>
                        )}
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <input
                          name="confirmPassword"
                          className="form-control"
                          type="password"
                          required
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <div style={{ display: passwordMatchDisplay, color: 'red' }}>
                          Passwords do not match
                        </div>
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Phone</label>
                        <input
                          name="phone"
                          className="form-control"
                          type="text"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Department</label>
                        <select
                          name="department"
                          className="form-select"
                          value={department}
                          onChange={(e) => setDepartment(e.target.value)}
                        >
                          <option value="">Select Department</option>
                          <option value="Finance">Finance</option>
                          <option value="Administration">Administration</option>
                          <option value="Accounts">Accounts</option>
                          <option value="Management">Management</option>
                          <option value="ICT">ICT</option>
                          <option value="Customer Service">Customer Service</option>
                        </select>
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Corporate <span className="text-danger">*</span></label>
                        {loadingCorporates ? (
                          <div>Loading corporates...</div>
                        ) : fetchError ? (
                          <div className="text-danger">Error loading corporates: {fetchError}</div>
                        ) : (
                          <select
                            name="corporate"
                            className="form-select"
                            value={corporate}
                            onChange={(e) => setCorporate(e.target.value)}
                            required
                          >
                            <option value="">Select Corporate</option>
                            {corporates.length > 0 ? (
                              corporates.map(corp => (
                                <option key={corp._id} value={corp._id}>
                                  {corp.legalName || corp.registeredName || 'Unnamed Corporate'}
                                </option>
                              ))
                            ) : (
                              <option value="" disabled>No corporates available</option>
                            )}
                          </select>
                        )}
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Team <span className="text-danger">*</span></label>
                        {loadingTeams ? (
                          <div>Loading teams...</div>
                        ) : fetchError ? (
                          <div className="text-danger">Error loading teams: {fetchError}</div>
                        ) : (
                          <select
                            name="team"
                            className="form-select"
                            value={team}
                            onChange={(e) => setTeam(e.target.value)}
                            required
                          >
                            <option value="">Select Team</option>
                            {teams.length > 0 ? (
                              teams.map(t => (
                                <option key={t._id} value={t._id}>
                                  {t.teamName || 'Unnamed Team'}
                                </option>
                              ))
                            ) : (
                              <option value="" disabled>No teams available</option>
                            )}
                          </select>
                        )}
                      </div>
                    </div>
                  </div>

                  {}
                  <div className="m-t-20 text-left">
                    <button
                      id="addemployee"
                      type="submit"
                      className="btn btn-primary submit-btn"
                      disabled={!corporate || !team}
                    >
                      Create Employee
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {}
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Add Employee"
          ErrorList={errorList}
        />
      </div>
    </Box>
  );
}

export default Addemployee;
