import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import Box from '@mui/material/Box';
import CustomerTable from '../MUITable/CustomerTable';

function CustomerList() {
    const params = new URLSearchParams(window.location.search);
    const name = params.get('name');

    const [customers, setCustomer] = useState([]);

    const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const handleDialogueOpen = () => {
        setErrorDialogueBoxOpen(true)
    };
    const handleDialogueClose = () => {
        setErrorList([]);
        setErrorDialogueBoxOpen(false)
    };

    useEffect(() => {
        getCustomers();
    }, []
    );

    const getCustomers = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        const response = await axios.get(`${baseApi}/customers`, {
            params: {
                name: name
            }
        });
        setCustomer(response.data);
    };

    const deleteCustomer = async (id) => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        try {
            await axios.delete(`${baseApi}/customers/${id}`);
            getCustomers();
        } catch (error) {
            setErrorList(error);
            handleDialogueOpen();
        }
    };


    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>

            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-sm-3 col-3">
                            <h4 className="page-title">Customer</h4>
                        </div>
                        <div className="col-sm-9 col-9 text-right m-b-20">
                            <Link to="/account/customers/add" className="btn btn-primary float-right btn-rounded">
                                <i className="fa fa-plus"></i> Add Customer
                            </Link>
                        </div>
                    </div>
                    <form action="/customers" name="userFilter" >
                        <div className="row filter-row">

                            <div className="col-sm-4 col-md-4">
                                <div className="form-floating">
                                    <input type="text" name="name" className="form-control" placeholder='Customer Name' />
                                    <label className="focus-label">Customer Name</label>
                                </div>
                            </div>

                            <div className="col-sm-4 col-md-4">
                                <button type="submit" className="btn btn-primary btn-block"> Search </button>
                            </div>
                        </div>
                    </form>
                    <CustomerTable customerList={customers} deleteCustomer={deleteCustomer} />
                    {
}
                </div>
                <ErrorDialogueBox
                    open={errorDialogueBoxOpen}
                    handleToClose={handleDialogueClose}
                    ErrorTitle="Error: Add Customer"
                    ErrorList={errorList}
                />
            </div>

        </Box>
    )
}

export default CustomerList;
