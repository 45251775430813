import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import ConfirmDeleteDialogue from '../MUIDialogueBox/ConfirmDeleteDialogue';
import { useNavigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';

const columns = [
    { id: 'teamName', label: 'Team Name', minWidth: 170 },
    { id: 'email', label: 'Email', minWidth: 170 },
    { id: 'phone', label: 'Phone', minWidth: 170 },
    { id: 'teamUsers', label: 'Team Users', minWidth: 170 },
    { id: 'actionsID', label: 'Actions', minWidth: 100 },
];

function createData(teamName = '-', email = '-', phone = '-', teamUsers = [], actionsID) {
    return {
        teamName,
        email,
        phone,
        teamUsers,
        actionsID,
    };
}

export default function TeamTable({ teamList = [], deleteTeam, userList = [] }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [openConfirmDeleteDialogue, setOpenConfirmDeleteDialogue] = React.useState(false);
    const [teamToDelete, setTeamToDelete] = React.useState(null);

    const navigate = useNavigate();

    const handleDeleteDialogueOpen = (teamId) => {
        setTeamToDelete(teamId);
        setOpenConfirmDeleteDialogue(true);
    };

    const handleDeleteDialogueClose = () => {
        setTeamToDelete(null);
        setOpenConfirmDeleteDialogue(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const rows = teamList.map((team) =>
        createData(
            team.teamName,
            team.email,
            team.phone,
            team.users,
            team._id
        )
    );

    // Function to get user names from userList
    const getUserNames = (userIds) => {
        return userIds
            .map(userId => {
                const user = userList.find(u => u._id.toString() === userId.toString()); // Ensure matching by string comparison
                return user ? `${user.firstName} ${user.lastName}` : null;
            })
            .filter(Boolean); // Remove nulls if user is not found
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: 2, boxShadow: "0 12px 24px rgba(0, 0, 0, 0.2)" }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align || 'left'}
                                    style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.actionsID}>
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        if (column.id === 'actionsID') {
                                            return (
                                                <TableCell key={column.id} align={column.align || 'left'}>
                                                    <Tooltip title="Edit" placement="top" arrow>
                                                        <EditIcon
                                                            className="mx-2"
                                                            style={{ color: '#ff6600', fontSize: 30 }}
                                                            onClick={() => {
                                                                navigate(`/account/teams/edit/${value}`);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="Delete" placement="top" arrow>
                                                        <DeleteIcon
                                                            className="mx-2"
                                                            style={{ color: 'red', fontSize: 30 }}
                                                            onClick={() => handleDeleteDialogueOpen(value)}
                                                        />
                                                    </Tooltip>
                                                    <ConfirmDeleteDialogue
                                                        title="Confirm Delete"
                                                        message="Are you sure you want to delete this record? This action cannot be undone."
                                                        open={openConfirmDeleteDialogue}
                                                        handleClose={handleDeleteDialogueClose}
                                                        handleDelete={() => {
                                                            deleteTeam(teamToDelete);
                                                            handleDeleteDialogueClose();
                                                        }}
                                                    />
                                                </TableCell>
                                            );
                                        } else if (column.id === 'teamUsers') {
                                            // Check if teamUsers is empty or not provided
                                            const userNames = getUserNames(value);
                                            if (userNames.length === 0) {
                                                return (
                                                    <TableCell key={column.id} align={column.align || 'left'}>
                                                        <Tooltip title="No assigned users" placement="top" arrow>
                                                            <span style={{ cursor: 'pointer' }} className="custom-badge status-red">
                                                                No assigned users
                                                            </span>
                                                        </Tooltip>
                                                    </TableCell>
                                                );
                                            }

                                            const firstUser = userNames[0] || '';
                                            const othersCount = userNames.length > 1 ? userNames.length - 1 : 0;
                                            return (
                                                <TableCell key={column.id} align={column.align || 'left'}>
                                                    <Tooltip
                                                        title={userNames.join(', ')}
                                                        placement="top"
                                                        arrow
                                                    >
                                                        <span style={{ cursor: 'pointer' }}
                                                            className="custom-badge status-green">
                                                            {`${firstUser} ${othersCount > 0 ? `+${othersCount}` : ''}`}
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                            );
                                        } else {
                                            return (
                                                <TableCell key={column.id} align={column.align || 'left'}>
                                                    {value}
                                                </TableCell>
                                            );
                                        }
                                    })}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    "& p": {
                        marginTop: 'auto',
                        marginBottom: 'auto',
                    },
                }}
            />
        </Paper>
    );
}
