import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import Box from '@mui/material/Box';
import ToastService from '../../Service/toast.service'
import {
  Tab,
  Tabs,
  Button,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination
} from '@mui/material';
import AddPatientWizard from './AddPatientWizard';

function MuiTabPanel(props) {
  const { children, value, index, sx, ...other } = props;
  return (
    <div
      style={{ background: 'white' }}
      role="tabpanel"
      hidden={value !== index}
      id={`mui-tabpanel-${index}`}
      aria-labelledby={`mui-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, background: 'white' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function AddPatient() {
  const navigate = useNavigate();

  const [isPartOfFamily, setIsPartOfFamily] = useState(false);
  const [familyName, setFamilyName] = useState('');
  const [familyMembers, setFamilyMembers] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [preferredComms, setPreferredComms] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDOB] = useState('');
  const [passwordMatchDisplay, setPasswordMatchDisplay] = useState('none');
  const [passwordValidationMessage, setPasswordValidationMessage] = useState('');
  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [newCondition, setNewCondition] = useState('');
  const [medications, setMedications] = useState([]);
  const [newMedication, setNewMedication] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleDialogueOpen = () => {
    setErrorDialogueBoxOpen(true);
  };

  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  const addFamilymember = (newMember) => {
    setFamilyMembers([...familyMembers, newMember]);
  }

  const editFamilyMember = (index, updatedMember) => {
    const updatedMembers = familyMembers.map((member, i) => (i === index ? updatedMember : member));
    setFamilyMembers(updatedMembers);
  }

  const handleNewPatientInFamily = (newPatient) => {
    setFamilyMembers((prev) => [...prev, newPatient._id]);
    setShowModal(false);
  };
    

  const handleBillingPatient = (index) => {
  };

  const addCondition = () => {
    if (newCondition.trim() !== '') {
      setConditions([...conditions, newCondition.trim()]);
      setNewCondition('');
    }
  };

  const removeCondition = (index) => {
    const updatedConditions = conditions.filter((_, i) => i !== index);
    setConditions(updatedConditions);
  };

  const addMedication = () => {
    if (newMedication.name.trim() !== '' && newMedication.startDate !== '') {
      setMedications([...medications, newMedication]);
      setNewMedication({ name: '', startDate: '', inUse: false });
    }
  };

  const removeMedication = (index) => {
    const updatedMedications = medications.filter((_, i) => i !== index);
    setMedications(updatedMedications);
  };

  const addPatient = (event) => {
    event.preventDefault();
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

    const form = document.forms.addPatientForm;
    let patient = {
      firstName: form.firstName.value,
      lastName: form.lastName.value,
      idNumber: form.idNumber.value,
      preferredComms: form.preferredComms.value,
      username: form.username.value,
      email: form.email.value,
      phone: form.phone.value,
      password: form.password.value,
      confirmPassword: form.confirmPassword.value,
      address: form.address.value,
      gender: form.gender.value,
      dob: form.dob.value,
      conditions: conditions,
      medications: medications,
    };

    fetch(`${baseApi}/patients`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(patient),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        let respMessage = data.message;
        if (respMessage === 'success') {
          ToastService.success("Patient added successfully.")
          navigate('/account/patients');
        } else {
          setErrorList(data.errors);
          handleDialogueOpen();
        }
      });
  };


const handleSaveFamily = () => {
  const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

  const familyPayload = {
    familyName: familyName,
    familyMembers: familyMembers.map(member => member.name),
  };

  fetch(`${baseApi}/families`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(familyPayload),
  })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        ToastService.success('Family saved successfully.');
      } else {
        setErrorList(data.errors || ['Failed to save family.']);
        handleDialogueOpen();
      }
    })
    .catch(err => {
      setErrorList(['An unexpected error occurred saving the family.']);
      handleDialogueOpen();
    });
};

  const familyColumns = [
    { id: 'fullName', label: 'Full Name', minWidth: 170 },
    { id: 'idNumber', label: 'ID Number', minWidth: 170},
    { id: 'actions', label: 'Actions', minWidth: 100 },
  ];

  const familyRows = familyMembers.map((member, index) => ({
    fullName: member.fullName,
    idNumber: member.idNumber,
    actions: index,
  }));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [newFamilyMemberName, setNewFamilyMemberName] = useState('');
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setNewFamilyMemberName('');
  };
  const handleAddFamilyMember = () => {
    if (newFamilyMemberName.trim() !== '') {
      setFamilyMembers([...familyMembers, { name: newFamilyMemberName }]);
      setNewFamilyMemberName('');
    }
    setShowModal(false);
  };

  useEffect(() => {
    if (password.length > 0 && password?.trim()?.length <= 6) {
      setPasswordValidationMessage('Password Length must be greater than 6 characters');
    } else {
      setPasswordValidationMessage('');
    }
    if (password === confirmPassword) {
      setPasswordMatchDisplay('none');
    } else {
      setPasswordMatchDisplay('block');
    }
  }, [password, confirmPassword]);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            <div className="row">
              <div className="col-lg-8 text-left">
                <h4 className="page-title">Add Patient</h4>
              </div>
            </div>

            { }
            <Tabs value={tabIndex} onChange={handleTabChange}>
              <Tab label="Patient Info" />
              <Tab label="Family Info" />
            </Tabs>

            { }
            <MuiTabPanel value={tabIndex} index={0}>
              <div className="row">
                <div className="col-lg-8">
                  <form id="addPatientForm" name="addPatientForm" onSubmit={addPatient}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>First Name <span className="text-danger">*</span></label>
                          <input
                            name="firstName"
                            className="form-control"
                            type="text"
                            value={firstName}
                            onChange={(event) => setFirstName(event.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Last Name</label>
                          <input
                            name="lastName"
                            className="form-control"
                            type="text"
                            value={lastName}
                            onChange={(event) => setLastName(event.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Username <span className="text-danger">*</span></label>
                          <input
                            name="username"
                            className="form-control"
                            type="text"
                            value={username}
                            onChange={(event) => setUsername(event.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Email <span className="text-danger">*</span></label>
                          <input
                            name="email"
                            className="form-control"
                            type="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Phone </label>
                          <input
                            name="phone"
                            className="form-control"
                            type="text"
                            value={phone}
                            onChange={(event) => setPhone(event.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Preferred Communication </label>
                          <input
                            name="preferredComms"
                            className="form-control"
                            type="text"
                            value={preferredComms}
                            onChange={(event) => setPreferredComms(event.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Password</label>
                          <input
                            name="password"
                            className="form-control"
                            type="password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Confirm Password</label>
                          <input
                            name="confirmPassword"
                            className="form-control"
                            type="password"
                            value={confirmPassword}
                            onChange={(event) => setConfirmPassword(event.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label>Address </label>
                          <input
                            name="address"
                            className="form-control"
                            type="text"
                            value={address}
                            onChange={(event) => setAddress(event.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>Gender</label>
                          <select
                            name="gender"
                            className="form-select"
                            value={gender}
                            onChange={(event) => setGender(event.target.value)}
                          >
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>Date of Birth </label>
                          <input
                            name="dob"
                            className="form-control"
                            type="date"
                            value={dob}
                            onChange={(event) => setDOB(event.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label>Id Number</label>
                          <input
                            name="idNumber"
                            className="form-control"
                            type="number"
                            value={idNumber}
                            onChange={(event) => setIdNumber(event.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="m-t-20 text-left">
                      <button
                        id="addPatient"
                        type="submit"
                        className="btn btn-primary submit-btn"
                      >
                        Create Patient
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-header">
                      <h4>Pre-existing Conditions</h4>
                    </div>
                    <div className="card-body">
                      <div className="form-group">
                        <label>Add Condition</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Condition name"
                            value={newCondition}
                            onChange={(e) => setNewCondition(e.target.value)}
                          />
                          <button
                            className="btn btn-primary"
                            onClick={addCondition}
                            type="button"
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <ul className="list-group mt-3">
                        {conditions.map((condition, index) => (
                          <li
                            key={index}
                            className="list-group-item d-flex justify-content-between align-items-center"
                          >
                            {condition}
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => removeCondition(index)}
                            >
                              Remove
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="card mt-4">
                    <div className="card-header">
                      <h4>Current Medications</h4>
                    </div>
                    <div className="card-body">
                      <div className="form-group">
                        <label>Medicine Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Medicine name"
                          value={newMedication.name}
                          onChange={(e) =>
                            setNewMedication({ ...newMedication, name: e.target.value })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>Start Date</label>
                        <input
                          type="date"
                          className="form-control"
                          value={newMedication.startDate}
                          onChange={(e) =>
                            setNewMedication({ ...newMedication, startDate: e.target.value })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          <input
                            type="checkbox"
                            checked={newMedication.inUse}
                            onChange={(e) =>
                              setNewMedication({ ...newMedication, inUse: e.target.checked })
                            }
                          />{' '}
                          Currently in use
                        </label>
                      </div>
                      <button className="btn btn-primary" onClick={addMedication} type="button">
                        Add Medication
                      </button>
                      <ul className="list-group mt-3">
                        {medications.map((medication, index) => (
                          <li
                            key={index}
                            className="list-group-item d-flex justify-content-between align-items-center"
                          >
                            <div>
                              <strong>{medication.name}</strong> -{' '}
                              {new Date(medication.startDate).toLocaleDateString()} -{' '}
                              {medication.inUse ? 'In Use' : 'Not in Use'}
                            </div>
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => removeMedication(index)}
                            >
                              Remove
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </MuiTabPanel>

            { }
            <MuiTabPanel value={tabIndex} index={1} sx={{ backgroundColor: 'white' }}>
              { }
              <div className="row">
                <div className="form-group col-md-4">
                  <label>Family Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={familyName}
                    onChange={(e) => setFamilyName(e.target.value)}
                  />
                </div>
              </div>

              { }
              <Button
                variant="contained"
                sx={{ mt: 2 }}
                onClick={() => setShowModal(true)}
              >
                Add Family Member
              </Button>

              { }
              <Paper sx={{ width: '95%', overflow: 'hidden', marginTop: 2, backgroundColor: 'white' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="family table">
                    <TableHead>
                      <TableRow>
                        {familyColumns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {familyRows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, idx) => (
                          <TableRow hover tabIndex={-1} key={idx}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>
                              <Tooltip title="Edit" placement="top" arrow>
                                <Button
                                  color="warning"
                                  onClick={() => editFamilyMember(row.actions, { name: row.name })}
                                >
                                  Edit
                                </Button>
                              </Tooltip>
                              <Tooltip title="Set Billing" placement="top" arrow>
                                <Button
                                  color="primary"
                                  onClick={() => handleBillingPatient(row.actions)}
                                >
                                  Billing
                                </Button>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={familyRows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                 <div className="col-md-4">
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2, mr: 2, maxWidth: '200px', maxHeight: '35px'}}
                  onClick={handleSaveFamily}
                >
                  Save Family
                </Button>
                </div>
              </Paper>
            </MuiTabPanel>
          </div>
        </div>

        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Add Patient"
          ErrorList={errorList}
        />

        { }
        <Dialog
          open={showModal}
          onClose={handleCloseModal}
          PaperProps={{
            sx: {
              width: 900,
              height: 700,
            },
          }}
        >
          <DialogTitle sx={{ m: 0, p: 2 }}>
            Add Family Member
            { }
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          { }
          <DialogContent dividers>
            <AddPatientWizard onPatientAdded={handleNewPatientInFamily}
              onWizardClose={handleCloseModal} />
          </DialogContent>
        </Dialog>
      </div>
    </Box>
  );
}

export default AddPatient;
