
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Box from "@mui/material/Box";
import ToastService from "../../Service/toast.service";
import ErrorDialogueBox from "../MUIDialogueBox/ErrorDialogueBox";
import { UserContext } from "../../Context/UserContext";

function EditTeam() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { isLoggedIn } = useContext(UserContext);

  /**
   * Team use states
   * @since 0.1
   */
  const [teamName, setTeamName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [corporateId, setCorporateId] = useState("");
  const [corporates, setCorporates] = useState([]);
  const [teamUsers, setTeamUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);

  /**
   * Handle error dialog open
   * @since 0.1
   * @returns 
   */
  const handleDialogueOpen = () => setErrorDialogueBoxOpen(true);

  /**
   * Handle error dialog close
   * @since 0.1
   */
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  const token = isLoggedIn ? localStorage.getItem("token") : null;

  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
  };

  /**
   * useEffect to fetch corporates, users and team by Id
   * @since 0.1
   */
  useEffect(() => {
    fetchCorporates();
    fetchUsers();
    getTeamById();
  }, []);

  /**
   * Fetch a list of corporates
   * @since 0.1
   */
  const fetchCorporates = async () => {
    const baseApi =
      process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";
    try {
      const response = await axios.get(`${baseApi}/corporates`, axiosConfig);
      setCorporates(response.data || []);
    } catch (error) {
      console.error("Error fetching corporates:", error);
      setErrorList(["Failed to fetch corporates."]);
      handleDialogueOpen();
    }
  };

  /**
   * Fetch a list of users
   * @since 0.1
   */
  const fetchUsers = async () => {
    const baseApi =
      process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";
    try {
      const response = await axios.get(`${baseApi}/users`, axiosConfig);
      setAllUsers(response.data || []);
    } catch (error) {
      console.error("Error fetching users:", error);
      setErrorList(["Failed to fetch users."]);
      handleDialogueOpen();
    }
  };

  /**
   * Get team by id
   * @since 0.1
   */
  const getTeamById = async () => {
    const baseApi =
      process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";
    try {
      const response = await axios.get(`${baseApi}/teams/${id}`, axiosConfig);

      const { teamName, email, phone, corporateId, users } = response.data;
      setTeamName(teamName || "");
      setEmail(email || "");
      setPhone(phone || "");
      setCorporateId(corporateId || "");
      setTeamUsers(users || []);
    } catch (error) {
      console.error("Error fetching team details:", error);
      setErrorList(["Failed to fetch team details."]);
      handleDialogueOpen();
    }
  };

  /**
   * Update team information
   * @since 0.1
   * @param {*} e 
   */
  const updateTeam = async (e) => {
    e.preventDefault();
    const baseApi =
      process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";

    try {
      const response = await axios.patch(
        `${baseApi}/teams/${id}`,
        {
          teamName,
          email,
          phone,
          corporateId: corporateId || null,
        },
        axiosConfig
      );
      navigate("/account/teams");
      ToastService.success("Team updated successfully.");
    } catch (error) {
      console.error("Error updating team:", error);
      const errors =
        error.response?.data?.errors ||
        [error.response?.data?.message || "An error occurred while updating the team."];
      setErrorList(errors);
      handleDialogueOpen();
    }
  };

  /**
   * Handle adding user to a team
   * @since 0.1
   * @param {*} user 
   * @returns 
   */
  const handleAddUser = async (user) => {
    if (teamUsers.find((u) => u._id === user._id)) return;

    const baseApi =
      process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";
    try {
      const updatedUsers = [...teamUsers.map((u) => u._id), user._id];

      const response = await axios.patch(
        `${baseApi}/teams/${id}/users`,
        {
          users: updatedUsers,
        },
        axiosConfig
      );

      const updatedTeam = response.data.team;

      setTeamUsers(updatedTeam.users || []);
      ToastService.success(`${user.firstName} added to the team.`);
    } catch (error) {
      console.error("Error adding user:", error);
      const errors =
        error.response?.data?.errors ||
        [error.response?.data?.message || "Failed to add user to the team."];
      setErrorList(errors);
      handleDialogueOpen();
      ToastService.error("Failed to add user to the team.");
    }
  };

  /**
   * Handle removal of user from team
   * @since 0.1
   */
  const handleRemoveUser = async (user) => {
    const baseApi =
      process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";
    try {
      const updatedUsers = teamUsers
        .filter((u) => u._id !== user._id)
        .map((u) => u._id);

      const response = await axios.patch(
        `${baseApi}/teams/${id}/users`,
        {
          users: updatedUsers,
        },
        axiosConfig
      );

      const updatedTeam = response.data.team;

      setTeamUsers(updatedTeam.users || []);
      ToastService.success(`${user.firstName} removed from the team.`);
    } catch (error) {
      console.error("Error removing user:", error);
      const errors =
        error.response?.data?.errors ||
        [error.response?.data?.message || "Failed to remove user from the team."];
      setErrorList(errors);
      handleDialogueOpen();
      ToastService.error("Failed to remove user from the team.");
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            <h3 className="page-title">Edit Team</h3>
            {/* Edit form start */}
            <form id="editTeamForm" onSubmit={updateTeam}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Team Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={teamName}
                      onChange={(e) => setTeamName(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Corporate</label>
                    <select
                      className="form-control"
                      value={corporateId || ""}
                      onChange={(e) =>
                        setCorporateId(e.target.value ? e.target.value : null)
                      }
                    >
                      <option value="">Select Corporate</option>
                      {corporates.map((corp) => (
                        <option key={corp._id} value={corp._id}>
                          {corp.legalName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="text-center mt-3">
                <button type="submit" className="btn btn-primary">
                  Update Team
                </button>
              </div>
            </form>
            <h4 className="mt-4">Manage Users</h4>
            <table className="table">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allUsers.map((user) => (
                  <tr key={user._id}>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>
                      {teamUsers.some((u) => u._id === user._id) ? (
                        <button
                          className="btn btn-danger"
                          onClick={() => handleRemoveUser(user)}
                        >
                          Remove
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={() => handleAddUser(user)}
                        >
                          Add
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        
        {/* Error dialog box */}
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Edit Team"
          ErrorList={errorList}
        />
      </div>
    </Box>
  );
}

export default EditTeam;
