import React, { useEffect, useState } from 'react';
import styles from './SignUp.module.css';
import { NavLink, useNavigate } from 'react-router-dom';
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import ToastService from "../../Service/toast.service"

function SignupPage() {
  const navigate = useNavigate();

  /**
   * Sign up use states
   * @since 0.1
   */
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatchDisplay, setPasswordMatchDisplay] = useState('none');
  const [passwordValidationMessage, setPasswordValidationMessage] = useState('');
  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);

  /**
   * Handle error dialog open
   * @since 0.1
   */
  const handleDialogueOpen = () => {
    setErrorDialogueBoxOpen(true);
  };

  /**
   * Handle error dialog close
   * @since 0.1
   */
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  /**
   * Handle signup form submission
   * @since 0.1
   * @param {} event 
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

    const user = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      password: password,
      confirmPassword: confirmPassword,
      userType: 'Customer'
    };

    try {
      const response = await fetch(`${baseApi}/signup`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      });

      const data = await response.json();

      console.log("Signup response data:", data);

      if (response.ok && data.message === "success") {
        ToastService.success("Successfully signed up.")
        navigate("/");
      } else {
        setErrorList(data.errors || ["An unexpected error occurred."]);
        handleDialogueOpen();
      }
    } catch (error) {
      console.error("Error during signup:", error);
      setErrorList(["Failed to connect to the server. Please try again later."]);
      handleDialogueOpen();
    }
  };

  /**
   * UseEffect for password validation
   * @since 0.1
   */
  useEffect(() => {
    if (password.length > 0 && password.trim().length <= 6) {
      setPasswordValidationMessage('Password length must be greater than 6 characters');
    }
    else {
      setPasswordValidationMessage('');
    }
    if (password === confirmPassword) {
      setPasswordMatchDisplay('none');
    }
    else {
      setPasswordMatchDisplay('block');
    }
  }, [password, confirmPassword]);

  return (
    <div className="bg-white dark-bg-primary" id={styles.signUpBody}>
      <div id={styles.signUpBG}>
        <div className={styles.greenLayer}></div>
      </div>
      <div>
        <h2>Create An Account</h2>
        {/* Signup form start */}
        <form id={styles.signUpform} name='signUpform' onSubmit={handleSubmit}>
          <div className='d-flex flex-column flex-lg-row flex-sm-column mt-5'>
            <div className='col-12 col-sm-12 col-lg-6 form-floating mx-2'>
              <input
                type="text"
                id="firstName"
                name="firstName"
                className="form-control"
                placeholder="first name"
                value={firstName}
                required
                onChange={(event) => setFirstName(event.target.value)}
              />
              <label htmlFor="firstName">First Name</label>
            </div>
            <div className='col-12 col-sm-12 col-lg-6 mt-3 mt-sm-3 mt-lg-0 form-floating mx-2'>
              <input
                type="text"
                id="lastName"
                name="lastName"
                className="form-control"
                placeholder="last name"
                value={lastName}
                required
                onChange={(event) => setLastName(event.target.value)}
              />
              <label htmlFor="lastName">Last Name</label>
            </div>
          </div>
          <div className='form-floating mt-3 col-12 mx-2'>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="name@example.com"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
              className="form-control"
            />
            <label htmlFor="email">Email</label>
          </div>
          <div className='form-floating mt-3 col-12 mx-2'>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              className="form-control"
              required
              placeholder="password"
            />
            <label htmlFor="password">Password</label>
          </div>
          <div className='mx-2 text-danger'>
            {passwordValidationMessage}
          </div>
          <div className='form-floating mt-3 col-12 mx-2'>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              className="form-control"
              required
              placeholder="confirm password"
            />
            <label htmlFor="confirmPassword">Confirm Password</label>
          </div>
          <div
            className="mx-2 text-danger"
            style={{
              display: `${passwordMatchDisplay}`
            }}>
            Password did not match
          </div>
          <div className="form-group form-check mt-5 mx-2">
            <input type="checkbox" className="form-check-input" id="terms-chkbox" required />
            <label className='' htmlFor="terms-chkbox">I agree with the terms and conditions</label>
          </div>
          <div className='text-center'>
            <button className="dark-btn-primary" id={styles.signUpBtn} type="submit">Sign Up</button>
          </div>
          <div className='text-center'>
            Already have an account? <NavLink className="text-primary dark-text-primary" to="/login" exact >Sign In</NavLink>
          </div>
        </form>
      </div>
      {/* Error dialog box */}
      <ErrorDialogueBox
        open={errorDialogueBoxOpen}
        handleToClose={handleDialogueClose}
        ErrorTitle="Error Signing Up"
        ErrorList={errorList}
      />
    </div>
  );
}

export default SignupPage;
