
import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  TextField,
} from "@mui/material";
import { savedbMedia, getdbMedia } from "../../Service/dbMediaService";
import imageCompression from 'browser-image-compression';
import axios from 'axios';
import ToastService from '../../Service/toast.service';
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';

const DocumentContent = () => {
  const [files, setFiles] = useState([]);
  const [newFile, setNewFile] = useState(null);
  const [isAddFileDialogOpen, setIsAddFileDialogOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);

  const [loading, setLoading] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);

  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

  /**
   * Fetches the list of files from the backend.
   */
  const fetchFiles = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    try {
      const response = await axios.get(`${baseApi}/dbmedia`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true
      });

      if (response.data && Array.isArray(response.data.files)) {
        setFiles(response.data.files);
      } else {
        setFiles([]);
        ToastService.info("No files found.");
      }
    } catch (error) {
      console.error("Error fetching files:", error.response?.data || error.message);
      setErrorList([error.response?.data?.error || "Failed to fetch files."]);
      setErrorDialogueBoxOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  /**
   * Handles the addition of new files.
   */
  const handleAddFile = async () => {
    if (!newFile || newFile.length === 0) {
      setErrorList(["Please select at least one file to upload."]);
      setErrorDialogueBoxOpen(true);
      return;
    }

    try {
      const uploadPromises = Array.from(newFile).map(async (file) => {
        let fileToUpload = file;

        if (file.type.startsWith('image/')) {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          };
          fileToUpload = await imageCompression(file, options);
        }

        return await savedbMedia(fileToUpload);
      });

      await Promise.all(uploadPromises);

      await fetchFiles();

      ToastService.success("Files uploaded successfully.");

      setNewFile(null);
      setIsAddFileDialogOpen(false);
    } catch (error) {
      console.error("Error uploading files:", error.response?.data || error.message);
      setErrorList([error.response?.data?.error || "Failed to upload files."]);
      setErrorDialogueBoxOpen(true);
    }
  };

  /**
   * Handles downloading a file.
   * @param {string} fileId - The ID of the file to download.
   * @param {string} fileName - The name of the file.
   */
  const handleDownloadFile = async (fileId, fileName) => {
    const token = localStorage.getItem("token");
    try {
      const blob = await getdbMedia(fileId, token);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName || `file-${fileId}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error.response?.data || error.message);
      setErrorList([error.response?.data?.error || "Failed to download file."]);
      setErrorDialogueBoxOpen(true);
    }
  };

  /**
   * Handles previewing a file.
   * @param {string} fileId - The ID of the file to preview.
   */
  const handlePreviewFile = async (fileId) => {
    const token = localStorage.getItem("token");
    try {
      const blob = await getdbMedia(fileId, token);
      const url = window.URL.createObjectURL(blob);
      setPreviewFile(url);
    } catch (error) {
      console.error("Error previewing file:", error.response?.data || error.message);
      setErrorList([error.response?.data?.error || "Failed to preview file."]);
      setErrorDialogueBoxOpen(true);
    }
  };

  /**
   * Handles file input change for multiple files.
   * @param {Event} e - The file input change event.
   */
  const handleFileInputChange = (e) => {
    setNewFile(e.target.files);
  };

  return (
    <Box display="flex" height="100vh" style={{ width: "100%" }}>
      {}
      <Box
        width="400px"
        p={2}
        bgcolor="#f5f5f5"
        display="flex"
        flexDirection="column"
        borderRight="1px solid #ddd"
        overflow="auto"
      >
        <Typography variant="h6" gutterBottom>
          Files
        </Typography>
        <Button
          variant="contained"
          size="small"
          onClick={() => setIsAddFileDialogOpen(true)}
          sx={{ mt: 1, mb: 2 }}
        >
          Add File
        </Button>
        <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Uploaded Files
          </Typography>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100px">
              <CircularProgress />
            </Box>
          ) : (
            <List>
              {Array.isArray(files) && files.length > 0 ? (
                files.map((file) => (
                  <ListItem key={file._id} divider>
                    <ListItemText
                      primary={
                        <>
                          <span
                            onClick={() => handleDownloadFile(file._id, file.name)}
                            style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
                          >
                            {file.name || "Untitled"}
                          </span>
                          {" | "}
                          <span
                            onClick={() => handlePreviewFile(file._id)}
                            style={{ cursor: "pointer", color: "green", textDecoration: "underline" }}
                          >
                            Preview
                          </span>
                        </>
                      }
                      secondary={`Type: ${file.type || 'Unknown'} | Size: ${(file.size / 1024).toFixed(2)} KB`}
                    />
                  </ListItem>
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No files available. Add some!
                </Typography>
              )}
            </List>
          )}
        </Paper>
      </Box>

      {}
      <Box flex={1} p={2} overflow="auto">
        {previewFile && (
          <Paper elevation={3} sx={{ p: 3, mb: 2 }}>
            <Typography variant="h5" gutterBottom>
              File Preview
            </Typography>
            {/\.(jpg|jpeg|png)$/i.test(previewFile) ? (
              <img src={previewFile} alt="Preview" style={{ maxWidth: "100%" }} />
            ) : (
              <iframe
                src={previewFile}
                title="Preview"
                style={{ width: "100%", height: "500px" }}
              ></iframe>
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setPreviewFile(null)}
              sx={{ mt: 2 }}
            >
              Close Preview
            </Button>
          </Paper>
        )}
      </Box>

      {}
      <Dialog open={isAddFileDialogOpen} onClose={() => setIsAddFileDialogOpen(false)}>
        <DialogTitle>Add File</DialogTitle>
        <DialogContent>
          <TextField
            type="file"
            fullWidth
            onChange={handleFileInputChange}
            inputProps={{ accept: "*/*", multiple: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddFileDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddFile} disabled={!newFile || newFile.length === 0}>
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {}
      <ErrorDialogueBox
        open={errorDialogueBoxOpen}
        handleToClose={handleDialogueClose}
        ErrorTitle="Error"
        ErrorList={errorList}
      />
    </Box>
  );
};

export default DocumentContent;
