import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

const TableLoader = ({ message = "Loading..." }) => {
    return (
        <Box sx={{ textAlign: "center", p: 3 }}>
            <CircularProgress />
        </Box>
    );
};

export default TableLoader;
