import React, { useState, useEffect, useContext } from 'react';
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import DatePicker from 'react-multi-date-picker';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { UserContext } from '../../Context/UserContext';
import Box from "@mui/material/Box";
import BookingTable from '../MUITable/BookingTable';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  TextField,
  MenuItem,
  Tab,
  Tabs,
  LinearProgress,
  Select,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  IconButton,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { gapi } from 'gapi-script';
import GoogleCalendarIntegration from '../../Service/gapi.service';
import { fetchCalendarEvents } from '../../utils/googleCalendarUtil';
import { generateAvailableSlots } from '../../utils/slotGenerator';
import ToastService from '../../Service/toast.service';
import AppointmentModal from '../Modal/AppointmentModal';

/**
 * Const for booking slot time limitation and duration
 * @since 0.1
 */
const WORKING_HOURS_START = '09:00';
const WORKING_HOURS_END = '17:00';
const SLOT_DURATION = 30;

/**
 * Returns a date range 
 * @since 0.1
 * @param {*} startDate - date range start
 * @param {*} endDate - date range end
 * @returns 
 */
const getDatesInRange = (startDate, endDate) => {
  const dates = [];
  let currentDate = startDate.clone().startOf('day');
  const lastDate = endDate.clone().startOf('day');

  while (currentDate.isSameOrBefore(lastDate)) {
    dates.push(currentDate.clone());
    currentDate.add(1, 'day');
  }

  return dates;
};

const localizer = momentLocalizer(moment);

const AdminAppointment = () => {

  /**
   * use states
   * @since 0.1
   */
  const [activeTab, setActiveTab] = useState(0);
  const [visibleCount, setVisibleCount] = useState(10);
  const { currentUser: contextUser } = useContext(UserContext);
  const [currentUser, setCurrentUser] = useState(contextUser);
  const [events, setEvents] = useState([]);
  const [patients, setPatients] = useState([]);
  const [addProgress, setAddProgress] = useState({ total: 0, completed: 0 });
  const [isAdding, setIsAdding] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [googleEvents, setGoogleEvents] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [availableSlots, setAvailableSlots] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openAppointmentDialog, setOpenAppointmentDialog] = useState(false);
  const [bookingDialogOpen, setBookingDialogOpen] = useState(false);
  const [activeAction, setActiveAction] = useState(null);
  const [followUpDate, setFollowUpDate] = useState('');
  const [rescheduleDate, setRescheduleDate] = useState('');
  const [rescheduleTime, setRescheduleTime] = useState('');
  const [calendarSource, setCalendarSource] = useState('google');
  const [isBookemLoaded, setIsBookemLoaded] = useState(false);
  const [isScriptLoading, setIsScriptLoading] = useState(false);
  const [scriptError, setScriptError] = useState(null);
  const [newSlot, setNewSlot] = useState({
    appointmentDate: '',
    selectedTimeSlot: '',
    customTime: '',
    patientName: '',
    patientEmail: '',
    patientId: '',
    doctorId: '',
    appointmentTime: '',
  });
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [isGoogleConnected, setIsGoogleConnected] = useState(false);
  const [googleAuthEmail, setGoogleAuthEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [eventDetailsDialogOpen, setEventDetailsDialogOpen] = useState(false);
  const [selectedEventDetails, setSelectedEventDetails] = useState(null);
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [addSlotDialogOpen, setAddSlotDialogOpen] = useState(false);
  const [newAvailableSlot, setNewAvailableSlot] = useState({
    doctorId: selectedDoctor || '',
    appointmentDates: [],
    appointmentTime: '',
  });
  const [addSlotLoading, setAddSlotLoading] = useState(false);
  const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";

  useEffect(() => {
    setNewAvailableSlot((prev) => ({
      ...prev,
      doctorId: selectedDoctor,
    }));
  }, [selectedDoctor]);

  /**
   * Get current user info from user context or localStorage
   * @since 0.1
   */
  useEffect(() => {
    if (!contextUser) {
      const storedUser = localStorage.getItem('currentUser');
      if (storedUser) {
        setCurrentUser(JSON.parse(storedUser));
      }
    } else {
      setCurrentUser(contextUser);
    }
  }, [contextUser]);

  /**
   * useEffect to check if current user is a doctor and set selected doctor to the current doctor
   * @since 0.1
   */
  useEffect(() => {
    if (currentUser && currentUser.userType === "doctor") {
      setSelectedDoctor(currentUser.doctorId);
    }
  }, [currentUser]);

  /**
   * Refresh bookings data
   * @since 0.1
   */
  const refreshBookings = () => {
    fetchBookings();
  };

  useEffect(() => {
    initializeGoogleAuth();
    fetchAppointments();
    fetchDoctors();
    fetchPatients();
  }, []);

  useEffect(() => {
    fetchBookings();
  }, [doctors, patients]);

  /**
   * useEffect to render Bookem booking embed code
   * @since 0.1
   */
  useEffect(() => {
    if (calendarSource === 'bookem') {
      if (!document.getElementById('bookem-script')) {
        setIsScriptLoading(true);
        const script = document.createElement('script');
        script.id = 'bookem-script';
        script.src = 'https://embed.bookem.com/embed.js';
        script.async = true;
        script.defer = true;

        script.onload = () => {
          setIsBookemLoaded(true);
          setIsScriptLoading(false);
        };

        script.onerror = () => {
          console.error('Failed to load the Bookem script.');
          setScriptError('Failed to load Bookem widget.');
          setIsScriptLoading(false);
        };

        document.body.appendChild(script);
      } else {
        setIsBookemLoaded(true);
      }
    }
  }, [calendarSource, isBookemLoaded]);


  useEffect(() => {
    if (calendarSource === 'bookem') {
      if (!document.getElementById('bookem-script')) {
        setIsScriptLoading(true);
        const script = document.createElement('script');
        script.id = 'bookem-script';
        script.src = 'https://embed.bookem.com/embed.js';
        script.async = true;
        script.defer = true;

        script.onload = () => {
          setIsBookemLoaded(true);
          setIsScriptLoading(false);
        };

        script.onerror = () => {
          console.error('Failed to load the Bookem script.');
          setScriptError('Failed to load Bookem widget.');
          setIsScriptLoading(false);
        };

        document.body.appendChild(script);
      } else {
        setIsBookemLoaded(true);
      }
    }
  }, [calendarSource, isBookemLoaded]);

  /**
   * Fetch bookings from bookings API
   * @since 0.1
   * @returns {Array} - returns array of bookings with patient name and email
   */
  const fetchBookings = async () => {
    console.log('Fetching bookings...');
    setLoading(true);
    try {
      const response = await axios.get(`${baseApi}/api/bookings`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      console.log('Raw bookings response:', response.data.bookings);

      const bookings = response.data.bookings;

      bookings.forEach((booking) => {
        console.log(`Looking for patient with ID: ${booking.patientId._id || booking.patientId}`);
      });

      console.log('Doctors:', doctors);
      console.log('Patients:', patients);

      if (response.data.bookings && Array.isArray(response.data.bookings)) {
        const enrichedBookings = response.data.bookings.map((booking) => {
          console.log(`Processing booking ID: ${booking._id}`);
          console.log('Full booking object:', booking);

          const doctor = doctors.find((doc) => doc._id === booking.doctorId._id);
          let doctorName = 'N/A';
          if (doctor && doctor.userId) {
            doctorName = `${doctor.userId.firstName || ''} ${doctor.userId.lastName || ''}`.trim();
          } else {
            console.warn(`Doctor not found for booking ID: ${booking._id}`);
          }

          const patientId = booking.patientId._id || booking.patientId;

          const patient = patients.find((pat) => pat._id === patientId);
          let patientName = 'N/A';
          if (patient && patient.userId) {
            patientName = `${patient.userId.firstName || ''} ${patient.userId.lastName || ''}`.trim();
          } else {
            console.warn(`Patient not found for booking ID: ${booking._id}`);
          }

          return {
            ...booking,
            patientName,
            doctorName,
          };
        });

        console.log('Enriched bookings:', enrichedBookings);
        setBookings(enrichedBookings);
      } else {
        console.error('Invalid bookings response:', response.data);
        setBookings([]);
      }
    } catch (error) {
      console.error('Error fetching bookings:', error);
      setBookings([]);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handle change of tabs 
   * @param {*} event 
   * @param {*} newValue 
   */
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  /**
   * Fetch appointments with the status 'available'
   * @since 0.1
   */
  const fetchAppointments = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseApi}/api/appointments`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (Array.isArray(response.data)) {
        const formattedEvents = response.data.map((appointment) => ({
          title: `${appointment.patientName || 'Available Slot'} - ${appointment.status || 'Available'}`,
          start: new Date(`${appointment.appointmentDate}T${appointment.appointmentTime}`),
          end: new Date(`${appointment.appointmentDate}T${appointment.appointmentTime}`),
          doctorId: appointment.doctorId,
          patientId: appointment.patientId,
          patientName: appointment.patientName,
          status: appointment.status,
          id: appointment._id,
          isSystem: true,
          fullAppointment: appointment,
        }));
        setEvents(formattedEvents);
      } else {
        setErrorList(['Failed to fetch appointments.']);
      }
    } catch (error) {
      console.error('Error fetching appointments:', error);
      setErrorList(['Failed to fetch appointments.']);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Fetch doctors from the doctos API endpoint
   * @since 0.1
   * @returns {Array} - array of doctor records
   */
  const fetchDoctors = async () => {
    try {
      const response = await axios.get(`${baseApi}/doctors`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setDoctors(response.data);
    } catch (error) {
      console.error('Error fetching doctors:', error);
      ToastService.error('Failed to fetch doctors.');
    }
  };


  /**
   * Fetch patients from the patiens API
   * @since 0.1
   * @returns {Array} - array of patients
   */
  const fetchPatients = async () => {
    try {
      const response = await axios.get(`${baseApi}/patients`, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem('token')}`
        },
      });
      if (Array.isArray(response.data)) {
        setPatients(response.data);
      } else {
        console.error('Invalid response data:', response.data);
        setPatients([]);
      }
    } catch (error) {
      console.error('Error fetching patients:', error);
      setPatients([]);
    }
  };

  /**
   * Delete a booking record
   * @since 0.1
   * @param {*} bookingId 
   * @returns 
   */
  const deleteBookings = async (bookingId) => {
    if (!bookingId) {
      ToastService.error("Invalid booking ID.");
      return;
    }

    const confirmDelete = window.confirm("Are you sure you want to delete this booking?");
    if (!confirmDelete) return;

    setLoading(true);
    try {
      const response = await axios.delete(`${baseApi}/api/bookings`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: { bookingId },
      });

      if (response.data.message === "Booking deleted successfully.") {
        ToastService.success("Booking deleted successfully.");
        fetchBookings();
      } else {
        ToastService.error(response.data.message || "Failed to delete booking.");
      }
    } catch (error) {
      console.error("Error deleting booking:", error);
      ToastService.error(error.response?.data?.errors?.[0] || "Failed to delete booking.");
    } finally {
      setLoading(false);
    }
  };

  /**
   * Approve a pending/tenative booking record
   * @since 0.1
   * @param {*} bookingId 
   * @returns 
   */
  const approveBooking = async (bookingId) => {
    if (!bookingId) {
      ToastService.error("Invalid booking ID.");
      return;
    }

    const confirmApprove = window.confirm("Are you sure you want to approve this booking?");
    if (!confirmApprove) return;

    setLoading(true);
    try {
      const response = await axios.put(`${baseApi}/api/bookings/approve/${bookingId}`, {}, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.data.message === "Booking approved successfully.") {
        ToastService.success("Booking approved successfully.");
        fetchBookings();
      } 
    } catch (error) {
      console.error("Error approving booking:", error);
      ToastService.error(error.response?.data?.errors?.[0] || "Failed to approve booking.");
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handle syncing of fetched Google events with big calendar
   * @since 0.1
   * @param {*} googleEventsFetched 
   * @returns 
   */
  const handleCalendarSync = async (googleEventsFetched) => {
    if (!selectedDoctor) {
      ToastService.error("Please select a doctor to sync slots.");
      return;
    }

    try {
      const busyEvents = googleEventsFetched.map(event => ({
        start: new Date(event.start.dateTime || event.start.date),
        end: new Date(event.end.dateTime || event.end.date),
      }));

      const startDate = moment().subtract(1, "months");
      const endDate = moment().add(6, "months");
      const datesToProcess = getDatesInRange(startDate, endDate);

      let totalRequests = 0;
      datesToProcess.forEach(date => {
        const formattedDate = date.format("YYYY-MM-DD");
        const availableSlotsForDay = generateAvailableSlots(
          busyEvents.filter(event =>
            moment(event.start).isSame(formattedDate, "day")
          ),
          formattedDate,
          WORKING_HOURS_START,
          WORKING_HOURS_END,
          SLOT_DURATION
        );
        totalRequests += availableSlotsForDay.timeSlots.length;
      });

      setAddProgress({ total: totalRequests, completed: 0 });
      setIsAdding(true);

      for (const date of datesToProcess) {
        const formattedDate = date.format("YYYY-MM-DD");

        const availableSlotsForDay = generateAvailableSlots(
          busyEvents.filter(event =>
            moment(event.start).isSame(formattedDate, "day")
          ),
          formattedDate,
          WORKING_HOURS_START,
          WORKING_HOURS_END,
          SLOT_DURATION
        );

        for (const time of availableSlotsForDay.timeSlots) {
          try {
            await axios.post(
              `${baseApi}/api/appointments/add`,
              {
                doctorId: selectedDoctor,
                appointmentDate: availableSlotsForDay.appointmentDate,
                appointmentTime: time,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
            setAddProgress(prev => ({ ...prev, completed: prev.completed + 1 }));
          } catch (error) {
            if (error.response?.data?.message === "Slot already exists.") {
              console.info(`Slot already exists: ${availableSlotsForDay.appointmentDate} ${time}`);
            } else {
              console.error("Error syncing slot:", error.response?.data || error.message);
            }
            setAddProgress(prev => ({ ...prev, completed: prev.completed + 1 }));
          }
        }
      }

      fetchDoctorSlots(selectedDoctor, moment().format("YYYY-MM-DD"));
      ToastService.success("Google Calendar slots synced successfully.");
    } catch (error) {
      console.error("Error syncing Google Calendar slots:", error);
      ToastService.error("Failed to sync slots. Please try again.");
    } finally {
      setIsAdding(false);
    }
  };

  /**
   * Fetch synced available appointments from the appointments API endpoint
   * @since 0.1
   * @param {*} doctorId 
   * @param {*} date 
   * @returns 
   */
  const fetchDoctorSlots = async (doctorId, date) => {
    if (!doctorId || !date) return;

    setLoadingSlots(true);
    try {
      const url = `${baseApi}/api/appointments/synced-slots`;
      console.log(`Fetching synced slots from URL: ${url}`);
      console.log(`Parameters: doctorID=${doctorId}, date=${date}`);

      const response = await axios.get(
        url,
        {
          params: {
            doctorID: doctorId,
            date: date
          },
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      );

      if (response.data.slots && Array.isArray(response.data.slots)) {
        console.log("Fetched slots:", response.data.slots);
        setAvailableSlots(response.data.slots);
      } else {
        setAvailableSlots([]);
        console.warn("No available slots found.");
      }
    } catch (error) {
      console.error("Error fetching available slots:", error);
      setAvailableSlots([]);
    } finally {
      setLoadingSlots(false);
    }
  };

  /**
   * Handle date selector change
   * @since 0.1
   * @param {*} date 
   */
  const handleDateChange = (date) => {
    setNewSlot({ ...newSlot, appointmentDate: date });
    if (selectedDoctor) {
      fetchDoctorSlots(selectedDoctor, date);
    }
  };

  /**
   * Handle adding a new available slot to a doctor
   * @since 0.1
   * @returns 
   */
  const handleAddAvailableSlot = async () => {
    const { doctorId, appointmentDate, appointmentTime } = newAvailableSlot;

    if (!doctorId || !appointmentDate || !appointmentTime) {
      ToastService.error("Please fill in all fields.");
      return;
    }

    const timeRegex = /^([0-1]\d|2[0-3]):([0-5]\d)$/;
    if (!timeRegex.test(appointmentTime)) {
      ToastService.error("Invalid time format. Please use HH:mm format.");
      return;
    }

    const payload = {
      doctorId,
      appointmentDate: moment(appointmentDate).format('YYYY-MM-DD'),
      appointmentTime,
    };

    setAddSlotLoading(true);

    try {
      const response = await axios.post(`${baseApi}/api/appointments/add`, payload, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.data.message === "Slot added successfully.") {
        ToastService.success("Available slot added successfully.");
        setAddSlotDialogOpen(false);
        setNewAvailableSlot({
          doctorId: selectedDoctor || '',
          appointmentDate: '',
          appointmentTime: '',
        });

        fetchDoctorSlots(doctorId, appointmentDate);
      } else {
        ToastService.error(response.data.message || "Failed to add available slot.");
      }
    } catch (error) {
      console.error("Error adding available slot:", error);
      if (error.response && error.response.data && error.response.data.message) {
        ToastService.error(error.response.data.message);
      } else {
        ToastService.error("Failed to add available slot.");
      }
    } finally {
      setAddSlotLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDoctor && newSlot.appointmentDate) {
      fetchDoctorSlots(selectedDoctor, newSlot.appointmentDate);
    }
  }, [selectedDoctor, newSlot.appointmentDate]);

  /**
   * Format google synced events to match calendar requirements
   * @since 0.1
   * @param {*} googleEventsFetched 
   */
  const onCalendarSync = (googleEventsFetched) => {
    const formattedGoogleEvents = googleEventsFetched.map((event) => ({
      title: event.summary || 'No Title',
      start: new Date(event.start.dateTime || event.start.date),
      end: new Date(event.end.dateTime || event.end.date),
      isSystem: false,
    }));

    setGoogleEvents(formattedGoogleEvents);
  };

  /**
   * Handle doctor selection
   * @since 0.1
   * @param {*} doctorId
   */
  const handleSelectDoctor = (doctorId) => {
    setSelectedDoctor(doctorId);
    setAvailableSlots([]);
    if (doctorId && newSlot.appointmentDate) {
      fetchDoctorSlots(doctorId, newSlot.appointmentDate);
    }
  };

  const handleSlotClick = (slot) => {
    setSelectedSlot({
      date: newSlot.appointmentDate,
      time: slot,
    });

    setNewSlot((prev) => ({
      ...prev,
      appointmentTime: slot,
    }));
    setBookingDialogOpen(true);

    console.log('Selected Slot from Manage Slots:', {
      date: newSlot.appointmentDate,
      time: slot,
    });
  };

  const handleSelectEvent = (event) => {
    setSelectedEventDetails(event);
    setEventDetailsDialogOpen(true);
  };

  const handleCreateBooking = async () => {
    if (!selectedSlot) {
      ToastService.error("No slot selected.");
      return;
    }

    if (!newSlot.patientId) {
      ToastService.error("Please select a patient.");
      return;
    }

    try {
      const bookingDate = selectedSlot.date;
      const bookingTime = selectedSlot.time;

      const appointmentData = {
        doctorId: selectedDoctor,
        bookingDate: bookingDate,
        bookingTime: bookingTime,
        patientId: newSlot.patientId,
        patientName: newSlot.patientName,
        patientEmail: newSlot.patientEmail,
      };

      const response = await axios.post(
        `${baseApi}/api/bookings/add`,
        appointmentData,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.message === "success") {
        ToastService.success("Appointment booked successfully.");
        setBookingDialogOpen(false);
      }
    } catch (error) {
      console.error("Error booking appointment:", error);
      ToastService.error(error.response?.data?.message || "Failed to book appointment.");
    }
  };

  const handleUpdateBooking = async (bookingId) => {
    if (!selectedSlot) {
      ToastService.error("No slot selected.");
      return;
    }

    if (!bookingId) {
      ToastService.error("Missing booking ID for update.");
      return;
    }

    try {
      const updatePayload = {
        bookingId: bookingId,
        patientId: currentUser.id,
        patientName: `${currentUser.firstName} ${currentUser.lastName}`,
        patientEmail: currentUser.email,
        status: "tentative",
        isTimeSlotAvailable: false,
      };

      const response = await axios.put(
        `${baseApi}/api/bookings/add`,
        {
          doctorId: selectedDoctor,
          bookingDate: selectedSlot.date,
          bookingTime: selectedSlot.time,
          ...updatePayload,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        ToastService.success("Appointment slot updated successfully.");
        fetchDoctorSlots(selectedDoctor, selectedSlot.date);
        setBookingDialogOpen(false);
      } else {
        throw new Error(response.data.message || "Failed to update appointment slot.");
      }
    } catch (error) {
      console.error("Error updating appointment:", error);
      ToastService.error(error.response?.data?.message || "Failed to update appointment.");
    }
  };

  const handleBookAppointment = async (appointmentId) => {
    if (!selectedSlot) {
      ToastService.error("No slot selected.");
      return;
    }

    try {
      const updatePayload = {
        appointmentId: appointmentId,
        patientId: currentUser._id,
        patientName: `${currentUser.firstName} ${currentUser.lastName}`,
        patientEmail: currentUser.email,
        status: "booked",
        isTimeSlotAvailable: false,
      };

      const response = await axios.put(
        `${baseApi}/api/appointments/book`,
        {
          doctorId: selectedDoctor,
          appointmentDate: selectedSlot.date,
          appointmentTime: selectedSlot.time,
          ...updatePayload,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        ToastService.success("Appointment slot updated successfully.");
      } else {
        throw new Error(response.data.message || "Failed to update appointment slot.");
      }

      fetchDoctorSlots(selectedDoctor, selectedSlot.date);
      setBookingDialogOpen(false);
    } catch (error) {
      console.error("Error booking appointment:", error);
      ToastService.error(error.response?.data?.message || "Failed to book appointment.");
    }
  };

  const handleCalendarSourceChange = (event, newSource) => {
    if (newSource !== null) {
      setCalendarSource(newSource);
      if (newSource === 'bookem' && !isBookemLoaded) {
        setIsBookemLoaded(false);
      }
    }
  };


  const handleAddSlot = async () => {
    try {
      const { appointmentDate, selectedTimeSlot, customTime } = newSlot;
      let appointmentTime;

      if (selectedTimeSlot === "custom") {
        if (!customTime) {
          setErrorList(["Custom time is required."]);
          return;
        }
        appointmentTime = customTime;
      } else if (selectedTimeSlot) {
        appointmentTime = selectedTimeSlot;
      } else {
        setErrorList(["Please select a time slot or choose to add a custom slot."]);
        return;
      }

      if (!/^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(appointmentTime)) {
        setErrorList(["Invalid time format. Please use HH:mm format."]);
        return;
      }

      const payload = {
        doctorId: selectedDoctor,
        appointmentDate: moment(appointmentDate).toISOString(),
        appointmentTime,
      };

      console.log("Adding slot with payload:", payload);

      const response = await axios.post(`${baseApi}/api/appointments/add`, payload, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      ToastService.success(response.data.message || "Slot added successfully.");
      fetchDoctorSlots(selectedDoctor, appointmentDate);
      setDialogOpen(false);
      setNewSlot({
        appointmentDate: "",
        selectedTimeSlot: "",
        customTime: "",
        patientName: "",
        patientEmail: "",
        patientId: "",
        doctorId: "",
        appointmentTime: "",
      });
    } catch (error) {
      if (error.response?.data?.message) {
        ToastService.info(error.response.data.message);
      } else {
        console.error("Error adding slot:", error.response || error.message);
        setErrorList(["Failed to add appointment slot."]);
      }
    }
  };

  const handlePatientChange = (patientId) => {
    const selectedPatient = patients.find((patient) => patient._id === patientId);
    if (selectedPatient) {
      setNewSlot((prev) => ({
        ...prev,
        patientName: `${selectedPatient.firstName} ${selectedPatient.lastName}`,
        patientEmail: selectedPatient.emailAddress,
        patientId,
      }));
    }
  };

  const handleDoctorChange = (doctorId) => {
    setNewSlot((prev) => ({
      ...prev,
      doctorId,
    }));

    fetchDoctorSlots(doctorId, newSlot.appointmentDate);
  };

  const handleRefresh = () => {
    fetchAppointments();
    if (isGoogleConnected) {
      setRefreshFlag(prev => !prev);
    }
    ToastService.success('Calendar refreshed successfully.');
  };

  const handleSelectSlot = (slotInfo) => {
    const selectedDate = moment(slotInfo.start).format('YYYY-MM-DD');
    const selectedTime = moment(slotInfo.start).format('HH:mm');

    setSelectedSlot({
      date: selectedDate,
      time: selectedTime,
    });

    setNewSlot((prev) => ({
      ...prev,
      appointmentDate: selectedDate,
      appointmentTime: selectedTime,
    }));

    setBookingDialogOpen(true);

    console.log('Selected Slot from Calendar:', {
      date: selectedDate,
      time: selectedTime,
    });
  };

  const createGoogleEvent = async (appointment) => {
    try {
      const auth = gapi.auth2.getAuthInstance();
      if (!auth || !auth.isSignedIn.get()) {
        throw new Error('User not authenticated with Google.');
      }
      const token = auth.currentUser.get().getAuthResponse().access_token;

      const event = {
        summary: `Appointment with ${appointment.patientName}`,
        start: {
          dateTime: `${appointment.appointmentDate}T${appointment.appointmentTime}:00`,
          timeZone: 'UTC',
        },
        end: {
          dateTime: moment(`${appointment.appointmentDate}T${appointment.appointmentTime}`).add(30, 'minutes').toISOString(),
          timeZone: 'UTC',
        },
        attendees: [
          { email: appointment.patientEmail || 'patient@example.com' },
        ],
        status: 'tentative',
      };

      await gapi.client.calendar.events.insert({
        calendarId: 'primary',
        resource: event,
      });

      ToastService.success('Google Calendar event created successfully.');
    } catch (error) {
      console.error('Error creating Google Calendar event:', error);
      setErrorList(['Failed to create Google Calendar event.']);
    }
  };

  const handleBookAppointmentFromCalendar = async () => {
    if (selectedSlot) {
      try {
        const appointmentDate = moment(selectedSlot.date).format('YYYY-MM-DD');
        const appointmentTime = moment(selectedSlot.time, 'HH:mm').format('HH:mm');

        const response = await axios.post(`${baseApi}/api/appointments/book`, {
          slotId: selectedSlot.id,
          patientName: newSlot.patientName,
          patientEmail: newSlot.patientEmail,
          appointmentDate,
          appointmentTime,
        }, {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        await createGoogleEvent(response.data.appointment);

        ToastService.success('Appointment booked and synced with Google Calendar.');
        setBookingDialogOpen(false);
        fetchAppointments();
      } catch (error) {
        console.error('Error booking appointment:', error);
        ToastService.error('Failed to book appointment.');
      }
    }
  };

  const initializeGoogleAuth = () => {
    gapi.load('client:auth2', async () => {
      if (!gapi.auth2) {
        try {
          await gapi.client.init({
            apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
            clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
            scope: 'https://www.googleapis.com/auth/calendar.events',
          });

          const authInstance = gapi.auth2.getAuthInstance();
          if (authInstance.isSignedIn.get()) {
            const user = authInstance.currentUser.get();
            const email = user.getBasicProfile().getEmail();
            const token = user.getAuthResponse().access_token;

            setIsGoogleConnected(true);
            setGoogleAuthEmail(email);
            localStorage.setItem('google_access_token', token);
            handleFetchEvents(authInstance);
          }
        } catch (error) {
          console.error('Error initializing Google API:', error);
        }
      }
    });
  };

  const handleFetchEvents = async (auth) => {
    try {
      const token = auth.currentUser.get().getAuthResponse().access_token;

      const timeMin = moment().toISOString();
      const timeMax = moment().add(6, 'months').toISOString();

      const events = await fetchCalendarEvents(token, timeMin, timeMax);

      if (onCalendarSync) onCalendarSync(events);

      localStorage.setItem('google_access_token', token);
    } catch (error) {
      console.error('Error fetching calendar events:', error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('google_access_token');
    if (token && gapi.auth2) {
      const authInstance = gapi.auth2.getAuthInstance();
      if (authInstance && authInstance.isSignedIn.get()) {
        handleFetchEvents(authInstance);
      }
    }
  }, []);

  useEffect(() => {
    if (isGoogleConnected) {
      const auth = gapi.auth2.getAuthInstance();
      if (auth && auth.isSignedIn.get()) {
        handleFetchEvents(auth);
      }
    }
  }, [refreshFlag, isGoogleConnected]);

  const handleFollowUp = async () => {
    if (!followUpDate) {
      ToastService.error("Please select a follow-up date.");
      return;
    }
    setLoading(true);
    try {
      await axios.post(`${baseApi}/api/appointments/${selectedEventDetails.id}/follow-up`, { followUpDate });
      ToastService.success("Follow-up scheduled successfully.");
      setActiveAction(null);
    } catch (error) {
      console.error("Error scheduling follow-up:", error);
      ToastService.error("Failed to schedule follow-up.");
    } finally {
      setLoading(false);
    }
  };

  const handleReschedule = async () => {
    if (!rescheduleDate || !rescheduleTime) {
      ToastService.error("Please select both date and time for rescheduling.");
      return;
    }
    setLoading(true);
    try {
      await axios.put(`${baseApi}/api/appointments/${selectedEventDetails.id}/reschedule`, {
        newDate: rescheduleDate,
        newTime: rescheduleTime,
      });
      ToastService.success("Appointment rescheduled successfully.");
      setActiveAction(null);
    } catch (error) {
      console.error("Error rescheduling appointment:", error);
      ToastService.error("Failed to reschedule appointment.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, maxWidth: '95%', margin: '0 auto' }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box" style={{ padding: '30px' }}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="Admin Appointment Tabs"
              sx={{ marginBottom: 3 }}
            >
              <Tab label="Calendar" />
              <Tab label="Bookings" />
            </Tabs>
            {isAdding && (
              <Box sx={{ width: '100%', mb: 2 }}>
                <Typography variant="body2" gutterBottom>
                  Adding slots: {addProgress.completed} / {addProgress.total}
                </Typography>
                <LinearProgress variant="determinate" value={(addProgress.completed / addProgress.total) * 100} />
              </Box>
            )}

            {activeTab === 0 && (
              <Box sx={{ background: 'white', padding: '0px !important' }}>
                <Grid container alignItems="top" justifyContent="space-between" spacing={1} sx={{ padding: '0px !important', marginTop: '0px', marginBottom: '0px' }}>
                  <Grid item xs={12} md={6} sx={{ padding: '0px !important', marginTop: '0px', marginBottom: '0px' }}>
                    <Box display="flex" alignItems="top" sx={{ backgroundColor: 'white', padding: '0px !important', marginTop: '0px', marginBottom: '0px' }}>
                      <Typography variant="h5" gutterBottom>
                        Appointments
                      </Typography>
                      <IconButton
                        color="primary"
                        onClick={handleRefresh}
                        style={{ marginLeft: '10px' }}
                        aria-label="refresh calendar"
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid spacing={0} item xs={12} md={3} style={{ textAlign: 'right', padding: '0px', marginTop: '0px', marginBottom: '0px' }}>
                    <GoogleCalendarIntegration
                      onCalendarSync={handleCalendarSync}
                      isGoogleConnected={isGoogleConnected}
                      setIsGoogleConnected={setIsGoogleConnected}
                      googleAuthEmail={googleAuthEmail}
                      setGoogleAuthEmail={setGoogleAuthEmail}
                      refreshFlag={refreshFlag}
                      disabled={!selectedDoctor || isAdding}
                    />
                  </Grid>
                </Grid>

                {errorList.length > 0 && (
                  <Box mt={0} sx={{ backgroundColor: 'white', padding: '0px !important' }}>
                    {errorList.map((error, index) => (
                      <ErrorDialogueBox key={index} errorMessage={error} />
                    ))}
                  </Box>
                )}

                <Box sx={{ marginBottom: 0, marginTop: 0, background: 'white' }}>
                  <Typography variant="h6" gutterBottom>
                    Select Calendar Source:
                  </Typography>
                  <ToggleButtonGroup
                    value={calendarSource}
                    exclusive
                    onChange={handleCalendarSourceChange}
                    aria-label="calendar source"
                    sx={{ marginBottom: 0 }}
                  >
                    <ToggleButton value="google" aria-label="Google Calendar">
                      Google Calendar
                    </ToggleButton>
                    <ToggleButton value="bookem" aria-label="Bookem">
                      Bookem
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>

                {calendarSource === 'google' ? (
                  <Grid container spacing={3} style={{ marginTop: '0px' }}>
                    <Grid item xs={12} md={8}>
                      <Paper style={{ padding: '0px', height: '100%' }}>
                        {loading ? (
                          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <CircularProgress />
                          </Box>
                        ) : (
                          <Calendar
                            localizer={localizer}
                            events={[...events, ...googleEvents]}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: 500 }}
                            selectable
                            onSelectEvent={handleSelectEvent}
                            onSelectSlot={handleSelectSlot}
                            popup
                          />
                        )}
                      </Paper>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Paper style={{ padding: '20px' }}>
                        <Typography variant="h6" gutterBottom>
                          Manage Slots
                        </Typography>
                        <Select
                          value={selectedDoctor}
                          onChange={(e) => handleSelectDoctor(e.target.value)}
                          displayEmpty
                          fullWidth
                          className="mt-2 mb-2"
                        >
                          <MenuItem value="" disabled>
                            Select Doctor
                          </MenuItem>
                          {doctors.map((doctor) => (
                            <MenuItem key={doctor._id} value={doctor._id}>
                              {`${doctor.userId.firstName} ${doctor.userId.lastName}`}
                            </MenuItem>
                          ))}
                        </Select>
                        <TextField
                          label="Select Date"
                          type="date"
                          className="mt-2 mb-2"
                          value={newSlot.appointmentDate}
                          onChange={(e) => handleDateChange(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setAddSlotDialogOpen(true)}
                          disabled={!selectedDoctor}
                          fullWidth
                          style={{ marginBottom: '20px' }}
                          startIcon={<AddIcon />}
                        >
                          Add Availability Slot
                        </Button>

                        <div className="d-flex text-center align-items-center justify-content-center">
                          <Typography variant="subtitle1" gutterBottom sx={{ mb: 3 }}>
                            Available Slots for {newSlot.appointmentDate ? moment(newSlot.appointmentDate).format("MMMM D, YYYY") : 'Select a date'}
                          </Typography>
                        </div>
                        <Grid container spacing={2} style={{ maxHeight: "300px", overflowY: "auto", textAlign: "center", justifyContent: 'center' }}>
                          {availableSlots.length > 0 ? (
                            availableSlots.map((slot, index) => (
                              <Button
                                key={index}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => handleSlotClick(slot)}
                                style={{ marginBottom: '10px', marginLeft: '15px' }}
                              >
                                {moment(slot, "HH:mm").format("hh:mm A")}
                              </Button>
                            ))
                          ) : (
                            <Typography>No available slots for the selected date.</Typography>
                          )}
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                ) : (
                  <Box sx={{ background: 'white' }}>
                    <Paper elevation={3} sx={{ padding: 4 }}>
                      { }
                      {isScriptLoading && (
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                          <CircularProgress />
                        </Box>
                      )}

                      {scriptError && (
                        <Typography variant="body1" color="error">
                          {scriptError}
                        </Typography>
                      )}

                      { }
                      {isBookemLoaded && !scriptError && (
                        <div style={{ width: '100%', minHeight: '600px', justifyContent: 'center', paddingLeft: '75px' }}>
                          <bookem-widget business-id="085ea543afb146b59399611adcd6aedd"></bookem-widget>
                        </div>
                      )}
                    </Paper>
                  </Box>
                )}
              </Box>
            )}

            {activeTab === 1 && (
              <Box sx={{ background: 'white' }}>
                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                  <BookingTable bookingList={bookings} refreshBookings={refreshBookings} deleteBookings={deleteBookings} approveBooking={approveBooking} />
                </Grid>
              </Box>
            )}

            <Dialog open={addSlotDialogOpen} onClose={() => setAddSlotDialogOpen(false)} aria-labelledby="add-slot-dialog-title">
              <DialogTitle id="add-slot-dialog-title">Add Available Slot</DialogTitle>
              <DialogContent>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  { }
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Doctor
                    </Typography>
                    <Select
                      fullWidth
                      value={newAvailableSlot.doctorId}
                      onChange={(e) => setNewAvailableSlot({ ...newAvailableSlot, doctorId: e.target.value })}
                      displayEmpty
                      style={{ marginBottom: "10px" }}
                    >
                      <MenuItem value="" disabled>
                        Select Doctor
                      </MenuItem>
                      {doctors.map((doctor) => (
                        <MenuItem key={doctor._id} value={doctor._id}>
                          {`${doctor.userId.firstName} ${doctor.userId.lastName}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  { }
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Appointment Date
                    </Typography>
                    <TextField
                      label="Appointment Date"
                      type="date"
                      fullWidth
                      value={newAvailableSlot.appointmentDate}
                      onChange={(e) => setNewAvailableSlot({ ...newAvailableSlot, appointmentDate: e.target.value })}
                      InputLabelProps={{ shrink: true }}
                      style={{ marginBottom: "10px" }}
                    />
                  </Grid>

                  { }
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Appointment Time
                    </Typography>
                    <TextField
                      label="Appointment Time"
                      type="time"
                      fullWidth
                      value={newAvailableSlot.appointmentTime}
                      onChange={(e) => setNewAvailableSlot({ ...newAvailableSlot, appointmentTime: e.target.value })}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        step: 300,
                      }}
                      style={{ marginBottom: "10px" }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setAddSlotDialogOpen(false)} color="secondary" disabled={addSlotLoading}>
                  Cancel
                </Button>
                <Button onClick={handleAddAvailableSlot} color="primary" disabled={addSlotLoading}>
                  {addSlotLoading ? <CircularProgress size={24} /> : 'Add Slot'}
                </Button>
              </DialogActions>
            </Dialog>

            { }
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
              <DialogTitle>Create Appointment</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Patient
                    </Typography>
                    <Select
                      fullWidth
                      value={newSlot.patientId || ""}
                      onChange={(e) => handlePatientChange(e.target.value)}
                      displayEmpty
                      style={{ marginBottom: "10px" }}
                    >
                      <MenuItem value="" disabled>
                        Select Patient
                      </MenuItem>
                      {patients.map((patient) => (
                        <MenuItem key={patient._id} value={patient._id}>
                          {`${patient.firstName} ${patient.lastName}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Doctor
                    </Typography>
                    <Select
                      fullWidth
                      value={newSlot.doctorId || ""}
                      onChange={(e) => handleDoctorChange(e.target.value)}
                    >
                      <MenuItem value="" disabled>
                        Select Doctor
                      </MenuItem>                     
                      {doctors.map((doctor) => (
                        <MenuItem key={doctor._id} value={doctor._id}>
                          {`${doctor.userId.firstName} ${doctor.userId.lastName}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Appointment Date: {newSlot.appointmentDate ? moment(newSlot.appointmentDate).format("MMMM D, YYYY") : 'N/A'}
                    </Typography>
                    <TextField
                      label="Appointment Date"
                      type="date"
                      fullWidth
                      value={newSlot.appointmentDate}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      style={{ marginBottom: "10px" }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Time Slot
                    </Typography>
                    <Select
                      value={newSlot.appointmentTime || ""}
                      onChange={(e) => setNewSlot({ ...newSlot, appointmentTime: e.target.value })}
                      fullWidth
                      displayEmpty
                      style={{ marginBottom: "10px" }}
                    >
                      <MenuItem value="" disabled>
                        Select Timeslot
                      </MenuItem>
                      {availableSlots.map((slot, index) => (
                        <MenuItem key={index} value={slot}>
                          {moment(slot, "HH:mm").format("hh:mm A")}
                        </MenuItem>
                      ))}
                      <MenuItem value="custom">Custom Time Slot</MenuItem>
                    </Select>
                  </Grid>

                  {newSlot.appointmentTime === 'custom' && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Custom Time
                      </Typography>
                      <TextField
                        type="time"
                        fullWidth
                        value={newSlot.customTime}
                        onChange={(e) => setNewSlot({ ...newSlot, customTime: e.target.value })}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDialogOpen(false)} color="secondary">
                  Cancel
                </Button>
                <Button onClick={handleAddSlot} color="primary">
                  Create Appointment
                </Button>
              </DialogActions>
            </Dialog>

            { }
            <Dialog open={bookingDialogOpen} onClose={() => setBookingDialogOpen(false)}>
              <DialogTitle>Book Appointment</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Patient
                    </Typography>
                    <Select
                      fullWidth
                      value={newSlot.patientId || ""}
                      onChange={(e) => handlePatientChange(e.target.value)}
                      displayEmpty
                      style={{ marginBottom: "10px" }}
                    >
                      <MenuItem value="" disabled>
                        Select Patient
                      </MenuItem>
                      {patients.map((patient) => (
                        <MenuItem key={patient._id} value={patient._id}>
                          {`${patient.firstName} ${patient.lastName}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Doctor
                    </Typography>
                    <Select
                      fullWidth
                      value={newSlot.doctorId || ""}
                      onChange={(e) => handleDoctorChange(e.target.value)}
                    >
                      <MenuItem value="" disabled>Select Doctor</MenuItem>
                      {doctors.map((doctor) => (
                        <MenuItem key={doctor._id} value={doctor._id}>
                          {`${doctor.userId.firstName} ${doctor.userId.lastName}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Appointment Date: {newSlot.appointmentDate ? moment(newSlot.appointmentDate).format("MMMM D, YYYY") : 'N/A'}
                    </Typography>
                    <TextField
                      label="Appointment Date"
                      type="date"
                      fullWidth
                      value={newSlot.appointmentDate}
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      style={{ marginBottom: "10px" }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                      Time Slot
                    </Typography>
                    <Select
                      value={newSlot.appointmentTime || ""}
                      onChange={(e) => setNewSlot({ ...newSlot, appointmentTime: e.target.value })}
                      fullWidth
                      displayEmpty
                      style={{ marginBottom: "10px" }}
                    >
                      <MenuItem value="" disabled>
                        Select Timeslot
                      </MenuItem>
                      {availableSlots.map((slot, index) => (
                        <MenuItem key={index} value={slot}>
                          {moment(slot, "HH:mm").format("hh:mm A")}
                        </MenuItem>
                      ))}
                      <MenuItem value="custom">Custom Time Slot</MenuItem>
                    </Select>
                  </Grid>

                  {newSlot.appointmentTime === 'custom' && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Custom Time
                      </Typography>
                      <TextField
                        type="time"
                        fullWidth
                        value={newSlot.customTime}
                        onChange={(e) => setNewSlot({ ...newSlot, customTime: e.target.value })}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setBookingDialogOpen(false)}>Cancel</Button>
                <Button color="primary" onClick={handleCreateBooking}>
                  Book
                </Button>
              </DialogActions>
            </Dialog>

            { }
            <Dialog open={eventDetailsDialogOpen} onClose={() => setEventDetailsDialogOpen(false)} maxWidth="sm" fullWidth>
              <DialogTitle>Event Details</DialogTitle>
              <DialogContent>
                {selectedEventDetails ? (
                  <>
                    <Typography variant="h6">{selectedEventDetails.title}</Typography>
                    <Typography>
                      {`Start: ${moment(selectedEventDetails.start).format("MMMM D, YYYY h:mm A")}`}
                    </Typography>
                    <Typography>
                      {`End: ${moment(selectedEventDetails.end).format("MMMM D, YYYY h:mm A")}`}
                    </Typography>
                    {selectedEventDetails.patientName && (
                      <Typography>{`Patient: ${selectedEventDetails.patientName}`}</Typography>
                    )}
                    {selectedEventDetails.status && (
                      <Typography>{`Status: ${selectedEventDetails.status}`}</Typography>
                    )}

                    {activeAction === "follow-up" && (
                      <Box mt={3} sx={{ backgroundColor: 'white' }}>
                        <Typography variant="h6" color="primary">Schedule Follow-Up</Typography>
                        <Divider sx={{ my: 2 }} />
                        <TextField
                          label="Follow-Up Date"
                          type="date"
                          fullWidth
                          value={followUpDate}
                          onChange={(e) => setFollowUpDate(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                        />
                        <Box mt={2} textAlign="right" sx={{ background: 'white' }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFollowUp}
                            disabled={loading}
                          >
                            {loading ? <CircularProgress size={24} /> : "Schedule"}
                          </Button>
                        </Box>
                      </Box>
                    )}

                    {activeAction === "reschedule" && (
                      <Box mt={3} sx={{ backgroundColor: 'white' }}>
                        <Typography variant="h6" color="secondary">Reschedule Appointment</Typography>
                        <Divider sx={{ my: 2 }} />
                        <TextField
                          label="New Date"
                          type="date"
                          fullWidth
                          value={rescheduleDate}
                          onChange={(e) => setRescheduleDate(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          label="New Time"
                          type="time"
                          fullWidth
                          value={rescheduleTime}
                          onChange={(e) => setRescheduleTime(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                        />
                        <Box mt={2} textAlign="right" sx={{ backgroundColor: 'white' }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleReschedule}
                            disabled={loading}
                          >
                            {loading ? <CircularProgress size={24} /> : "Reschedule"}
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </>
                ) : (
                  <Typography>No details available.</Typography>
                )}
              </DialogContent>
              <DialogActions sx={{ backgroundColor: 'white' }}>
                {(currentUser?.userType === "Doctor" || currentUser?.userType === "Admin") && (
                  <>
                    <Tooltip title="Schedule Follow-Up">
                      <IconButton
                        color="secondary"
                        onClick={() => setActiveAction("follow-up")}
                      >
                        <EventNoteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Reschedule Appointment">
                      <IconButton
                        color="warning"
                        onClick={() => setActiveAction("reschedule")}
                      >
                        <ScheduleIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                <Button onClick={() => setEventDetailsDialogOpen(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            { }
            {selectedAppointment && (
              <AppointmentModal
                isOpen={isAppointmentModalOpen}
                onClose={() => setIsAppointmentModalOpen(false)}
                appointment={selectedAppointment}
                onFollowUp={() => {
                  ToastService.info('Follow-up feature not implemented yet.');
                }}
                onReschedule={() => {
                  ToastService.info('Reschedule feature not implemented yet.');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default AdminAppointment;
