import React, { useMemo, useContext, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDeleteDialogue from '../MUIDialogueBox/ConfirmDeleteDialogue';
import { UserContext } from '../../Context/UserContext';
import { useNavigate } from 'react-router-dom';
import ResponsiveTable from './ResponsiveTable'; // Import ResponsiveTable

const columns = [
    { id: 'Name', label: 'Name', minWidth: 170 },
    { id: 'Email', label: 'Email', minWidth: 170 },
    { id: 'Phone', label: 'Phone', minWidth: 170 },
    { id: 'Gender', label: 'Gender', minWidth: 170 },
    { id: 'Address', label: 'Address', minWidth: 170 },
    { id: 'DOB', label: 'DOB', minWidth: 170 },
    { id: 'actionsID', label: 'Actions', minWidth: 100 },
];

export default function PatientTable({ patientList, deletePatient }) {
    const [page, setPage] = useState(0);
    const { currentUser } = useContext(UserContext);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [patientToDelete, setPatientToDelete] = useState(null);
    const navigate = useNavigate();

    const handleDeleteClick = (id) => {
        setPatientToDelete(id);
    };

    const handleConfirmDelete = () => {
        if (patientToDelete) {
            deletePatient(patientToDelete);
            setPatientToDelete(null);
        }
    };

    const handleCancelDelete = () => {
        setPatientToDelete(null);
    };

    const filteredPatients = useMemo(() => {
        if (!currentUser) {
            return patientList;
        }

        if (currentUser?.userType === 'Patient' && Array.isArray(currentUser.patientIds)) {
            return patientList.filter((patient) =>
                patient._id && currentUser.patientIds.some((id) => String(id) === String(patient._id))
            );
        }
        return patientList;
    }, [patientList, currentUser]);

    const rows = filteredPatients.map((patient) => ({
        Name: `${patient.userId.firstName} ${patient.userId.lastName}`,
        Email: patient.userId.email,
        Phone: patient.phone,
        Gender: patient.gender,
        Address: patient.address,
        DOB: patient.dob,
        actionsID: patient._id,
    }));

    const renderActions = (actionsID) => (
        <>
            <Tooltip title="Edit" placement="top" arrow>
                <EditIcon
                    className="mx-2"
                    style={{ color: '#ff6600', fontSize: 30 }}
                    onClick={() => navigate(`/account/patients/edit/${actionsID}`)}
                />
            </Tooltip>
            <Tooltip title="Delete" placement="top" arrow>
                <DeleteIcon
                    className="mx-2"
                    style={{ color: 'red', fontSize: 30 }}
                    onClick={() => handleDeleteClick(actionsID)}
                />
            </Tooltip>
        </>
    );

    return (
        <>
            <ResponsiveTable
                columns={columns}
                rows={rows}
                renderActions={renderActions}
            />
            <ConfirmDeleteDialogue
                title="Confirm Delete"
                message="Are you sure you want to delete this patient? This action cannot be undone."
                open={Boolean(patientToDelete)}
                handleClose={handleCancelDelete}
                handleDelete={handleConfirmDelete}
            />
        </>
    );
}
