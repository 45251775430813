import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { TextField, Button, Box, CircularProgress, Alert } from "@mui/material";
import ToastService from "../../Service/toast.service";

const ResetPasswordPage = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [validToken, setValidToken] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        verifyToken();
    }, [token]);

    const verifyToken = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";
        try {
            const response = await fetch(`${baseApi}/password-reset/verify/${token}`);
            const data = await response.json();
            setValidToken(data.valid);
        } catch (error) {
            console.error("Error verifying token:", error);
            setValidToken(false);
        } finally {
            setLoading(false);
        }
    };

    const handlePasswordReset = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";
    
        if (!password || !confirmPassword) {
            ToastService.error("Please fill in all fields.");
            return;
        }
    
        if (password !== confirmPassword) {
            ToastService.error("Passwords do not match.");
            return;
        }

        if (password.length < 8) {
            ToastService.error("Password must be at least 8 characters long.");
            return;
        }
    
        try {
            setSubmitting(true);
            const response = await fetch(`${baseApi}/password-reset/reset/${token}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ password }),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to reset password');
            }

            ToastService.success("Password reset successful!");
            navigate("/login");
        } catch (error) {
            console.error("Error resetting password:", error);
            ToastService.error(error.message || "An error occurred. Please try again.");
        } finally {
            setSubmitting(false);
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!validToken) {
        return (
            <Box sx={{ maxWidth: 400, mx: "auto", mt: 8, p: 3 }}>
                <Alert severity="error">
                    Invalid or expired reset token. Please request a new password reset.
                </Alert>
            </Box>
        );
    }

    return (
        <Box sx={{ maxWidth: 400, mx: "auto", mt: 8, p: 3, boxShadow: 3, borderRadius: 2 }}>
            <h2>Reset Password</h2>
            <TextField
                fullWidth
                margin="normal"
                type="password"
                label="New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={submitting}
            />
            <TextField
                fullWidth
                margin="normal"
                type="password"
                label="Confirm New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                disabled={submitting}
            />
            <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handlePasswordReset}
                sx={{ mt: 2 }}
                disabled={submitting}
            >
                {submitting ? "Resetting..." : "Reset Password"}
            </Button>
        </Box>
    );
};

export default ResetPasswordPage;
