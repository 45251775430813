import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import ConfirmDeleteDialogue from "../MUIDialogueBox/ConfirmDeleteDialogue";
import { useNavigate } from "react-router-dom";
import TableLoader from "../Loader/TableLoader";

const columns = [
  { id: "legalName", label: "Legal Name", minWidth: 170 },
  { id: "registeredName", label: "Registered Name", minWidth: 170 },
  { id: "email", label: "Email", minWidth: 170 },
  { id: "phone", label: "Phone", minWidth: 170 },
  { id: "corporateReg", label: "Registration Number", minWidth: 170 },
  { id: "corporateVat", label: "VAT Number", minWidth: 170 },
  { id: "corporateType", label: "Type", minWidth: 100 },
  { id: "actionsID", label: "Actions", minWidth: 100 },
];

function createData(
  legalName,
  registeredName,
  email,
  phone,
  corporateReg,
  corporateVat,
  corporateType,
  actionsID
) {
  return { legalName, registeredName, email, phone, corporateReg, corporateVat, corporateType, actionsID };
}

export default function UserTable({ corporateList, deleteCorporate, loading }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openConfirmDeleteDialogue, setOpenConfirmDeleteDialogue] = React.useState(false);

  const navigate = useNavigate();

  const handleDeleteDialogueOpen = () => {
    setOpenConfirmDeleteDialogue(true);
  };

  const handleDeleteDialogueClose = () => {
    setOpenConfirmDeleteDialogue(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let rows = corporateList.map((corporate) => {
    return createData(
      corporate.legalName,
      corporate.registeredName,
      corporate.email,
      corporate.phone,
      corporate.corporateReg,
      corporate.corporateVat,
      corporate.corporateType,
      corporate._id
    );
  });

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", marginTop: 2, boxShadow: "0 12px 24px rgba(0, 0, 0, 0.2)" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading ? (
            <>
            <TableLoader message="Loading corporate data..." />
            </>
          ) : (
            <>
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.actionsID}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      if (column.id === "actionsID") {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Tooltip title="Edit" placement="top" arrow>
                              <EditIcon
                                className="mx-2"
                                style={{ color: "#ff6600", fontSize: 30 }}
                                onClick={() => {
                                  navigate(`/account/corporates/edit/${value}`);
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Delete" placement="top" arrow>
                              <DeleteIcon
                                className="mx-2"
                                style={{ color: "red", fontSize: 30 }}
                                onClick={handleDeleteDialogueOpen}
                              />
                            </Tooltip>
                            <ConfirmDeleteDialogue
                              title="Confirm Delete"
                              message="Are you sure you want to delete this record? This action cannot be undone."
                              open={openConfirmDeleteDialogue}
                              handleClose={handleDeleteDialogueClose}
                              handleDelete={() => {
                                deleteCorporate(value);
                                handleDeleteDialogueClose();
                              }}
                            />
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value || "N/A"}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
            </>
            )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          "& p": {
            marginTop: "auto",
            marginBottom: "auto",
          },
        }}
      />
    </Paper>
  );
}
