
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from '../../Context/UserContext';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ToastService from '../../Service/toast.service';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';

import axios from 'axios';
import moment from 'moment';
import { PlayArrow } from '@mui/icons-material';

const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

function createData(patientName, doctorName, bookingDate, bookingTime, status, approval, actionsID) {
  return { patientName, doctorName, bookingDate, bookingTime, status, approval, actionsID };
}

export default function BookingTable({ bookingList, refreshBookings, deleteBookings, approveBooking, openAppointmentDialog }) {
  console.log('Received bookingList in BookingTable:', bookingList);
  const { currentUser } = useContext(UserContext);

  const columns = [
    { id: 'patientName', label: 'Patient Name', minWidth: 170 },
    { id: 'doctorName', label: 'Doctor Name', minWidth: 170 },
    { id: 'bookingDate', label: 'Booking Date', minWidth: 170 },
    { id: 'bookingTime', label: 'Booking Time', minWidth: 170 },
    { id: 'status', label: 'Status', minWidth: 100 },
    { id: 'approval', label: 'Approval', minWidth: 100 },
    { id: 'actions', label: 'Actions', minWidth: 250 },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [rescheduleDialogOpen, setRescheduleDialogOpen] = useState(false);
  const [rescheduleData, setRescheduleData] = useState({
    bookingId: '',
    newDate: '',
    newTime: '',
  });
  const [rescheduleLoading, setRescheduleLoading] = useState(false);

  const [declineDialogOpen, setDeclineDialogOpen] = useState(false);
  const [declineBookingId, setDeclineBookingId] = useState('');
  const [declineLoading, setDeclineLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const formatDate = (date) => moment(date).format('MMMM D, YYYY');
  const formatTime = (time) => moment(time, "HH:mm").format("hh:mm A");

  const handleApprove = async (bookingId) => {
    try {
      await approveBooking(bookingId);
      ToastService.success("Booking approved successfully.");
      refreshBookings();
    } catch (error) {
      console.error("Error approving booking:", error);
      ToastService.error(error.response?.data?.message || "Failed to approve booking.");
    }
  };

  const openDeclineDialog = (bookingId) => {
    setDeclineBookingId(bookingId);
    setDeclineDialogOpen(true);
  };

  const closeDeclineDialog = () => {
    setDeclineDialogOpen(false);
    setDeclineBookingId('');
  };

  const handleDecline = async () => {
    setDeclineLoading(true);
    try {
      await deleteBookings(declineBookingId);
      ToastService.success("Booking declined and deleted successfully.");
      setDeclineDialogOpen(false);
      setDeclineBookingId('');
      refreshBookings();
    } catch (error) {
      console.error("Error declining booking:", error);
      ToastService.error(error.response?.data?.message || "Failed to decline booking.");
    } finally {
      setDeclineLoading(false);
    }
  };

  const openRescheduleDialog = (bookingId) => {
    setRescheduleData({ bookingId, newDate: '', newTime: '' });
    setRescheduleDialogOpen(true);
  };

  const closeRescheduleDialog = () => {
    setRescheduleDialogOpen(false);
    setRescheduleData({ bookingId: '', newDate: '', newTime: '' });
  };

  const handleReschedule = async () => {
    const { bookingId, newDate, newTime } = rescheduleData;

    if (!newDate || !newTime) {
      ToastService.error("Please select both new date and time.");
      return;
    }

    setRescheduleLoading(true);
    try {
      await axios.put(`${baseApi}/api/bookings/${bookingId}/reschedule`, {
        newDate: moment(newDate).format('YYYY-MM-DD'),
        newTime: moment(newTime, "hh:mm A").format("HH:mm"),
      }, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      ToastService.success("Booking rescheduled successfully.");
      setRescheduleDialogOpen(false);
      setRescheduleData({ bookingId: '', newDate: '', newTime: '' });
      refreshBookings();
    } catch (error) {
      console.error("Error rescheduling booking:", error);
      ToastService.error(error.response?.data?.message || "Failed to reschedule booking.");
    } finally {
      setRescheduleLoading(false);
    }
  };

  const rows = (bookingList || [])
  .filter((booking) => {
    if (!currentUser) return false;

    if (currentUser.userType === 'Doctor') {
      return booking.doctorId === currentUser.id;
    }

    if (currentUser.userType === 'Patient') {
      return booking.patientId === currentUser.id;
    }
    return true;
  })
  .map((booking) => {
    const row = createData(
      booking.patientName || 'N/A',
      booking.doctorName || 'N/A',
      formatDate(booking.bookingDate),
      formatTime(booking.bookingTime),
      booking.status === 'booked' ? 'Booked' : 'Available',
      booking.isApproved ? 'Approved' : 'Pending',
      booking._id
    );
    console.log('Row created:', row);
    return row;
  });


  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: 2 }}>
      <TableContainer>
        <Table stickyHeader aria-label="Bookings Table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.actionsID}>
                {columns.map((column) => {
                  const value = row[column.id];
                  console.log(`Rendering column ${column.id}:`, value);
                  if (column.id === 'actions') {
                    return (
                      <TableCell key={column.id}>
                        {}
                        {row.status === 'Available' && row.approval === 'Pending' && (
                          <Tooltip title="Approve Booking">
                            <IconButton
                              color="primary"
                              onClick={() => handleApprove(row.actionsID)}
                            >
                              <CheckIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                        {}
                        <Tooltip title="Decline Booking">
                          <IconButton
                            color="error"
                            onClick={() => openDeclineDialog(row.actionsID)}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>

                        {}
                        {row.status === 'Booked' && (
                          <Tooltip title="Reschedule Booking">
                            <IconButton
                              color="secondary"
                              onClick={() => openRescheduleDialog(row.actionsID)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {row.status === 'Booked' && (
                          <Tooltip title="Start appointment">
                            <IconButton
                              color="secondary"
                              onClick={() => openAppointmentDialog(row.actionsID)}
                            >
                              <PlayArrow />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell key={column.id}>
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
      {}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {}
      <Dialog
        open={declineDialogOpen}
        onClose={closeDeclineDialog}
        aria-labelledby="decline-dialog-title"
      >
        <DialogTitle id="decline-dialog-title">Decline Booking</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to decline and delete this booking?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeclineDialog} color="primary" disabled={declineLoading}>
            Cancel
          </Button>
          <Button onClick={handleDecline} color="error" disabled={declineLoading}>
            {declineLoading ? <CircularProgress size={24} /> : 'Decline'}
          </Button>
        </DialogActions>
      </Dialog>

      {}
      <Dialog
        open={rescheduleDialogOpen}
        onClose={closeRescheduleDialog}
        aria-labelledby="reschedule-dialog-title"
      >
        <DialogTitle id="reschedule-dialog-title">Reschedule Booking</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                label="New Date"
                type="date"
                fullWidth
                value={rescheduleData.newDate}
                onChange={(e) => setRescheduleData({ ...rescheduleData, newDate: e.target.value })}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="New Time"
                type="time"
                fullWidth
                value={rescheduleData.newTime}
                onChange={(e) => setRescheduleData({ ...rescheduleData, newTime: e.target.value })}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  step: 300,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeRescheduleDialog} color="primary" disabled={rescheduleLoading}>
            Cancel
          </Button>
          <Button onClick={handleReschedule} color="secondary" disabled={rescheduleLoading}>
            {rescheduleLoading ? <CircularProgress size={24} /> : 'Reschedule'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

BookingTable.propTypes = {
  bookingList: PropTypes.array.isRequired,
  refreshBookings: PropTypes.func.isRequired,
  deleteBookings: PropTypes.func.isRequired,
  approveBooking: PropTypes.func.isRequired,
};
