import React, { useState, useEffect, useContext } from "react";
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography,
    CircularProgress,
    TextField,
    Button,
    Toolbar,
    Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import SendIcon from "@mui/icons-material/Send";
import ListItemIcon from "@mui/material/ListItemIcon";
import { io } from "socket.io-client";
import { UserContext } from "../../Context/UserContext";

const socket = io(process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za");

function Chat() {
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedChat, setSelectedChat] = useState(null);
    const [oldChats, setOldChats] = useState([]);
    const [filteredChats, setFilteredChats] = useState([]);
    const [loading, setLoading] = useState(false);
    const { currentUser } = useContext(UserContext);

    const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za"

    const fetchOldChats = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${baseApi}/chat/messages`);
            const data = await response.json();
            setOldChats(Array.isArray(data) ? data : []);
            setFilteredChats(Array.isArray(data) ? data : []);
        } catch (error) {
            console.error("Error fetching old chats:", error);
            setOldChats([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOldChats();

        socket.on("chat message", (newMessage) => {
            setMessages((prevMessages) => [...prevMessages, newMessage]);
        });

        socket.on("chat history", (history) => {
            setMessages(history);
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        if (Array.isArray(oldChats)) {
            const filtered = oldChats.filter((chat) =>
                chat.message?.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredChats(filtered);
        } else {
            setFilteredChats([]);
        }
    }, [searchQuery, oldChats]);

    const fetchMessages = async (chatId) => {
        setLoading(true);
        try {
            const response = await fetch(`${baseApi}/chat/messages/${chatId}`);
            const data = await response.json();
            setMessages(data);
            setSelectedChat(chatId);
        } catch (error) {
            console.error("Error fetching selected chat:", error);
        } finally {
            setLoading(false);
        }
    };

    const sendMessage = async () => {
        if (!message.trim()) return;

        const newMessage = {
            userId: currentUser?.id || "Anonymous",
            message,
        };

        socket.emit("chat message", newMessage);

        try {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/chat/messages`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(newMessage),
            });
        } catch (error) {
            console.error("Error sending message:", error);
        }

        setMessage("");
    };

    return (
        <Box sx={{ display: "flex", height: "100%", width: "100%", overflow: "hidden" }}>
            {}
            <Box
                sx={{
                    width: 350,
                    backgroundColor: "#f5f5f5",
                    borderRight: "1px solid #ddd",
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                }}
            >
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        Past Chats
                    </Typography>
                </Toolbar>
                <Divider />
                <Box sx={{ p: 2 }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Search chats"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        InputProps={{
                            startAdornment: <SearchIcon sx={{ mr: 1 }} />,
                        }}
                    />
                </Box>
                <Divider />
                <List>
                    {loading ? (
                        <Box sx={{ textAlign: "center", p: 3 }}>
                            <CircularProgress />
                        </Box>
                    ) : filteredChats.length > 0 ? (
                        filteredChats.map((chat) => (
                            <ListItem
                                key={chat._id}
                                disablePadding
                                onClick={() => fetchMessages(chat._id)}
                                selected={selectedChat === chat._id}
                            >
                                <ListItemButton>
                                    <ListItemIcon>
                                        <ChatBubbleOutlineIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={chat.message || `Chat ${chat._id}`} />
                                </ListItemButton>
                            </ListItem>
                        ))
                    ) : (
                        <Typography
                            variant="body1"
                            sx={{ textAlign: "center", color: "#aaa", marginTop: 2 }}
                        >
                            No past chats
                        </Typography>
                    )}
                </List>
            </Box>

            {}
            <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", overflowY: "auto", p: 3 }}>
                <Paper
                    elevation={3}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        borderRadius: "8px",
                        padding: "16px",
                    }}
                >
                    <Box
                        sx={{
                            flexGrow: 1,
                            overflowY: "auto",
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                            padding: "16px",
                            marginBottom: "16px",
                            backgroundColor: "#f9f9f9",
                        }}
                    >
                        {messages.length > 0 ? (
                            messages.map((msg, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        marginBottom: "10px",
                                        padding: "8px",
                                        borderRadius: "8px",
                                        backgroundColor:
                                            msg.userId === currentUser?.id ? "#d1e7dd" : "#f1f1f1",
                                    }}
                                >
                                    <strong>
                                        {msg.userId === currentUser?.id ? "You" : msg.userId}:
                                    </strong>{" "}
                                    {msg.message}
                                </Box>
                            ))
                        ) : (
                            <Typography variant="body1" sx={{ textAlign: "center", color: "#aaa" }}>
                                No messages yet. Start a conversation!
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ display: "flex", gap: 2, backgroundColor: 'white' }}>
                        <TextField
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Type your message"
                            fullWidth
                            variant="outlined"
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "8px",
                                },
                            }}
                        />
                        <button
                            onClick={sendMessage}
                            className="btn btn-primary"
                            sx={{
                                width: 150,
                                borderRadius: "8px",
                                backgroundColor: "#1976d2",
                            }}
                        >
                            Send <SendIcon />
                        </button>
                    </Box>
                </Paper>
            </Box>
        </Box>
    );
}

export default Chat;
