import React, { useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Menu,
    TextField,
    Button,
    Box,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

const FrontendHeaderNav = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [loginInfo, setLoginInfo] = useState({ username: "", password: "" });

    const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLoginInfo((prev) => ({ ...prev, [name]: value }));
    };

    const handleLogin = () => {
        console.log("Login attempt:", loginInfo);
        setAnchorEl(null);
    };

    return (
        <AppBar
            position="sticky"
            sx={{
                backgroundColor: "#fff",
                color: "#000",
                marginTop: 0,
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                zIndex: 1201,
            }}
        >
            <Toolbar>
                {}
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    <img
                        src="https://docotela.co.za/wp-content/uploads/2023/02/Docotela-new-logo-600x171.webp"
                        alt="Logo"
                        style={{ height: "40px", cursor: "pointer" }}
                    />
                </Typography>

                {}
                <Box sx={{
                    background: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <IconButton
                        color="inherit"
                        aria-controls="login-menu"
                        aria-haspopup="true"
                        onClick={handleMenuOpen}
                        style={{ paddingBottom: '2rem'}}
                    >
                        <PersonIcon/>
                    </IconButton>
                    <Menu
                        id="login-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                        <Box sx={{ padding: 2, maxWidth: '400px' }}>
                            <h5>Login to access your account</h5>
                            <TextField
                                label="Username"
                                name="username"
                                fullWidth
                                value={loginInfo.username}
                                onChange={handleInputChange}
                                margin="dense"
                            />
                            <TextField
                                label="Password"
                                name="password"
                                type="password"
                                fullWidth
                                value={loginInfo.password}
                                onChange={handleInputChange}
                                margin="dense"
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleLogin}
                                fullWidth
                                sx={{ marginTop: 1 }}
                            >
                                Login
                            </Button>
                            Or <a href="/sign-up" className="text-primary">Sign up</a> for an account.
                        </Box>
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default FrontendHeaderNav;
