import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import Box from '@mui/material/Box';
import OrderTable from '../MUITable/OrderTable';
import { UserContext } from '../../Context/UserContext';

function OrderList() {
    const { currentUser } = useContext(UserContext);
    const [orders, setOrders] = useState([]);
    const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);

    const handleDialogueOpen = () => setErrorDialogueBoxOpen(true);
    const handleDialogueClose = () => {
        setErrorList([]);
        setErrorDialogueBoxOpen(false);
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    const fetchOrders = async (filterName = '') => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    
        const isAdminOrDoctor =
            currentUser?.userType === 'Admin' || currentUser?.userType === 'Doctor';
    
        try {
            let endpoint = '/orders';
    
            let params = {};
    
            if (isAdminOrDoctor) {
                if (filterName) {
                    params = { name: filterName };
                } else {
                    endpoint = '/orders/all';
            
                }
            } else if (currentUser?.userType === 'Patient') {
                params = {
                    userId: currentUser.id,
                    ...(filterName && { name: filterName }),
                };
            }
    
            console.log(`Fetching from ${baseApi}${endpoint} with params:`, params);
    
            const response = await axios.get(`${baseApi}${endpoint}`, { params });
            setOrders(response.data);
        } catch (error) {
            console.error('Error fetching orders:', error.response?.data || error.message);
            setErrorList(['Failed to fetch orders. Please try again later.']);
            handleDialogueOpen();
        }
    };    

    const handleSearch = (e) => {
        e.preventDefault();
        const searchName = new FormData(e.target).get('name') || '';
        fetchOrders(searchName);
    };

    const deleteOrder = async (id) => {
        const confirmation = window.confirm('Are you sure you want to delete this order?');
        if (!confirmation) return;

        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        try {
            await axios.delete(`${baseApi}/orders/${id}`);
            fetchOrders();
        } catch (error) {
            console.error('Error deleting order:', error);
            setErrorList(['Failed to delete order. Please try again later.']);
            handleDialogueOpen();
        }
    };

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-sm-4 col-3">
                            <h4 className="page-title fw-bold">Orders</h4>
                        </div>
                    </div>

                    {}
                    <form onSubmit={handleSearch} name="userFilter">
                        <div className="row filter-row">
                            <div className="col-sm-4 col-md-4">
                                <div className="form-floating">
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        placeholder="Order ID or Name"
                                    />
                                    <label className="focus-label">Order ID or Name</label>
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-4">
                                <button type="submit" className="btn btn-primary btn-block">
                                    Search
                                </button>
                            </div>
                        </div>
                    </form>

                    {}
                    <OrderTable orderList={orders} deleteOrder={deleteOrder} />

                    {}
                    <ErrorDialogueBox
                        open={errorDialogueBoxOpen}
                        handleToClose={handleDialogueClose}
                        ErrorTitle="Error: Orders"
                        ErrorList={errorList}
                    />
                </div>
            </div>
        </Box>
    );
}

export default OrderList;
