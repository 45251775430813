import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import Box from '@mui/material/Box';
import ProductTable from '../MUITable/ProductTable';
import ToastService from '../../Service/toast.service';

function ProductList() {
    const params = new URLSearchParams(window.location.search);
    const name = params.get('name');

    const [products, setproduct] = useState([]);

    const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const handleDialogueOpen = () => {
        setErrorDialogueBoxOpen(true)
    };
    const handleDialogueClose = () => {
        setErrorList([]);
        setErrorDialogueBoxOpen(false)
    };


    useEffect(() => {
        getproducts();
    }, []
    );

    const getproducts = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        const response = await axios.get(`${baseApi}/products`, {
            params: {
                name: name
            }
        });
        setproduct(response.data);
    };

    const deleteProduct = async (id) => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        try {
            await axios.delete(`${baseApi}/products/${id}`);
            ToastService.success("Successfully deleted product.")
            getproducts();
        } catch (error) {
            setErrorList(error);
            handleDialogueOpen();
        }


    };


    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>

            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-sm-3 col-3">
                            <h4 className="page-title">Product</h4>
                        </div>
                        <div className="col-sm-9 col-9 text-right m-b-20 d-flex justify-content-end">
                            <Link to="/account/products/add" className="btn btn-primary float-right btn-rounded">
                                <i className="fa fa-plus"></i> Add Product
                            </Link>
                        </div>
                    </div>
                    <form action="/account/products" name="userFilter" >
                        <div className="row filter-row">

                            <div className="col-sm-4 col-md-4">
                                <div className="form-floating ">

                                    <input type="text" name="name" className="form-control" placeholder='Product Name' />
                                    <label className="focus-label">Product Name</label>
                                </div>
                            </div>

                            <div className="col-sm-4 col-md-4">
                                <button type="submit" className="btn btn-primary btn-block"> Search </button>
                            </div>
                        </div>
                    </form>
                    <ProductTable productList={products} deleteProduct={deleteProduct} />
                    {
}
                </div>
                <ErrorDialogueBox
                    open={errorDialogueBoxOpen}
                    handleToClose={handleDialogueClose}
                    ErrorTitle="Error: Add product"
                    ErrorList={errorList}
                />
            </div>

        </Box>
    )
}

export default ProductList;
