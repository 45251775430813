import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDeleteDialogue from '../MUIDialogueBox/ConfirmDeleteDialogue';
import { useNavigate } from 'react-router-dom';
import ResponsiveTable from './ResponsiveTable'; // Import ResponsiveTable

const columns = [
    { id: 'Name', label: 'Name', minWidth: 170 },
    { id: 'Email', label: 'Email', minWidth: 170 },
    { id: 'Phone', label: 'Phone', minWidth: 170 },
    { id: 'Department', label: 'Department', minWidth: 170 },
    { id: 'Address', label: 'Address', minWidth: 170 },
    { id: 'AvailableSlots', label: 'Available Slots', minWidth: 200 },
    { id: 'actionsID', label: 'Actions', minWidth: 100 },
];

function DoctorTable({ doctorList, deleteDoctor, fetchAppointments }) {
    const [doctorToDelete, setDoctorToDelete] = useState(null);
    const [availableSlots, setAvailableSlots] = useState({});
    const navigate = useNavigate();

    const handleDeleteClick = (id) => {
        setDoctorToDelete(id);
    };

    const handleConfirmDelete = () => {
        if (doctorToDelete) {
            deleteDoctor(doctorToDelete);
            setDoctorToDelete(null);
        }
    };

    const handleCancelDelete = () => {
        setDoctorToDelete(null);
    };

    const formatAvailableSlots = (slots) => {
        if (slots && Array.isArray(slots) && slots.length > 0) {
            const firstSlot = slots[0];
            const othersCount = slots.length - 1;
            return `${firstSlot} ${othersCount > 0 ? `+${othersCount}` : ''}`;
        }
        return 'No available slots';
    };

    const rows = doctorList.map((doctor) => {
        const user = doctor.userId || {};
        const doctorAvailableSlots = availableSlots[doctor._id] || [];

        return {
            Name: `${user.firstName || 'N/A'} ${user.lastName || 'N/A'}`,
            Email: user.email || 'N/A',
            Phone: doctor.phone || 'N/A',
            Department: doctor.department || 'N/A',
            Address: doctor.address || 'N/A',
            AvailableSlots: formatAvailableSlots(doctorAvailableSlots),
            actionsID: doctor._id,
        };
    });

    useEffect(() => {
        doctorList.forEach((doctor) => {
            fetchAppointments(doctor._id)
                .then((slots) => {
                    setAvailableSlots((prev) => ({
                        ...prev,
                        [doctor._id]: slots,
                    }));
                })
                .catch((error) => console.error('Error fetching slots:', error));
        });
    }, [doctorList, fetchAppointments]);

    const renderActions = (actionsID) => (
        <>
            <Tooltip title="Edit" placement="top" arrow>
                <IconButton
                    aria-label={`edit doctor ${actionsID}`}
                    onClick={() => navigate(`/account/doctors/edit/${actionsID}`)}
                >
                    <EditIcon style={{ color: '#ff6600', fontSize: 24 }} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete" placement="top" arrow>
                <IconButton
                    aria-label={`delete doctor ${actionsID}`}
                    onClick={() => handleDeleteClick(actionsID)}
                >
                    <DeleteIcon style={{ color: 'red', fontSize: 24 }} />
                </IconButton>
            </Tooltip>
        </>
    );

    return (
        <>
            <ResponsiveTable
                columns={columns}
                rows={rows}
                renderActions={renderActions}
            />
            <ConfirmDeleteDialogue
                title="Confirm Delete"
                message="Are you sure you want to delete this doctor? This action cannot be undone."
                open={Boolean(doctorToDelete)}
                handleClose={handleCancelDelete}
                handleDelete={handleConfirmDelete}
            />
        </>
    );
}

DoctorTable.propTypes = {
    doctorList: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string.isRequired,
            userId: PropTypes.shape({
                firstName: PropTypes.string,
                lastName: PropTypes.string,
                email: PropTypes.string,
            }),
            phone: PropTypes.string,
            department: PropTypes.string,
            address: PropTypes.string,
            availableSlots: PropTypes.array,
        })
    ).isRequired,
    deleteDoctor: PropTypes.func.isRequired,
    fetchAppointments: PropTypes.func.isRequired,
};

export default DoctorTable;
