import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Box from "@mui/material/Box";
import ToastService from "../../Service/toast.service";
import ErrorDialogueBox from "../MUIDialogueBox/ErrorDialogueBox";

function EditProduct() {
  const navigate = useNavigate();
  const { id } = useParams();

  /** Product use states */
  const [name, setName] = useState("");
  const [subscription, setSubscription] = useState(false);
  const [subscriptionInt, setSubscriptionInt] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [company, setCompany] = useState("");
  const [isStock, setIsStock] = useState(false);
  const [productImage, setProductImage] = useState("");
  const [categories, setCategories] = useState([]);
  const [categoryInput, setCategoryInput] = useState("");
  const [inclusions, setInclusions] = useState([]);
  const [accessUrl, setAccessUrl] = useState("");
  const [inclusionInput, setInclusionInput] = useState("");
  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);

  /**
   * Handle error dialog open
   * @since 0.1
   * @returns 
   */
  const handleDialogueOpen = () => setErrorDialogueBoxOpen(true);
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  /**
   * useEffect to get product by Id
   * @since 0.1
   */
  useEffect(() => {
    getProductById();
  }, []);

  /**
   * Get product by Id
   * @since 0.1
   */
  const getProductById = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";

    try {
      const response = await axios.get(`${baseApi}/products/${id}`);
      const product = response.data;

      setName(product.name || "");
      setSubscription(product.subscription || false);
      setSubscriptionInt(product.subscriptionInt || "");
      setDescription(product.description || "");
      setPrice(product.price || "");
      setDiscountedPrice(product.discountedPrice || "");
      setCompany(product.company || "");
      setIsStock(product.isStock || false);
      setProductImage(product.productImage || "");
      setCategories(product.categories || []);
      setInclusions(product.inclusions || []);
      setAccessUrl(product.accessUrl || "");
    } catch (error) {
      console.error("Error fetching product details:", error);
      setErrorList(["Failed to fetch product details."]);
      handleDialogueOpen();
    }
  };

  /**
   * Handle product image upload
   * @param {*} e 
   * TODO: Change storage
   */
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProductImage(URL.createObjectURL(file));
    }
  };

  /**
   * Handle add category
   * @since 0.1
   * TODO: Retrieve previously added categories
   */
  const handleCategoryAdd = () => {
    if (categoryInput.trim() !== "") {
      setCategories([...categories, categoryInput.trim()]);
      setCategoryInput("");
    }
  };

  /**
   * Handle remove category
   * @param {*} index 
   * TODO: Change to remove only instance and keep category for further use
   */
  const handleCategoryRemove = (index) => {
    setCategories(categories.filter((_, i) => i !== index));
  };

  /**
   * Handle adding inclusions
   * @since 0.1
   */
  const handleInclusionAdd = () => {
    if (inclusionInput.trim() !== "") {
      setInclusions([...inclusions, inclusionInput.trim()]);
      setInclusionInput("");
    }
  };

  /**
   * Handle removing inclusion
   * @since 0.1
   * @param {*} index 
   */
  const handleInclusionRemove = (index) => {
    setInclusions(inclusions.filter((_, i) => i !== index));
  };

  /**
   * Handle updating product info
   * @since 0.1
   * @param {*} e 
   */
  const updateProduct = async (e) => {
    e.preventDefault();
    const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";

    const updatedProduct = {
      name,
      subscription,
      subscriptionInt,
      description,
      price,
      discountedPrice,
      company,
      isStock,
      productImage,
      categories,
      inclusions,
      accessUrl
    };

    try {
      await axios.patch(`${baseApi}/products/${id}`, updatedProduct);
      ToastService.success("Product updated successfully.");
      navigate("/account/products");
    } catch (error) {
      console.error("Error updating product:", error);
      setErrorList(error.response?.data?.errors || ["Failed to update product."]);
      handleDialogueOpen();
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            <h4 className="page-title">Edit Product</h4>
            {/* Product form start */}
            <form id="editProductForm" onSubmit={updateProduct}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Brand</label>
                    <input
                      className="form-control"
                      type="text"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Product Name</label>
                    <input
                      className="form-control"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Description</label>
                    <input
                      className="form-control"
                      type="text"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Price</label>
                    <input
                      className="form-control"
                      type="number"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Discounted Price</label>
                    <input
                      className="form-control"
                      type="number"
                      value={discountedPrice}
                      onChange={(e) => setDiscountedPrice(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Subscription</label>
                    <select
                      className="form-control"
                      value={subscription}
                      onChange={(e) => setSubscription(e.target.value === "true")}
                    >
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </select>
                  </div>
                </div>
                {subscription && (
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Subscription Interval</label>
                      <input
                        className="form-control"
                        type="text"
                        value={subscriptionInt}
                        onChange={(e) => setSubscriptionInt(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>In Stock</label>
                    <select
                      className="form-control"
                      value={isStock}
                      onChange={(e) => setIsStock(e.target.value === "true")}
                    >
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <label>Product Image</label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="form-control mb-2"
                  />
                  {productImage && (
                    <img
                      src={productImage}
                      alt="Product Preview"
                      style={{ width: "100px", height: "100px", objectFit: "cover" }}
                    />
                  )}
                </div>
                <div className="col-md-6">
                <div className="form-group">
                      <label>Access Url</label>
                      <input
                        className="form-control"
                        type="text"
                        value={accessUrl}
                        disabled
                      />
                    </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Categories</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        value={categoryInput}
                        onChange={(e) => setCategoryInput(e.target.value)}
                      />
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleCategoryAdd}
                      >
                        Add
                      </button>
                    </div>
                    <div className="mt-2">
                      {categories.map((category, index) => (
                        <span key={index} className="badge badge-primary mx-1">
                          {category}{" "}
                          <button
                            type="button"
                            className="btn-close"
                            onClick={() => handleCategoryRemove(index)}
                          />
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Inclusions</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        value={inclusionInput}
                        onChange={(e) => setInclusionInput(e.target.value)}
                      />
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleInclusionAdd}
                      >
                        Add
                      </button>
                    </div>
                    <ul className="list-group mt-2">
                      {inclusions.map((inclusion, index) => (
                        <li key={index} className="list-group-item d-flex justify-content-between">
                          {inclusion}
                          <button
                            type="button"
                            className="btn-close"
                            onClick={() => handleInclusionRemove(index)}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="m-t-20 text-left">
                <button type="submit" className="btn btn-primary submit-btn">
                  Update Product
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* Error dialog box */}
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Edit Product"
          ErrorList={errorList}
        />
      </div>
    </Box>
  );
}

export default EditProduct;
