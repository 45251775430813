import React, { useState, useEffect } from "react";
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Typography,
    IconButton,
    Paper,
    CircularProgress,
    Toolbar,
    Button,
    Snackbar,
    TextField,
} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import InboxIcon from "@mui/icons-material/Inbox";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import ToastService from "../../Service/toast.service";

function Mailbox() {
    const [selectedMail, setSelectedMail] = useState(null);
    const [isComposing, setIsComposing] = useState(false);
    const [composeFields, setComposeFields] = useState({
        to: "",
        cc: "",
        bcc: "",
        subject: "",
        body: "",
    });
    const [mails, setMails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isViewingSent, setIsViewingSent] = useState(false);
    const [isViewingTrash, setIsViewingTrash] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showPlainText, setShowPlainText] = useState(false);

    const authHeader = {
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
    };

    const fetchEmails = async (endpoint) => {
        setLoading(true);
        try {
            const response = await axios.get(endpoint, authHeader);
            setMails(response.data);
        } catch (error) {
            console.error(`Error fetching emails from ${endpoint}:`, error);
            ToastService.error("Failed to fetch emails.");
        } finally {
            setLoading(false);
        }
    };
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    const fetchInbox = () => fetchEmails(`${baseApi}/api/emails/fetch`);

    const toggleView = (viewSent, viewTrash) => {
        setIsViewingSent(viewSent);
        setIsViewingTrash(viewTrash);
        setSelectedMail(null);

        if (viewSent) {
            fetchEmails("/api/emails/sent");
        } else if (viewTrash) {
            fetchEmails("/api/emails/trash");
        } else {
            fetchInbox();
        }
    };

    const handleComposeChange = (field, value) => {
        setComposeFields((prevFields) => ({
            ...prevFields,
            [field]: value,
        }));
    };

    const sendMail = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        try {
            const response = await axios.post(
                `${baseApi}/api/emails/send`,
                composeFields,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('Mail sent response:', response.data);
            setIsComposing(false);
            ToastService.success('Mail sent successfully!');
        } catch (error) {
            console.error('Error sending mail:', error.response?.data || error.message);
            ToastService.error('Failed to send mail.');
        }
    };    
    
    useEffect(() => {
        fetchInbox();
    }, []);

    return (
        <Box sx={{ display: "flex", height: "100vh", width: "100%", overflow: "hidden" }}>
            {}
            <Box
                sx={{
                    width: 300,
                    backgroundColor: "#f5f5f5",
                    borderRight: "1px solid #ddd",
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                }}
            >
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        Mailbox
                    </Typography>
                </Toolbar>
                <Divider />
                <Button variant="contained" startIcon={<CreateIcon />} sx={{ m: 2 }} onClick={() => setIsComposing(true)}>
                    Compose
                </Button>
                <List>
                    <ListItem disablePadding>
                        <ListItemButton
                            selected={!isViewingSent && !isViewingTrash}
                            onClick={() => toggleView(false, false)}
                        >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Inbox" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton selected={isViewingSent} onClick={() => toggleView(true, false)}>
                            <ListItemIcon>
                                <SendIcon />
                            </ListItemIcon>
                            <ListItemText primary="Sent" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton selected={isViewingTrash} onClick={() => toggleView(false, true)}>
                            <ListItemIcon>
                                <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText primary="Trash" />
                        </ListItemButton>
                    </ListItem>
                </List>
                <Divider />
                {loading ? (
                    <Box sx={{ textAlign: "center", p: 3 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <List>
                        {mails.map((mail) => (
                            <ListItem key={mail._id} disablePadding onClick={() => setSelectedMail(mail)}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <MailOutlineIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={mail.subject} secondary={`From: ${mail.from}`} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                )}
            </Box>

            {}
            <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", overflowY: "auto", p: 3 }}>
                {loading ? (
                    <Box sx={{ textAlign: "center", p: 3 }}>
                        <CircularProgress />
                    </Box>
                ) : isComposing ? (
                    <Paper elevation={3} sx={{ p: 3 }}>
                        <IconButton onClick={() => setIsComposing(false)}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h5" gutterBottom>
                            Compose Mail
                        </Typography>
                        <TextField
                            label="To"
                            fullWidth
                            margin="normal"
                            value={composeFields.to}
                            onChange={(e) => handleComposeChange("to", e.target.value)}
                        />
                        <TextField
                            label="CC"
                            fullWidth
                            margin="normal"
                            value={composeFields.cc}
                            onChange={(e) => handleComposeChange("cc", e.target.value)}
                        />
                        <TextField
                            label="BCC"
                            fullWidth
                            margin="normal"
                            value={composeFields.bcc}
                            onChange={(e) => handleComposeChange("bcc", e.target.value)}
                        />
                        <TextField
                            label="Subject"
                            fullWidth
                            margin="normal"
                            value={composeFields.subject}
                            onChange={(e) => handleComposeChange("subject", e.target.value)}
                        />
                        <TextField
                            label="Body"
                            fullWidth
                            margin="normal"
                            multiline
                            rows={10}
                            value={composeFields.body}
                            onChange={(e) => handleComposeChange("body", e.target.value)}
                        />
                        <Button
                            variant="contained"
                            sx={{ mt: 2 }}
                            onClick={sendMail}
                        >
                            Send
                        </Button>
                    </Paper>
                ) : selectedMail ? (
                    <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography variant="h5">{selectedMail.subject}</Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            From: {selectedMail.from}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            To: {selectedMail.to}
                        </Typography>
                        {selectedMail.cc && (
                            <Typography variant="subtitle1" color="textSecondary">
                                CC: {selectedMail.cc}
                            </Typography>
                        )}
                        {selectedMail.bcc && (
                            <Typography variant="subtitle1" color="textSecondary">
                                BCC: {selectedMail.bcc}
                            </Typography>
                        )}
                        <Divider sx={{ my: 2 }} />
                        <Button
                            variant="contained"
                            sx={{ mb: 2 }}
                            onClick={() => setShowPlainText(!showPlainText)}
                        >
                            {showPlainText ? "Show HTML" : "Show Plain Text"}
                        </Button>
                        <div
                            style={{ whiteSpace: "pre-wrap" }}
                            dangerouslySetInnerHTML={
                                showPlainText
                                    ? { __html: `<pre>${selectedMail.plaintext}</pre>` }
                                    : { __html: selectedMail.html }
                            }
                        />
                    </Paper>
                ) : (
                    <Typography variant="body1">Select an email to preview it here.</Typography>
                )}
            </Box>

            <Snackbar
                open={!!errorMessage}
                autoHideDuration={6000}
                onClose={() => setErrorMessage("")}
                message={errorMessage}
            />
        </Box>
    );
}

export default Mailbox;
