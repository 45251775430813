import React from 'react';
import ResponsiveTable from './ResponsiveTable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import ConfirmDeleteDialogue from '../MUIDialogueBox/ConfirmDeleteDialogue';
import { useNavigate } from 'react-router-dom';

const columns = [
  { id: 'Name', label: 'Name', minWidth: 170 },
  { id: 'Email', label: 'Email', minWidth: 170 },
  { id: 'Phone', label: 'Phone', minWidth: 170 },
  { id: 'Department', label: 'Department', minWidth: 170 },
  { id: 'Corporate', label: 'Corporate', minWidth: 170 },
  { id: 'Team', label: 'Team', minWidth: 170 },
  { id: 'actionsID', label: 'Actions', minWidth: 100 },
];

export default function UserTable({ employeeList, deleteEmployee }) {
  const navigate = useNavigate();
  const [openConfirmDeleteDialogue, setOpenConfirmDeleteDialogue] = React.useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = React.useState(null);

  const handleDeleteDialogueOpen = (employeeId) => {
    setSelectedEmployeeId(employeeId);
    setOpenConfirmDeleteDialogue(true);
  };

  const handleDeleteDialogueClose = () => {
    setOpenConfirmDeleteDialogue(false);
  };

  const renderActions = (employeeId) => (
    <>
      <Tooltip title="Edit" placement="top" arrow>
        <EditIcon
          style={{ color: '#ff6600', fontSize: 30, cursor: 'pointer' }}
          onClick={() => navigate(`/account/employee/edit/${employeeId}`)}
        />
      </Tooltip>
      <Tooltip title="Delete" placement="top" arrow>
        <DeleteIcon
          style={{ color: 'red', fontSize: 30, cursor: 'pointer' }}
          onClick={() => handleDeleteDialogueOpen(employeeId)}
        />
      </Tooltip>
      <ConfirmDeleteDialogue
        title="Confirm Delete"
        message="Are you sure you want to delete this record? This action cannot be undone."
        open={openConfirmDeleteDialogue}
        handleClose={handleDeleteDialogueClose}
        handleDelete={() => {
          deleteEmployee(selectedEmployeeId);
          handleDeleteDialogueClose();
        }}
      />
    </>
  );

  const rows = employeeList.map((employee) => ({
    Name: `${employee.userId?.firstName || 'N/A'} ${employee.userId?.lastName || 'N/A'}`,
    Email: employee.userId?.email || 'N/A',
    Phone: employee.phone || 'N/A',
    Department: employee.department || 'N/A',
    Corporate: employee.corporateId?.legalName || employee.corporateId?.registeredName || 'N/A',
    Team: employee.teamId?.teamName || 'N/A',
    actionsID: employee._id,
  }));

  return <ResponsiveTable columns={columns} rows={rows} renderActions={renderActions} />;
}
