import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ErrorDialogueBox from "../MUIDialogueBox/ErrorDialogueBox";
import axios from "axios";
import Box from "@mui/material/Box";
import ToastService from "../../Service/toast.service";

function EditCorporate() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [legalName, setLegalName] = useState("");
  const [registeredName, setRegisteredName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [corporateReg, setCorporateReg] = useState("");
  const [corporateVat, setCorporateVat] = useState("");
  const [corporateType, setCorporateType] = useState("");

  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const handleDialogueOpen = () => setErrorDialogueBoxOpen(true);
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  useEffect(() => {
    getCorporateById();
  }, []);

  const getCorporateById = async () => {
    const baseApi =
      process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";

    try {
      const response = await axios.get(`${baseApi}/corporates/${id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const corporate = response.data;
      setLegalName(corporate.legalName);
      setRegisteredName(corporate.registeredName);
      setEmail(corporate.email);
      setPhone(corporate.phone);
      setCorporateReg(corporate.corporateReg);
      setCorporateVat(corporate.corporateVat);
      setCorporateType(corporate.corporateType);
    } catch (error) {
      setErrorList([error.message || "Failed to fetch corporate details."]);
      handleDialogueOpen();
    }
  };

  const updateCorporate = async (e) => {
    e.preventDefault();
    const baseApi =
      process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";

    try {
      await axios.patch(
        `${baseApi}/corporates/${id}`,
        {
          legalName,
          registeredName,
          email,
          phone,
          corporateReg,
          corporateVat,
          corporateType,
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      ToastService.success('Updated corporate successfully.')
      navigate("/account/corporates");
    } catch (error) {
      setErrorList(error.response?.data?.errors || ["Failed to update corporate."]);
      handleDialogueOpen();
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="page-title">Edit Corporate</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <form id="editCorporateForm" name="editCorporateForm" onSubmit={updateCorporate}>
                  <div className="row">
                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Legal Name <span className="text-danger">*</span>
                        </label>
                        <input
                          name="legalName"
                          className="form-control"
                          type="text"
                          required
                          value={legalName}
                          onChange={(event) => setLegalName(event.target.value)}
                        />
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Registered Name <span className="text-danger">*</span>
                        </label>
                        <input
                          name="registeredName"
                          className="form-control"
                          type="text"
                          required
                          value={registeredName}
                          onChange={(event) => setRegisteredName(event.target.value)}
                        />
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          name="email"
                          className="form-control"
                          type="email"
                          required
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Phone <span className="text-danger">*</span>
                        </label>
                        <input
                          name="phone"
                          className="form-control"
                          type="text"
                          required
                          value={phone}
                          onChange={(event) => setPhone(event.target.value)}
                        />
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Registration Number <span className="text-danger">*</span>
                        </label>
                        <input
                          name="corporateReg"
                          className="form-control"
                          type="text"
                          required
                          value={corporateReg}
                          onChange={(event) => setCorporateReg(event.target.value)}
                        />
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          VAT Number <span className="text-danger">*</span>
                        </label>
                        <input
                          name="corporateVat"
                          className="form-control"
                          type="text"
                          required
                          value={corporateVat}
                          onChange={(event) => setCorporateVat(event.target.value)}
                        />
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Corporate Type <span className="text-danger">*</span>
                        </label>
                        <select
                          name="corporateType"
                          className="form-select"
                          required
                          value={corporateType}
                          onChange={(event) => setCorporateType(event.target.value)}
                        >
                          <option value="">Select Type</option>
                          <option value="PTY">PTY</option>
                          <option value="CC">Close Corp</option>
                          <option value="NPC">NPC</option>
                          <option value="Public">Public</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="m-t-20 text-center">
                    <button type="submit" className="btn btn-primary submit-btn">
                      Update Corporate
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Edit Corporate"
          ErrorList={errorList}
        />
      </div>
    </Box>
  );
}

export default EditCorporate;
