import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import ToastService from '../../Service/toast.service';

function AddPatientWizard({ onPatientAdded, onWizardClose }) {
  const navigate = useNavigate();

  const steps = ['Patient Info', 'Previous Conditions', 'Medications'];
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };
  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [preferredComms, setPreferredComms] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDOB] = useState('');

  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const [conditions, setConditions] = useState([]);
  const [newCondition, setNewCondition] = useState('');

  const [medications, setMedications] = useState([]);
  const [newMedication, setNewMedication] = useState({ name: '', startDate: '', inUse: false });

  const [passwordValidationMessage, setPasswordValidationMessage] = useState('');
  const [passwordMatchDisplay, setPasswordMatchDisplay] = useState('none');

  useEffect(() => {
    if (password.length > 0 && password.trim().length <= 6) {
      setPasswordValidationMessage('Password Length must be greater than 6 characters');
    } else {
      setPasswordValidationMessage('');
    }
    if (password === confirmPassword) {
      setPasswordMatchDisplay('none');
    } else {
      setPasswordMatchDisplay('block');
    }
  }, [password, confirmPassword]);

  const handleDialogueOpen = () => {
    setErrorDialogueBoxOpen(true);
  };
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  const addCondition = () => {
    if (newCondition.trim() !== '') {
      setConditions((prev) => [...prev, newCondition.trim()]);
      setNewCondition('');
    }
  };
  const removeCondition = (index) => {
    setConditions((prev) => prev.filter((_, i) => i !== index));
  };

  const addMedication = () => {
    if (newMedication.name.trim() !== '' && newMedication.startDate !== '') {
      setMedications((prev) => [...prev, newMedication]);
      setNewMedication({ name: '', startDate: '', inUse: false });
    }
  };
  const removeMedication = (index) => {
    setMedications((prev) => prev.filter((_, i) => i !== index));
  };

  const addPatient = () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

    const patient = {
      firstName,
      lastName,
      idNumber,
      preferredComms,
      username,
      email,
      phone,
      password,
      confirmPassword,
      address,
      gender,
      dob,
      conditions,
      medications,
    };

    fetch(`${baseApi}/patients`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(patient),
    })
      .then((response) => response.json())
      .then((data) => {
        const respMessage = data.message;
        if (respMessage === 'success') {
          ToastService.success('Patient added successfully.');

          if (typeof onPatientAdded === 'function') {
            const newlyCreatedPatient = data.patient || {
              firstName: patient.firstName,
              lastName: patient.lastName,
            };
            onPatientAdded(newlyCreatedPatient);
          }

          if (typeof onWizardClose === 'function') {
            onWizardClose();
          }
        } else {
          setErrorList(data.errors);
          handleDialogueOpen();
        }
      })
      .catch((err) => {
        setErrorList(['An unexpected error occurred.']);
        handleDialogueOpen();
      });
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <div>
            <h4>Patient Info</h4>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>First Name <span className="text-danger">*</span></label>
                  <input
                    name="firstName"
                    className="form-control"
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    name="lastName"
                    className="form-control"
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Username <span className="text-danger">*</span></label>
                  <input
                    name="username"
                    className="form-control"
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Email <span className="text-danger">*</span></label>
                  <input
                    name="email"
                    className="form-control"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Phone </label>
                  <input
                    name="phone"
                    className="form-control"
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Preferred Communication </label>
                  <input
                    name="preferredComms"
                    className="form-control"
                    type="text"
                    value={preferredComms}
                    onChange={(e) => setPreferredComms(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Password</label>
                  <input
                    name="password"
                    className="form-control"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {passwordValidationMessage && (
                    <small className="text-danger">{passwordValidationMessage}</small>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Confirm Password</label>
                  <input
                    name="confirmPassword"
                    className="form-control"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <small
                    className="text-danger"
                    style={{ display: passwordMatchDisplay }}
                  >
                    Passwords do not match
                  </small>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Address </label>
                  <input
                    name="address"
                    className="form-control"
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label>Gender</label>
                  <select
                    name="gender"
                    className="form-select"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="">-- Select --</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label>Date of Birth </label>
                  <input
                    name="dob"
                    className="form-control"
                    type="date"
                    value={dob}
                    onChange={(e) => setDOB(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label>Id Number</label>
                  <input
                    name="idNumber"
                    className="form-control"
                    type="number"
                    value={idNumber}
                    onChange={(e) => setIdNumber(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      case 1:
        return (
          <div>
            <h4>Previous Conditions</h4>
            <div className="card">
              <div className="card-header">
                <h4>Add Condition</h4>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label>Condition</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Condition name"
                      value={newCondition}
                      onChange={(e) => setNewCondition(e.target.value)}
                    />
                    <button
                      className="btn btn-primary"
                      onClick={addCondition}
                      type="button"
                    >
                      +
                    </button>
                  </div>
                </div>
                <ul className="list-group mt-3">
                  {conditions.map((condition, index) => (
                    <li
                      key={index}
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      {condition}
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => removeCondition(index)}
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        );

      case 2:
        return (
          <div>
            <h4>Medications</h4>
            <div className="card">
              <div className="card-header">
                <h4>Current Medications</h4>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label>Medicine Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Medicine name"
                    value={newMedication.name}
                    onChange={(e) =>
                      setNewMedication((prev) => ({ ...prev, name: e.target.value }))
                    }
                  />
                </div>
                <div className="form-group">
                  <label>Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={newMedication.startDate}
                    onChange={(e) =>
                      setNewMedication((prev) => ({ ...prev, startDate: e.target.value }))
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      checked={newMedication.inUse}
                      onChange={(e) =>
                        setNewMedication((prev) => ({
                          ...prev,
                          inUse: e.target.checked,
                        }))
                      }
                    />{' '}
                    Currently in use
                  </label>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={addMedication}
                  type="button"
                >
                  Add Medication
                </button>
                <ul className="list-group mt-3">
                  {medications.map((med, index) => (
                    <li
                      key={index}
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <div>
                        <strong>{med.name}</strong> -{' '}
                        {new Date(med.startDate).toLocaleDateString()} -{' '}
                        {med.inUse ? 'In Use' : 'Not in Use'}
                      </div>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => removeMedication(index)}
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        );

      default:
        return <div>Unknown step</div>;
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            {}
            <p>Step {activeStep + 1} of {steps.length}: {steps[activeStep]}</p>

            {}
            {renderStepContent()}

            {}
            <div style={{ marginTop: '1rem' }}>
              {}
              {activeStep > 0 && (
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={handleBack}
                  style={{ marginRight: 8 }}
                >
                  Back
                </Button>
              )}
              {}
              {activeStep < steps.length - 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  Next
                </Button>
              )}
              {}
              {activeStep === steps.length - 1 && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={addPatient}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </div>

        {}
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Add Patient"
          ErrorList={errorList}
        />
      </div>
    </Box>
  );
}

export default AddPatientWizard;
