import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import Box from '@mui/material/Box';
import PrescriptionTable from '../MUITable/PrescriptionTable';
import { UserContext } from '../../Context/UserContext';

function PrescriptionList() {
    const { currentUser } = useContext(UserContext);

    const [prescriptions, setPrescriptions] = useState([]);
    const [users, setUsers] = useState({});
    const [patientList, setPatientList] = useState([]);
    const [doctorList, setDoctorList] = useState([]);
    const [patientSelected, setPatientSelected] = useState("");
    const [doctorSelected, setDoctorSelected] = useState("");
    const [errorList, setErrorList] = useState([]);

    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${baseApi}/users`, {
                headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            const userMap = response.data.reduce((acc, user) => {
                acc[user._id] = `${user.firstName} ${user.lastName}`;
                return acc;
            }, {});
            setUsers(userMap);
        } catch (error) {
            console.error("Error fetching users:", error);
            setErrorList((prev) => [...prev, "Failed to fetch users."]);
        }
    };

    const fetchPatients = async () => {
        try {
            const response = await axios.get(`${baseApi}/patients`, {
                headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setPatientList(response.data);
        } catch (error) {
            console.error("Error fetching patients:", error);
        }
    };

    const fetchDoctors = async () => {
        try {
            const response = await axios.get(`${baseApi}/doctors`, {
                headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setDoctorList(response.data);
        } catch (error) {
            console.error("Error fetching doctors:", error);
        }
    };

    const fetchPrescriptions = async () => {
        try {
            const response = await axios.get(`${baseApi}/prescriptions`, {
                headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
            });
    
            if (response.data.message === "success") {
                const prescriptions = response.data.prescriptions.map((prescription) => {
                    const patientName = users[prescription.patientId?.userId?.trim()] || "Unknown";
                    const doctorName = users[prescription.prescribingDoctor?.trim()] || "Unknown";
    
                    return {
                        ...prescription,
                        patientName,
                        doctorName,
                    };
                });
                setPrescriptions(prescriptions);
            } else {
                setErrorList((prev) => [...prev, "Failed to fetch prescriptions."]);
            }
        } catch (error) {
            console.error("Error fetching prescriptions:", error);
            setErrorList((prev) => [...prev, "An error occurred while fetching prescriptions."]);
        }
    };      

    const handleSearch = async (e) => {
        e.preventDefault();
        const reqObj = {
            patientId: patientSelected || "",
            doctorId: doctorSelected || "",
        };

        try {
            const response = await axios.post(`${baseApi}/prescriptions`, reqObj, {
                headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
            });

            if (response.data.message === "success") {
                const prescriptions = response.data.prescriptions.map((prescription) => {
                    const patientName = users[prescription.patientId?.userId] || "Unknown";
                    const doctorName = users[prescription.prescribingDoctor?.userId] || "Unknown";

                    return {
                        ...prescription,
                        patientName,
                        doctorName,
                    };
                });
                setPrescriptions(prescriptions);
            } else {
                setErrorList((prev) => [...prev, "Failed to fetch filtered prescriptions."]);
            }
        } catch (error) {
            console.error("Error filtering prescriptions:", error);
            setErrorList((prev) => [...prev, "An error occurred while filtering prescriptions."]);
        }
    };

    useEffect(() => {
        fetchUsers().then(() => {
            fetchPrescriptions();
            fetchPatients();
            fetchDoctors();
        });
    }, []);

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-sm-3 col-3">
                            <h4 className="page-title fw-bold">Prescriptions</h4>
                        </div>
                        <div className="col-sm-9 col-9 text-right m-b-20 d-flex justify-content-end">
                            <Link
                                to="/account/prescriptions/add"
                                className="btn btn-primary float-right btn-rounded"
                            >
                                <i className="fa fa-plus"></i> Add Prescription
                            </Link>
                        </div>
                    </div>
                    <form onSubmit={handleSearch} className={currentUser.userType === "Patient" ? "hide" : ""}>
                        <div className="row filter-row">
                            <div className="col-sm-4 col-md-4 mt-2">
                                <select
                                    name="patientId"
                                    id="patientId"
                                    className="form-select"
                                    value={patientSelected}
                                    onChange={(e) => setPatientSelected(e.target.value)}
                                >
                                    <option value="">Choose Patient</option>
                                    {patientList.map((patient) => (
                                        <option key={patient._id} value={patient._id}>
                                            {users[patient.userId] || "Unknown"}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {currentUser.userType === "Admin" && (
                                <div className="col-sm-4 col-md-4 mt-2">
                                    <select
                                        name="doctorId"
                                        id="doctorId"
                                        className="form-select"
                                        value={doctorSelected}
                                        onChange={(e) => setDoctorSelected(e.target.value)}
                                    >
                                        <option value="">Choose Doctor</option>
                                        {doctorList.map((doctor) => (
                                            <option key={doctor._id} value={doctor._id}>
                                                {users[doctor.userId] || "Unknown"}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            <div className="col-sm-4 col-md-4">
                                <button type="submit" className="btn btn-primary btn-block">
                                    Search
                                </button>
                            </div>
                        </div>
                    </form>
                    <PrescriptionTable prescriptionList={prescriptions} />
                </div>
            </div>
        </Box>
    );
}

export default PrescriptionList;
