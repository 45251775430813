import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import ToastService from '../../Service/toast.service';
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';

function AddPrescription() {
  const navigate = useNavigate();

  const [appointmentId, setAppointmentId] = useState('');
  const [appointments, setAppointments] = useState([]);
  const [prescribingDoctor, setPrescribingDoctor] = useState('');
  const [doctors, setDoctors] = useState([]);
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState('');
  const [prescriptionDate, setPrescriptionDate] = useState('');
  const [medicines, setMedicines] = useState([{ name: '', brand: '', quantity: '', repeats: '', file: null }]);
  const [indications, setIndications] = useState([]);
  const [contraindications, setContraIndications] = useState([]);
  const [uploadToggle, setUploadToggle] = useState(false);
  const [fileUpload, setFileUpload] = useState(null);
  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const handleDialogueOpen = () => setErrorDialogueBoxOpen(true);
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  const fetchAppointments = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";

    try {
      const response = await fetch(`${baseApi}/appointments`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      const data = await response.json();

      if (data && Array.isArray(data.appointments)) {
        const today = new Date();
        today.setHours(23, 59, 59, 999);

        const filteredAppointments = data.appointments.filter(appt => {
          const appointmentDate = new Date(appt.appointmentDate);
          return appointmentDate <= today;
        });

        const sortedAppointments = filteredAppointments.sort((a, b) =>
          new Date(b.appointmentDate) - new Date(a.appointmentDate)
        );

        setAppointments(sortedAppointments);
      } else {
        console.error("Unexpected response format for appointments:", data);
        setAppointments([]);
      }
    } catch (error) {
      console.error("Error fetching appointments:", error);
      setAppointments([]);
    }
  };

  const fetchDoctors = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

    try {
      const response = await fetch(`${baseApi}/doctors`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setDoctors(data);
      } else {
        console.error("Unexpected response format for doctors:", data);
        setDoctors([]);
      }
    } catch (error) {
      console.error("Error fetching doctors:", error);
      setDoctors([]);
    }
  };

  const fetchPatients = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

    try {
      const response = await fetch(`${baseApi}/patients`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setPatients(data);
      } else {
        console.error("Unexpected response format for patients:", data);
        setPatients([]);
      }
    } catch (error) {
      console.error("Error fetching patients:", error);
      setPatients([]);
    }
  };

  const handleAddMedicine = () => {
    setMedicines([...medicines, { name: '', brand: '', quantity: '', repeats: '', file: null }]);
  };

  const handleRemoveMedicine = (index) => {
    setMedicines(medicines.filter((_, i) => i !== index));
  };

  const handleMedicineChange = (index, field, value) => {
    const updatedMedicines = [...medicines];
    updatedMedicines[index][field] = value;
    setMedicines(updatedMedicines);
  };

  const addPrescription = async (event) => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

    event.preventDefault();

    const prescription = {
      appointmentId: appointmentId === 'Script Request' ? null : appointmentId,
      prescribingDoctor,
      prescriptionDate,
      patientId: selectedPatient,
      medicines,
      indications,
      uploadPrescription: uploadToggle ? fileUpload : null,
    };

    try {
      const response = await fetch(`${baseApi}/prescription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(prescription),
      });

      const data = await response.json();

      if (data.message === 'success') {
        ToastService.success('Prescription added successfully.');
        navigate('/account/prescriptions');
      } else {
        setErrorList(data.errors || ['An unexpected error occurred. Please try again.']);
        handleDialogueOpen();
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorList(['An unexpected error occurred. Please try again later.']);
      handleDialogueOpen();
    }
  };

  useEffect(() => {
    fetchAppointments();
    fetchDoctors();
    fetchPatients();
  }, []);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            <h4 className="page-title fw-bold">Add Prescription</h4>

            <form id="addPrescriptionForm" onSubmit={addPrescription}>
              <div className="row">
                {}
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Patient <span className="text-danger">*</span></label>
                    <select
                      className="form-control"
                      value={selectedPatient}
                      onChange={(e) => setSelectedPatient(e.target.value)}
                      required
                    >
                      <option value="">Select a patient</option>
                      {patients.map((patient) => (
                        <option key={patient._id} value={patient._id}>
                          {`${patient.userId.firstName} ${patient.userId.lastName}`}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {}
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Appointment <span className="text-danger">*</span></label>
                    <div className="input-group">
                      <select
                        className="form-control"
                        value={appointmentId}
                        onChange={(e) => setAppointmentId(e.target.value)}
                        required
                      >
                        <option value="Script Request">Script request - No appointment</option>
                        {appointments.map((appt) => {
                          const appointmentDate = new Date(appt.appointmentDate);
                          const formattedDate = appointmentDate.toLocaleDateString();
                          const formattedTime = appt.appointmentTime || 'Unknown Time';

                          return (
                            <option key={appt._id} value={appt._id}>
                              {`${appt.patientId?.userId?.firstName || 'Unknown'} ${appt.patientId?.userId?.lastName || ''
                                } - ${formattedDate} ${formattedTime}`}
                            </option>
                          );
                        })}
                      </select>
                      {appointments.length === 0 && (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary ml-2"
                            onClick={() => window.open('/account/appointments', '_blank')}
                          >
                            +
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary ml-2"
                            onClick={fetchAppointments}
                          >
                            Refresh
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {}
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Prescribing Doctor <span className="text-danger">*</span></label>
                    <select
                      className="form-control"
                      value={prescribingDoctor}
                      onChange={(e) => setPrescribingDoctor(e.target.value)}
                      required
                    >
                      <option value="">Select a doctor</option>
                      {doctors.map((doc) => (
                        <option key={doc?._id} value={doc?._id}>
                          {doc?.userId?.firstName} {doc?.userId?.lastName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {}
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Prescription Date <span className="text-danger">*</span></label>
                    <input
                      type="date"
                      className="form-control"
                      value={prescriptionDate}
                      onChange={(e) => setPrescriptionDate(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>

              {}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={uploadToggle}
                  onChange={() => setUploadToggle(!uploadToggle)}
                />
                <label className="form-check-label">Upload Prescription</label>
              </div>

              {}
              {uploadToggle && (
                <div className="form-group mt-3">
                  <label>Upload Prescription File</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={(e) => setFileUpload(e.target.files[0])}
                  />
                </div>
              )}

              {}
              <h5>Medicines</h5>
              {medicines.map((medicine, index) => (
                <div key={index} className="row mb-3">
                  <div className="col-sm-3">
                    <label>Brand</label>
                    <input
                      className="form-control"
                      value={medicine.brand}
                      onChange={(e) => handleMedicineChange(index, 'brand', e.target.value)}
                    />
                  </div>

                  {}
                  <div className="col-sm-3">
                    <label>Medicine Name</label>
                    <input
                      className="form-control"
                      value={medicine.name}
                      onChange={(e) => handleMedicineChange(index, 'name', e.target.value)}
                    />
                  </div>

                  {}
                  <div className="col-sm-2">
                    <label>Quantity</label>
                    <input
                      type="number"
                      className="form-control"
                      value={medicine.quantity}
                      onChange={(e) => handleMedicineChange(index, 'quantity', e.target.value)}
                    />
                  </div>

                  {}
                  <div className="col-sm-2">
                    <label>Repeats</label>
                    <input
                      type="number"
                      className="form-control"
                      value={medicine.repeats}
                      onChange={(e) => handleMedicineChange(index, 'repeats', e.target.value)}
                    />
                  </div>

                  {}
                  <div className="col-sm-2">
                    <button
                      type="button"
                      className="btn btn-danger mt-4"
                      onClick={() => handleRemoveMedicine(index)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
              <button type="button" className="btn btn-primary mb-3" onClick={handleAddMedicine}>
                Add Medicine
              </button>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>Indications</label>
                  <textarea
                    rows="5"
                    className="form-control"
                    value={indications}
                    onChange={(e) => setIndications(e.target.value)}
                    required
                  />
                </div>
              </div>

              {}
              <div className="mt-4">
                <button type="submit" className="btn btn-primary">
                  Create Prescription
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {}
      <ErrorDialogueBox
        open={errorDialogueBoxOpen}
        handleToClose={handleDialogueClose}
        ErrorTitle="Error: Add Prescription"
        ErrorList={errorList}
      />
    </Box>
  );
}

export default AddPrescription;
