import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom'
import { useNavigate, useParams } from "react-router-dom";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import axios from "axios";
import Box from '@mui/material/Box';

function Editemployee() {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [department, setDepartment] = useState('');
  const [corporate, setCorporate] = useState('');
  const [team, setTeam] = useState('');
  const [corporates, setCorporates] = useState([]);
  const [teams, setTeams] = useState([]);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [userId, setUserId] = useState('');
  const [passwordMatchDisplay, setPasswordMatchDisplay] = useState('none');
  const [passwordValidationMessage, setPasswordValidationMessage] = useState('');
  const { id } = useParams();

  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const handleDialogueOpen = () => {
    setErrorDialogueBoxOpen(true)
  };
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false)
  };

  useEffect(() => {
    getemployeeById();
  }, []);

  const getemployeeById = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";

    try {
      const [employeeRes, corporatesRes, teamsRes] = await Promise.all([
        axios.get(`${baseApi}/employees/${id}`),
        axios.get(`${baseApi}/corporates`),
        axios.get(`${baseApi}/teams`)
      ]);

      console.log('Employee Data:', employeeRes.data);
      console.log('Corporates:', corporatesRes.data);
      console.log('Teams:', teamsRes.data);

      setFirstName(employeeRes.data.userId?.firstName || '');
      setLastName(employeeRes.data.userId?.lastName || '');
      setEmail(employeeRes.data.userId?.email || '');
      setUsername(employeeRes.data.userId?.username || '');
      setPassword(employeeRes.data.userId?.password || '');
      setConfirmPassword(employeeRes.data.userId?.password || '');
      setPhone(employeeRes.data.phone || '');
      setDepartment(employeeRes.data.department || '');
      setCorporate(employeeRes.data.corporateId?._id || '');
      setTeam(employeeRes.data.teamId?._id || '');

      setCorporates(corporatesRes.data);
      setTeams(teamsRes.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const updateemployee = async (e) => {
    e.preventDefault();
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

    try {
      await axios.patch(`${baseApi}/employees/${id}`, {
        firstName,
        lastName,
        username,
        email,
        phone,
        password,
        confirmPassword,
        department,
        userId: userId || null,
        corporateId: corporate || null,
        teamId: team || null,
      });
      navigate("/account/employee");
    } catch (error) {

      console.log(error);
      setErrorList(error.response.data.errors);
      handleDialogueOpen();
    }
  };


  useEffect(() => {
    if ((typeof password !== 'undefined') && password.length > 0 && password?.trim()?.length <= 6) {
      setPasswordValidationMessage('Password Length must be greater than 6 characters');
    }
    else {
      setPasswordValidationMessage('');
    }
    if (password === confirmPassword) {
      setPasswordMatchDisplay('none');
    }
    else {
      setPasswordMatchDisplay('block');
    }
  }, [password, confirmPassword])

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">

          <div className="card-box">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="page-title">Edit Employee</h3>
              </div>
            </div>
            <div className="row">

              <div className="col-lg-12">
                <form id="editemployeeForm" name='editemployeeForm' onSubmit={updateemployee}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>First Name <span className="text-danger">*</span></label>
                        <input name="firstName" className="form-control" type="text" required value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input name="lastName" className="form-control" type="text" required value={lastName} onChange={(event) => setLastName(event.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Username <span className="text-danger">*</span></label>
                        <input name="username" className="form-control" type="text" required value={username} onChange={(event) => setUsername(event.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Email <span className="text-danger">*</span></label>
                        <input name="email" className="form-control" type="email" required value={email} onChange={(event) => setEmail(event.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Password</label>
                        <input name="password" className="form-control" type="password" required value={password} onChange={(event) => setPassword(event.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <input name="confirmPassword" className="form-control" type="password" required value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Phone </label>
                        <input name="phone" className="form-control" type="text" value={phone} onChange={(event) => setPhone(event.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Department</label>
                        <select name="department" className="form-select" value={department} onChange={(event) => setDepartment(event.target.value)}>
                          <option value="Finance">Finance</option>
                          <option value="Administration">Administration</option>
                          <option value="Accounts">Accounts</option>
                          <option value="Management">Management</option>
                          <option value="ICT">ICT</option>
                          <option value="Customer Service">Customer Service</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Corporate <span className="text-danger">*</span></label>
                        <select
                          name="corporate"
                          className="form-select"
                          value={corporate}
                          onChange={(event) => setCorporate(event.target.value)}
                        >
                          <option value="">Select Corporate</option>
                          {corporates.map(corp => (
                            <option key={corp._id} value={corp._id}>
                              {corp.legalName || corp.registeredName || 'Unnamed Corporate'}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Team <span className="text-danger">*</span></label>
                        <select
                          name="team"
                          className="form-select"
                          value={team}
                          onChange={(event) => setTeam(event.target.value)}
                        >
                          <option value="">Select Team</option>
                          {teams.map(team => (
                            <option key={team._id} value={team._id}>
                              {team.teamName || 'Unnamed Team'}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="m-t-20 text-left">
                    <button type="submit" className="btn btn-primary submit-btn">Update Employee</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Edit employee"
          ErrorList={errorList}
        />
      </div>
    </Box>
  )
}

export default Editemployee;
