import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import Box from '@mui/material/Box';
import TransactionTable from '../MUITable/TransactionTable';
import { UserContext } from '../../Context/UserContext';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { ProductionQuantityLimitsOutlined } from '@mui/icons-material';

function TransactionList() {
    const { currentUser } = useContext(UserContext);

    /**
     * Transaction use states
     * @since 0.1
     */
    const [transactions, setTransactions] = useState([]);
    const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);

    /**
     * Handle error dialog open
     * @since 0.1
     * @returns 
     */
    const handleDialogueOpen = () => setErrorDialogueBoxOpen(true);
    const handleDialogueClose = () => {
        setErrorList([]);
        setErrorDialogueBoxOpen(false);
    };

    /**
     * useEffect to fetch transactions on load
     * @since 0.1
     */
    useEffect(() => {
        fetchAllTransactions();
    }, []);

    /**
     * Fetch a list of transactions with a filter
     * @since 0.1
     * @param {*} filterName
     */
    const fetchTransactions = async (filterName = '') => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    
        try {
            // Prepare request parameters
            const params = {
                ...(filterName.trim() && { name: filterName }), // Filter by name if provided
                ...(currentUser.userType !== 'Admin' && { userId: currentUser._id }), // Add userId filter for non-Admins
            };
    
            const response = await axios.get(`${baseApi}/transactions`, { params });
            setTransactions(response.data);
        } catch (error) {
            console.error('Error fetching transactions:', error);
            setErrorList(['Failed to fetch transactions. Please try again later.']);
            handleDialogueOpen();
        }
    };
    
    /**
     * Fetch all transactions with or without params
     * @since 0.1
     */
    const fetchAllTransactions = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    
        try {
            const response = await axios.get(
                `${baseApi}/transactions/all`,
                currentUser.userType !== 'Admin'
                    ? { params: { userId: currentUser._id } } // Filter by userId for non-Admins
                    : {}
            );
            setTransactions(response.data);
        } catch (error) {
            console.error('Error fetching all transactions:', error);
            setErrorList(['Failed to fetch all transactions. Please try again later.']);
            handleDialogueOpen();
        }
    };    

    /**
     * Retry payment using Payfast
     * @since 0.1
     * @param {*} transactionIds 
     */
    const retryPayment = async (transactionIds) => {
        for (const id of transactionIds) {
            const transaction = transactions.find((t) => t._id === id);
    
            if (!transaction) {
                console.error(`Transaction with ID ${id} not found.`);
                continue;
            }
    
            const payload = {
                amount: transaction.total, 
                item_name: transaction.productName, 
                billingDetails: {
                    name_first: transaction.billingDetails.name,
                    name_last: transaction.billingDetails.surname,
                    email_address: transaction.billingDetails.email,
                    cell_number: transaction.billingDetails.mobile,
                    physicalAddress: transaction.billingDetails.physicalAddress,
                    billingAddress: transaction.billingDetails.billingAddress,
                },
                userId: transaction.userId, 
                productId: transaction.productId, 
            };
    
            try {
                // Send the payload to the backend
                const response = await axios.post('/api/payfast/initiate', payload);
    
                console.log('PayFast initiation response:', response.data);
    
                // Open the PayFast payment form in a new tab
                const formWindow = window.open('', '_blank');
                formWindow.document.write(response.data);
            } catch (error) {
                console.error(`Failed to retry payment for transaction ID ${id}:`, error);
            }
        }
    };    

    /**
     * Download transaction statements
     * @since 0.1
     * @param {*} transactionIds 
     */
    const downloadStatements = (transactionIds) => {

        const selectedTransactions = transactions.filter((t) => transactionIds.includes(t._id));

        let totalPaid = 0;
        let totalUnpaid = 0;

        const rows = selectedTransactions.map((transaction) => {
            const { transactionId, status, paymentMethod } = transaction.paymentDetails;
            const amount = transaction.total;

            if (status === 'Paid') {
                totalPaid += amount;
            } else if (status === 'Failed' || status === 'Unpaid') {
                totalUnpaid += amount;
            }

            return [
                transactionId || 'N/A',
                `R${amount.toFixed(2)}`,
                status || 'N/A',
                paymentMethod || 'N/A',
            ];
        });

        const doc = new jsPDF();

        doc.setFontSize(16);
        doc.text('Transaction Statement', 20, 20);

        doc.autoTable({
            startY: 30,
            head: [['Transaction ID', 'Amount', 'Status', 'Payment Method']],
            body: rows,
            theme: 'grid',
        });

        const finalY = doc.lastAutoTable.finalY + 10;
        doc.setFontSize(12);
        doc.text(`Total Paid: R${totalPaid.toFixed(2)}`, 20, finalY);
        doc.text(`Total Failed/Unpaid: R${totalUnpaid.toFixed(2)}`, 20, finalY + 10);

        doc.save('Transaction_Statement.pdf');
    };

    /**
     * Delete a transaction
     * @since 0.1
     * @param {*} id - transaction id
     * @returns 
     */
    const deleteTransaction = async (id) => {
        const confirmation = window.confirm('Are you sure you want to delete this transaction?');
        if (!confirmation) return;

        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        try {
            await axios.delete(`${baseApi}/transactions/${id}`);
            fetchAllTransactions();
        } catch (error) {
            console.error('Error deleting transaction:', error);
            setErrorList(['Failed to delete transaction. Please try again later.']);
            handleDialogueOpen();
        }
    };

    /**
     * Handle search function
     * @since 0.1
     * TODO: Needs to be updated
     */
    const handleSearch = (e) => {
        e.preventDefault();
        const searchName = new FormData(e.target).get('name');
        fetchTransactions(searchName);
    };

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-sm-4 col-3">
                            <h4 className="page-title fw-bold">Transactions</h4>
                        </div>
                    </div>
                    {/* Search form start */}
                    <form onSubmit={handleSearch} name="userFilter">
                        <div className="row filter-row">
                            <div className="col-sm-4 col-md-4">
                                <div className="form-floating">
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        placeholder="Transaction ID or Name"
                                    />
                                    <label className="focus-label">Transaction ID or Name</label>
                                </div>
                            </div>

                            <div className="col-sm-4 col-md-4">
                                <button type="submit" className="btn btn-primary btn-block">
                                    Search
                                </button>
                            </div>
                        </div>
                    </form>
                    {/* Transaction table component */}
                    <TransactionTable transactionList={transactions} deleteTransaction={deleteTransaction} retryPayment={retryPayment} downloadStatements={downloadStatements} />

                    {/* Error dialog box */}
                    <ErrorDialogueBox
                        open={errorDialogueBoxOpen}
                        handleToClose={handleDialogueClose}
                        ErrorTitle="Error: Transactions"
                        ErrorList={errorList}
                    />
                </div>
            </div>
        </Box>
    );
}

export default TransactionList;
