
import { useContext } from 'react';
import { UserContext } from '../Context/UserContext';
import { activityLogService } from '../Service/activityLogService';

const useActivityLogger = () => {
    const { currentUser } = useContext(UserContext);

    const logActivity = async (action, details = '') => {
        if (!currentUser) {
            console.warn('Attempted to log activity without an authenticated user.');
            return;
        }

        const logData = {
            type: 'USER',
            userId: currentUser._id,
            action,
            details
        };

        try {
            await activityLogService.createActivityLog(logData);
        } catch (error) {
            console.error('Error logging activity:', error);
        }
    };

    return { logActivity };
};

export default useActivityLogger;
