import styles from './Dashboard.module.css';
import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Onboarding from '../Modal/OnboardingModal';
import { UserContext } from '../../Context/UserContext';
import axios from "axios";
import moment from "moment";
import { NavLink } from 'react-router-dom';
import BookOnlineIcon from '@mui/icons-material/BookOnline';

export default function PatientDashboard() {
	const { currentUser } = useContext(UserContext);
	const [open, setOpen] = useState(false);
	const [patientProfile, setPatientProfile] = useState(null);
	const [isProfileComplete, setIsProfileComplete] = useState(true);
	const [firstAppointmentInFuture, setFirstAppointmentInFuture] = useState({});
	const [prescriptions, setPrescription] = useState([]);
	const [patientId, setPatientId] = useState('');

	useEffect(() => {
		checkProfileCompletion();
	}, []);

	useEffect(() => {
		if (currentUser) {
			fetchProfile();
		}
	}, [currentUser])

	const fetchProfile = async () => {
		const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
		try {
			const response = await axios.get(`${baseApi}/profile/patient/${currentUser?.userId}`);
			const data = response.data;
			setPatientId(data?._id || '');
			setPatientProfile(data);
		} catch (error) {
			console.error("Failed to fetch patient profile:", error);
		}
	};

	const checkProfileCompletion = () => {
		if (
			!currentUser.firstName ||
			!currentUser.lastName ||
			!currentUser.phone ||
			!currentUser.address ||
			!currentUser.gender
		) {
			setIsProfileComplete(false);
		}
	};

	const handleOpenOnboarding = () => {
		console.log('Opening Onboarding Modal');
		setOpen(true);
	};

	const getAppDate = (dateOfJoining) => {
		if (!dateOfJoining) return;
		return new Date(dateOfJoining.slice(0, -1)).getDate();
	};

	const getAppMonth = (dateOfJoining) => {
		if (!dateOfJoining) return;
		const month = new Date(dateOfJoining.slice(0, -1)).getMonth();
		const monthList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		return monthList[month];
	};

	const getAppYear = (dateOfJoining) => {
		if (!dateOfJoining) return;
		return new Date(dateOfJoining.slice(0, -1)).getFullYear();
	};

	const getPrescription = async () => {
		const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
		try {
			const response = await axios.post(`${baseApi}/prescriptions`, {}, {
				headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
			});
			if (response.data.message === "success") {
				const sortedPrescriptions = response.data.prescriptions.sort((a, b) => {
					const timeA = new Date(`${moment(a.appointmentId.appointmentDate.slice(0, -1)).format('MM/DD/YYYY')} ${a.appointmentId.appointmentTime}`);
					const timeB = new Date(`${moment(b.appointmentId.appointmentDate.slice(0, -1)).format('MM/DD/YYYY')} ${b.appointmentId.appointmentTime}`);
					return timeB - timeA;
				});
				setPrescription(sortedPrescriptions);
			}
		} catch (error) {
			console.error("Failed to fetch prescriptions:", error);
		}
	};

	useEffect(() => {
		getPrescription();
	}, []);

	return (
		<Box className={styles.dashboardBody} component="main" sx={{ flexGrow: 1, p: 3 }}>

			<div id={styles.welcomeBanner}>
				<div className="text-white">
					<h3>Welcome!</h3>
					<h4>{currentUser.firstName} {currentUser.lastName}</h4>
					<p>
						At Docotela, we believe that every patient deserves the highest quality care possible.
						Our commitment to excellence in healthcare is matched only by our compassion for those we serve.
					</p>
					{!isProfileComplete && (
						<Alert
							severity="info"
							action={
								<Button color="inherit" size="small" onClick={handleOpenOnboarding}>
									Complete Profile
								</Button>
							}
							sx={{ marginBottom: 2, marginTop: 4 }}
						>
							Your profile is incomplete. Please complete your profile to access full features.
						</Alert>
					)}
				</div>
			</div>

			<div className="row mt-5 justify-content-center">
				<div className="col-md-6 col-sm-12">
					<div className="customPatientApt mx-auto">
						<div className="topicHeader">
							<h3 className="text-center">Upcoming Appointment</h3>
						</div>
						<div className="topicContent">
							{firstAppointmentInFuture.appointmentDate ? (
								<div className="contentCard">
									<div className="apDate">
										<p className="date">{getAppDate(firstAppointmentInFuture.appointmentDate)}</p>
										<p>{getAppMonth(firstAppointmentInFuture.appointmentDate)}</p>
										<p>{getAppYear(firstAppointmentInFuture.appointmentDate)}</p>
									</div>
									<div className="apDetails">
										<p><strong>Doctor Name: </strong>{firstAppointmentInFuture.doctorId?.userId?.firstName} {firstAppointmentInFuture.doctorId?.userId?.lastName}</p>
										<p><strong>Department: </strong>{firstAppointmentInFuture.doctorId?.department}</p>
										<p><strong>Time: </strong>{firstAppointmentInFuture.appointmentTime}</p>
									</div>
								</div>
							) : (
								<div className="contentCard-empty">
									<p>You have no upcoming appointments.</p>
									<Button
										variant="contained"
										color="success"
										startIcon={<BookOnlineIcon />}
										component={NavLink}
										to="/account/appointments"
									>
										Book Now
									</Button>
								</div>
							)}
						</div>
					</div>
				</div>

				<div className="col-md-6 col-sm-12">
					<div className="customPatientApt mx-auto">
						<div className="topicHeader">
							<h3 className="text-center">Patient History</h3>
						</div>
						<div className="topicContent">
							{prescriptions[0]?.appointmentId ? (
								<div className="contentCard">
									<div className="apDate">
										<p className="date">{getAppDate(prescriptions[0]?.appointmentId?.appointmentDate)}</p>
										<p>{getAppMonth(prescriptions[0]?.appointmentId?.appointmentDate)}</p>
										<p>{getAppYear(prescriptions[0]?.appointmentId?.appointmentDate)}</p>
									</div>
									<div className="apDetails">
										<p><strong>Doctor Name: </strong>{prescriptions[0]?.appointmentId?.doctorId?.userId?.firstName} {prescriptions[0]?.appointmentId?.doctorId?.userId?.lastName}</p>
										<p><strong>Department: </strong>{prescriptions[0]?.appointmentId?.doctorId?.department}</p>
										<p><strong>Doctor's Remarks: </strong>{prescriptions[0]?.remarks}</p>
									</div>
								</div>
							) : (
								<div className="contentCard-empty">
									<p>You have no medical history.</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			{patientProfile && (
				<Onboarding key={currentUser?.id} open={open} onClose={() => setOpen(false)} patientId={patientId} patientProfile={patientProfile}/>
			)}
		</Box>
	);
}
