
import axios from 'axios';

/**
 * Fetches calendar events from Google Calendar within a specified time range.
 * @param {String} token - The OAuth 2.0 access token.
 * @param {String} timeMin - ISO string representing the start of the time range.
 * @param {String} timeMax - ISO string representing the end of the time range.
 * @returns {Array} - Array of calendar event objects.
 */
export const fetchCalendarEvents = async (token, timeMin, timeMax) => {
    try {
        const response = await axios.get('https://www.googleapis.com/calendar/v3/calendars/primary/events', {
            params: {
                timeMin,
                timeMax,
                singleEvents: true,
                orderBy: 'startTime',
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data.items;
    } catch (error) {
        console.error('Error fetching Google Calendar events:', error);
        throw error;
    }
};