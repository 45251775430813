import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AlarmOn from '@mui/icons-material/AlarmOn';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import moment from 'moment';
import { Typography } from '@mui/material';
import axios from 'axios';
import ToastService from '../../Service/toast.service';
import AppointmentModal from '../Modal/AppointmentModal';
import RescheduleModal from '../Modal/RescheduleModal';

const columns = [
    { id: 'status', label: 'Status', minWidth: 100 },
    { id: 'patientName', label: 'Patient Name', minWidth: 170 },
    { id: 'doctorName', label: 'Doctor Name', minWidth: 100 },
    { id: 'appointmentDate', label: 'Appointment Date', minWidth: 170 },
    { id: 'appointmentTime', label: 'Appointment Time', minWidth: 170 },
    { id: 'actionsID', label: 'Actions', minWidth: 100 },
];

const AppointmentTable = ({ setBookedAppointments, fetchBookedAppointments, bookedAppointments, deleteBookedSlots, onFollowUp, onReschedule }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [appointments, setAppointments] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);

    useEffect(() => {
        setAppointments(bookedAppointments || []);
    }, [bookedAppointments]);

    const rows = appointments.map((apt) => ({
        status: apt.isApproved ? 'Approved' : 'Pending',
        patientName: `${apt.patientId?.userId?.firstName} ${apt.patientId?.userId?.lastName}`,
        doctorName: `${apt.doctorId?.userId?.firstName} ${apt.doctorId.userId?.lastName}`,
        appointmentDate: moment(apt.appointmentDate).format('YYYY-MM-DD'),
        appointmentTime: apt.appointmentTime,
        actionsID: apt._id,
    }));

    const handleOpenModal = (appointment) => {
        setSelectedAppointment(appointment);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedAppointment(null);
    };

    const handleConfirmAppointment = async (appointmentId) => {
        console.log('Attempting to approve appointment with ID:', appointmentId);
        try {
            const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
            const response = await axios.put(
                `${baseApi}/appointments/${appointmentId}/approve`,
                { status: 'Approved' },
                { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } }
            );
            console.log('Approve appointment response:', response.data);
            ToastService.success('Appointment approved successfully!');

            setBookedAppointments((prevAppointments) =>
                prevAppointments.map((apt) =>
                    apt._id === appointmentId ? { ...apt, isApproved: true } : apt
                )
            );
        } catch (error) {
            console.error('Error approving appointment:', error.response?.data || error.message);
            ToastService.error('Failed to approve appointment.');
        }
    };

    const handleSetReminder = async (appointmentId) => {
        const appointment = bookedAppointments.find((app) => app._id === appointmentId);
        if (!appointment) {
            ToastService.error('Appointment not found');
            return;
        }

        try {
            const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
            const response = await axios.post(
                `${baseApi}/appointments/send-reminders`,
                { appointmentId },
                { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } }
            );
            console.log('Set reminder response:', response.data);
            ToastService.success('Reminder set successfully!');

            setBookedAppointments((prevAppointments) =>
                prevAppointments.map((apt) =>
                    apt._id === appointmentId ? { ...apt, reminderSet: true } : apt
                )
            );
        } catch (error) {
            console.error('Error sending reminder:', error.response?.data || error.message);
            ToastService.error('Failed to send reminder.');
        }
    };

    const handleReschedule = (appointmentId) => {
        const appointment = bookedAppointments.find((apt) => apt._id === appointmentId);
        if (appointment) {
            setSelectedAppointment(appointment);
            setIsRescheduleModalOpen(true);
        }
    };

    const handleDelete = (appointmentId) => {
        deleteBookedSlots(appointmentId);
    };

    const handleRescheduleSubmit = async (rescheduleData) => {
        console.log('Reschedule data:', rescheduleData);
        setIsRescheduleModalOpen(false);
    };

    return (
        <Paper sx={{ width: '95%', overflow: 'hidden', marginTop: 5, boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)' }}>
            <TableContainer>
                <Table stickyHeader aria-label="appointment table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.length > 0 ? (
                            rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.actionsID}>
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        if (column.id === 'actionsID') {
                                            return (
                                                <TableCell key={column.id}>
                                                    <Tooltip title="Start">
                                                        <PlayArrowIcon
                                                            className="mx-2"
                                                            style={{ color: 'grey', fontSize: 30 }}
                                                            onClick={() => {
                                                                const appointment = bookedAppointments.find((app) => app._id === row.actionsID);
                                                                if (appointment) {
                                                                    handleOpenModal(appointment);
                                                                } else {
                                                                    console.error('Appointment not found');
                                                                }
                                                            }}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="Confirm">
                                                        <EventAvailableIcon
                                                            className="mx-2"
                                                            style={{ color: 'green', fontSize: 30 }}
                                                            onClick={() => handleConfirmAppointment(value)}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="Set Reminder">
                                                        <AlarmOn
                                                            className="mx-2"
                                                            style={{ color: 'teal', fontSize: 30 }}
                                                            onClick={() => handleSetReminder(value)}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="Reschedule">
                                                        <DateRangeIcon
                                                            className="mx-2"
                                                            style={{ color: 'orange', fontSize: 30 }}
                                                            onClick={() => handleReschedule(value)}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="Cancel">
                                                        <EventBusyIcon
                                                            className="mx-2"
                                                            style={{ color: 'red', fontSize: 30 }}
                                                            onClick={() => handleDelete(value)}
                                                        />
                                                    </Tooltip>
                                                </TableCell>
                                            );
                                        } else {
                                            return <TableCell key={column.id}>{value}</TableCell>;
                                        }
                                    })}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    <Typography variant="subtitle1">No appointments found.</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                onRowsPerPageChange={(e) => setRowsPerPage(+e.target.value)}
            />

            {selectedAppointment && (
                <AppointmentModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    appointment={selectedAppointment}
                    onFollowUp={onFollowUp}
                    onReschedule={onReschedule}
                />
            )}

            {isRescheduleModalOpen && (
                <RescheduleModal
                    isOpen={isRescheduleModalOpen}
                    onClose={() => setIsRescheduleModalOpen(false)}
                    onSubmit={handleRescheduleSubmit}
                    appointmentId={selectedAppointment?._id}
                    onRescheduleSuccess={() => {
                        fetchBookedAppointments();
                    }}
                />
            )}
        </Paper>
    );
};

export default AppointmentTable;
