import React, { useState, useEffect } from "react";
import { Box, Paper, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ApexCharts from "react-apexcharts";
import axios from "axios";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";

const AdminReportingScreen = () => {

  /**
   * Admin reports use states
   * @since 0.1
   */
  const [databases, setDatabases] = useState([]);
  const [selectedDatabase, setSelectedDatabase] = useState("");
  const [fields, setFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [summaryOption, setSummaryOption] = useState("");
  const [graphOptions, setGraphOptions] = useState({
    chart: { type: "line" },
    series: [],
    xaxis: { categories: [] },
  });

  /**
   * useEffect to fetch database list
   * @since 0.1
   */
  useEffect(() => {
    const fetchDatabases = async () => {
      try {
        const response = await axios.get("/api/databases");
        setDatabases(response.data);
      } catch (error) {
        console.error("Error fetching databases:", error);
      }
    };
    fetchDatabases();
  }, []);

  /**
   * useEffect to fetch database fields
   * @since 0.1
   */
  useEffect(() => {
    const fetchFields = async () => {
      if (!selectedDatabase) return;
      try {
        const response = await axios.get(`/api/fields/${selectedDatabase}`);
        setFields(response.data);
      } catch (error) {
        console.error("Error fetching fields:", error);
      }
    };
    fetchFields();
  }, [selectedDatabase]);

  /**
   * useEffect to fetch field data
   * @since 0.1
   */
  useEffect(() => {
    const fetchTableData = async () => {
      if (!selectedDatabase || selectedFields.length === 0) return;
      try {
        const response = await axios.post("/api/data", {
          database: selectedDatabase,
          fields: selectedFields,
        });
        setTableData(response.data);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };
    fetchTableData();
  }, [selectedDatabase, selectedFields]);

  /**
   * Export displayed report to excel
   * @since 0.1
   */
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(tableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(wb, "report.xlsx");
  };

  /**
   * Export displayed report to PDF
   * @since 0.1
   */
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text("Report", 20, 10);
    doc.autoTable({
      head: [selectedFields],
      body: tableData.map((row) => selectedFields.map((field) => row[field])),
    });
    doc.save("report.pdf");
  };

  /**
   * Export displayed report to CSV
   * @since 0.1
   */
  const exportToCSV = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      [selectedFields.join(","), ...tableData.map((row) => selectedFields.map((field) => row[field]).join(","))]
        .join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  /**
   * Update graph data based on report selection
   * @since 0.1
   */
  const updateGraphData = () => {
    const xCategories = tableData.map((row) => row[selectedFields[0]]);
    const yData = tableData.map((row) => row[selectedFields[1]]);
    setGraphOptions({
      ...graphOptions,
      series: [{ name: selectedFields[1], data: yData }],
      xaxis: { categories: xCategories },
    });
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-sm-4 col-3">
              <h4 className="page-title fw-bold">Reports</h4>
            </div>
            <div className="col-sm-6 col-6 justify-content-end align-items-end mb-4">
              <button className="btn btn-primary" onClick={exportToExcel} style={{ marginRight: "10px" }}>
                Export to Excel
              </button>
              <button className="btn btn-primary" onClick={exportToPDF} style={{ marginRight: "10px" }}>
                Export to PDF
              </button>
              <button className="btn btn-primary" onClick={exportToCSV}>
                Export to CSV
              </button>
            </div>
          </div>
          <Paper elevation={3} sx={{ p: 4 }}>
            {/* Database form selection start */}
            <form>
              <div className="row filter-row">
                <div className="col-sm-4 col-md-4">
                  <div className="form-floating">
                    <select
                      name="database"
                      className="form-control"
                      value={selectedDatabase}
                      onChange={(e) => setSelectedDatabase(e.target.value)}
                    >
                      <option value="">Select Database</option>
                      {databases.map((db) => (
                        <option key={db} value={db}>
                          {db}
                        </option>
                      ))}
                    </select>
                    <label className="focus-label">Database</label>
                  </div>
                </div>

                {/* Field Selection */}
                <div className="col-sm-4 col-md-4">
                  <div className="form-floating">
                    <select
                      name="fields"
                      className="form-control"
                      multiple
                      value={selectedFields}
                      onChange={(e) =>
                        setSelectedFields(
                          Array.from(e.target.selectedOptions, (option) => option.value)
                        )
                      }
                    >
                      {fields.map((field) => (
                        <option key={field} value={field}>
                          {field}
                        </option>
                      ))}
                    </select>
                    <label className="focus-label">Fields</label>
                  </div>
                </div>

                {/* Summary Option */}
                <div className="col-sm-4 col-md-4">
                  <div className="form-floating">
                    <select
                      name="summaryOption"
                      className="form-control"
                      value={summaryOption}
                      onChange={(e) => setSummaryOption(e.target.value)}
                    >
                      <option value="">Select Summary</option>
                      <option value="count">Count</option>
                      <option value="sum">Sum</option>
                      <option value="average">Average</option>
                      <option value="total">Total</option>
                    </select>
                    <label className="focus-label">Summary Option</label>
                  </div>
                </div>
              </div>
            </form>

            <Grid container spacing={3} sx={{ mt: 2 }}>
              {/* Data Table */}
              <Grid item xs={12}>
                <DataGrid
                  autoHeight
                  rows={tableData}
                  columns={selectedFields.map((field) => ({
                    field,
                    headerName: field,
                    flex: 1,
                  }))}
                  getRowId={(row) => row._id || Math.random()}
                />
              </Grid>

              {/* Graph */}
              <Grid item xs={12}>
                <button className="btn btn-primary" onClick={updateGraphData} style={{ marginBottom: "10px" }}>
                  Update Graph
                </button>
                <ApexCharts options={graphOptions} series={graphOptions.series} type="line" height={350} />
              </Grid>
            </Grid>
          </Paper>
        </div>
      </div>
    </Box>
  );
};

export default AdminReportingScreen;
