import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Tab,
  Tabs,
  Box,
  Button,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
  TextField
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import jsPDF from "jspdf";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import ToastService from '../../Service/toast.service';
import { UserContext } from '../../Context/UserContext';
import { savedbMedia } from '../../Service/dbMediaService';

import AddPatientWizard from '../Patient/AddPatientWizard';

function PatientProfile() {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const [profileImagePreview, setProfileImagePreview] = useState(null);
  const [patientId, setPatientId] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDOB] = useState('');

  const [conditions, setConditions] = useState('');
  const [chronicIllnesses, setChronicIllnesses] = useState('');
  const [surgeries, setSurgeries] = useState('');
  const [hospitalisations, setHospitalisations] = useState('');
  const [allergies, setAllergies] = useState('');

  const [occupation, setOccupation] = useState('');
  const [maritalStatus, setMaritalStatus] = useState('');
  const [smoke, setSmoke] = useState(false);
  const [drugs, setDrugs] = useState(false);
  const [alcohol, setAlcohol] = useState(false);
  const [exercise, setExercise] = useState(false);
  const [dietaryRestrictions, setDietaryRestrictions] = useState('');

  const [editPassword, setEditPassword] = useState(false);

  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  const [familyName, setFamilyName] = useState('');
  const [familyMembers, setFamilyMembers] = useState([]);
  const [familyBillingPatient, setFamilyBillingPatient] = useState(null);

  const familyColumns = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'dob', label: 'DOB', minWidth: 100 },
    { id: 'idNumber', label: 'ID Number', minWidth: 150 },
    { id: 'billing', label: 'Billing', minWidth: 100 },
    { id: 'actions', label: 'Actions', minWidth: 100 },
  ];

  const familyRows = familyMembers.map((member, index) => ({
    name: member.name,
    dob: member.dob || 'N/A',
    idNumber: member.idNumber || 'N/A',
    billing: familyBillingPatient === member._id,
    actions: index,
  }));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleNewPatientInFamily = (newPatient) => {
    if (!newPatient || !newPatient._id) {
      console.error('Invalid new patient data:', newPatient);
      return;
    }

    setFamilyMembers((prev) => [
      ...prev,
      {
        _id: newPatient._id,
        name: `${newPatient.firstName} ${newPatient.lastName}`.trim(),
        dob: newPatient.dob || 'N/A',
        idNumber: newPatient.idNumber || 'N/A',
      },
    ]);
    setShowModal(false);
  };

  const editFamilyMember = (rowIndex, updatedMember) => {
    const updated = [...familyMembers];
    updated[rowIndex] = updatedMember;
    setFamilyMembers(updated);
  };

  const handleBillingPatient = (rowIndex) => {
    const chosenId = familyMembers[rowIndex]._id;
    setFamilyBillingPatient(chosenId);
    ToastService.info(`Billing patient set to: ${familyMembers[rowIndex].name}`);
  };

  const handleSaveFamily = async () => {
    try {
      console.log('Family Members Before Saving:', familyMembers);
      const memberIds = familyMembers.map((m) => m._id);
      console.log('Mapped Member IDs:', memberIds);

      const familyPayload = {
        familyName,
        familyMembers: memberIds,
        familyBillingPatient,
      };

      const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
      const response = await axios.post(`${baseApi}/families/create`, familyPayload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.data.success) {
        ToastService.success('Family saved successfully.');
      } else {
        setErrorList(response.data.errors || ['Could not save family.']);
        setErrorDialogueBoxOpen(true);
      }
    } catch (error) {
      setErrorList(['An unexpected error occurred while saving the family.']);
      setErrorDialogueBoxOpen(true);
      console.error('Error saving family:', error.response?.data || error.message);
    }
  };

  const fetchFamily = async () => {
    try {
      const familyId = currentUser?.familyId;
      if (!familyId) {
        setErrorList(['No family associated with this user.']);
        setErrorDialogueBoxOpen(true);
        return;
      }

      const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
      const response = await axios.get(`${baseApi}/families/${familyId}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.data.success && response.data.family) {
        const { familyName, familyMembers, familyBillingPatient, user } = response.data.family;

        setFamilyName(familyName || '');
        setFamilyMembers(
          familyMembers.map((member) => ({
            ...member,
            name: member.name || 'Unknown',
            dob: member.dob || 'N/A',
            idNumber: member.idNumber || 'N/A',
          }))
        );
        setFamilyBillingPatient(familyBillingPatient || null);
        console.log('Family created by:', user);
      } else {
        console.error('Failed to fetch family:', response.data);
      }
    } catch (error) {
      console.error('Error fetching family info:', error.response?.data || error.message);
    }
  };

  const handleProfileImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImagePreview(URL.createObjectURL(file));

      try {
        const response = await savedbMedia(file);

        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
        const token = localStorage.getItem("token");

        if (response && response.mediaId) {
          const mediaUrl = `${baseApi}/dbmedia/${response.mediaId}`;

          await axios.patch(
            `${baseApi}/patients/${patientId}`,
            { profileImage: mediaUrl },
            {
              headers: {
                authorization: `Bearer ${token}`,
              },
            }
          );

          ToastService.success("Profile image uploaded successfully.");
        } else {
          ToastService.error("Failed to upload profile image.");
        }
      } catch (error) {
        ToastService.error("An error occurred while uploading the profile image.");
      }
    }
  };


  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Patient Profile Details", 10, 10);
    doc.text(`First Name: ${firstName}`, 10, 20);
    doc.text(`Last Name: ${lastName}`, 10, 30);
    doc.text(`Email: ${email}`, 10, 40);
    doc.text(`Username: ${username}`, 10, 50);
    doc.text(`Phone: ${phone}`, 10, 60);
    doc.text(`Address: ${address}`, 10, 70);
    doc.text(`Gender: ${gender}`, 10, 80);
    doc.text(`Date of Birth: ${dob}`, 10, 90);
    doc.save("Patient_Profile.pdf");
  };

  const handleUpdatePatient = async (e) => {
    e.preventDefault();
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    const token = localStorage.getItem("token");

    try {
      let profileImageUrl = null;
      if (profileImagePreview && typeof profileImagePreview !== "string") {
        const formData = new FormData();
        formData.append("file", document.querySelector("#upload-profile-image").files[0]);

        const uploadResponse = await axios.post(`${baseApi}/files/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`,
          },
        });
        profileImageUrl = uploadResponse.data.file.fileUrl;
      }

      await axios.patch(
        `${baseApi}/patients/${patientId}`,
        {
          firstName,
          lastName,
          username,
          email,
          phone,
          password: editPassword ? password : undefined,
          confirmPassword: editPassword ? confirmPassword : undefined,
          address,
          gender,
          dob,
          profileImage: profileImageUrl,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      ToastService.success("Saved profile successfully.");
      navigate("/account/profile");
    } catch (error) {
      setErrorList(error.response?.data?.errors || ["Failed to update profile"]);
      setErrorDialogueBoxOpen(true);
    }
  };

  const handleSaveOnboardingInfo = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    try {
      await axios.patch(`${baseApi}/patients/${patientId}`, {
        occupation,
        maritalStatus,
        smoke,
        drugs,
        alcohol,
        exercise,
        dietaryRestrictions,
      }, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      ToastService.success("Onboarding information saved successfully.");
    } catch (error) {
      ToastService.error("Failed to save onboarding information.");
    }
  };

  useEffect(() => {
    console.log('Current User:', currentUser);
    if (currentUser?.familyId) {
      fetchFamily();
    } else {
      console.error("No family ID found for the current user.");
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchProfile = async () => {
      const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
      try {
        const response = await axios.get(`${baseApi}/profile/patient/${currentUser?.userId}`, {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`
          }
        });
        const data = response.data;
        setPatientId(data?._id || '');
        setFirstName(data?.userId?.firstName || '');
        setLastName(data?.userId?.lastName || '');
        setEmail(data?.userId?.email || '');
        setUsername(data?.userId?.username || '');
        setPhone(data?.phone || '');
        setAddress(data?.address || '');
        setGender(data?.gender || '');
        setDOB(data?.dob || '');
        setConditions(data?.conditions || '');
        setChronicIllnesses(data?.chronicIllnesses || '');
        setSurgeries(data?.surgeries || '');
        setHospitalisations(data?.hospitalisations || '');
        setAllergies(data?.allergies || '');
        setOccupation(data?.occupation || '');
        setMaritalStatus(data?.maritalStatus || '');
        setSmoke(data?.smoke || false);
        setDrugs(data?.drugs || false);
        setAlcohol(data?.alcohol || false);
        setExercise(data?.exercise || false);
        setDietaryRestrictions(data?.dietaryRestrictions || '');
      } catch (error) {
        setErrorList(["Failed to fetch patient profile"]);
        setErrorDialogueBoxOpen(true);
      }
    };
    fetchProfile();
    fetchFamily();
  }, [currentUser]);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Paper sx={{ p: 3 }}>
        <div className="row">
          { }
          <div className="col-md-3 d-flex flex-column align-items-center justify-content-start">
            <div
              style={{
                width: 150,
                height: 150,
                borderRadius: '50%',
                backgroundColor: '#f0f0f0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                marginBottom: 10,
              }}
            >
              {profileImagePreview ? (
                <img
                  src={profileImagePreview}
                  alt="Profile Preview"
                  style={{ width: '100%', height: '100%' }}
                />
              ) : patientId && currentUser?.profileImage ? (
                <img
                  src={currentUser.profileImage}
                  alt="Profile"
                  style={{ width: '100%', height: '100%' }}
                />
              ) : (
                <PersonIcon style={{ fontSize: 50, color: '#9e9e9e' }} />
              )}

            </div>
            <input
              type="file"
              accept="image/*"
              onChange={handleProfileImageChange}
              style={{ display: 'none' }}
              id="upload-profile-image"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => document.getElementById('upload-profile-image').click()}
            >
              {profileImagePreview ? 'Replace Image' : 'Upload Image'}
            </Button>
          </div>

          { }
          <div className="col-md-9">
            <Tabs value={tabIndex} onChange={handleTabChange}>
              <Tab label="Profile Details" />
              <Tab label="Medical History" />
              <Tab label="Family Info" />
              <Tab label="Onboarding Information" />
              <Tab label="Activity History" />
            </Tabs>

            <div className="mt-3">
              { }
              {tabIndex === 0 && (
                <form onSubmit={handleUpdatePatient}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Username</label>
                        <input
                          type="text"
                          className="form-control"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Address</label>
                        <input
                          type="text"
                          className="form-control"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Gender</label>
                        <select
                          className="form-control"
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Date of Birth</label>
                        <input
                          type="date"
                          className="form-control"
                          value={dob}
                          onChange={(e) => setDOB(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => setEditPassword(!editPassword)}
                      >
                        {editPassword ? "Cancel Password Edit" : "Edit Password"}
                      </button>
                    </div>
                    {editPassword && (
                      <>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Password</label>
                            <input
                              type="password"
                              className="form-control"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Confirm Password</label>
                            <input
                              type="password"
                              className="form-control"
                              value={confirmPassword}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="mt-3">
                    <button type="submit" className="btn btn-primary">Save Changes</button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary mx-2"
                      onClick={downloadPDF}
                    >
                      Download Profile as PDF
                    </button>
                  </div>
                </form>
              )}

              { }
              {tabIndex === 1 && (
                <Box sx={{ backgroundColor: 'white', p: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Medical History
                  </Typography>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Conditions</label>
                        <input
                          type="text"
                          className="form-control"
                          value={conditions}
                          onChange={(e) => setConditions(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Chronic Illnesses</label>
                        <input
                          type="text"
                          className="form-control"
                          value={chronicIllnesses}
                          onChange={(e) => setChronicIllnesses(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Surgeries</label>
                        <input
                          type="text"
                          className="form-control"
                          value={surgeries}
                          onChange={(e) => setSurgeries(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Hospitalisations</label>
                        <input
                          type="text"
                          className="form-control"
                          value={hospitalisations}
                          onChange={(e) => setHospitalisations(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Allergies</label>
                        <input
                          type="text"
                          className="form-control"
                          value={allergies}
                          onChange={(e) => setAllergies(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              )}

              { }
              {tabIndex === 4 && (
                <Typography>Activity History (Coming Soon)</Typography>
              )}

              { }
              {tabIndex === 3 && (
                <form>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Occupation</label>
                        <input
                          type="text"
                          className="form-control"
                          value={occupation}
                          onChange={(e) => setOccupation(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Marital Status</label>
                        <select
                          className="form-control"
                          value={maritalStatus}
                          onChange={(e) => setMaritalStatus(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="single">Single</option>
                          <option value="married">Married</option>
                          <option value="divorced">Divorced</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Smoker</label>
                        <input
                          type="checkbox"
                          checked={smoke}
                          onChange={(e) => setSmoke(e.target.checked)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Drugs</label>
                        <input
                          type="checkbox"
                          checked={drugs}
                          onChange={(e) => setDrugs(e.target.checked)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Alcohol</label>
                        <input
                          type="checkbox"
                          checked={alcohol}
                          onChange={(e) => setAlcohol(e.target.checked)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Exercise</label>
                        <input
                          type="checkbox"
                          checked={exercise}
                          onChange={(e) => setExercise(e.target.checked)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Dietary Restrictions</label>
                        <input
                          type="text"
                          className="form-control"
                          value={dietaryRestrictions}
                          onChange={(e) => setDietaryRestrictions(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSaveOnboardingInfo}
                  >
                    Save Onboarding Information
                  </button>
                </form>
              )}

              { }
              {tabIndex === 2 && (
                <Box sx={{ backgroundColor: 'white', p: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Family Info
                  </Typography>
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label>Family Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={familyName}
                        onChange={(e) => setFamilyName(e.target.value)}
                      />
                    </div>
                  </div>

                  <Button
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={handleOpenModal}
                  >
                    Add Family Member
                  </Button>

                  <Paper sx={{ width: '95%', overflow: 'hidden', marginTop: 2, backgroundColor: 'white' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <Table stickyHeader aria-label="family table">
                        <TableHead>
                          <TableRow>
                            {familyColumns.map((column) => (
                              <TableCell
                                key={column.id}
                                style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {familyRows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, idx) => (
                              <TableRow hover tabIndex={-1} key={idx}>
                                {familyColumns.map((column) => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell key={column.id}>
                                      {column.id === 'billing' ? (
                                        value ? (
                                          <CheckCircleIcon color="success" />
                                        ) : (
                                          <CloseIcon color="error" />
                                        )
                                      ) : column.id === 'actions' ? (
                                        <>
                                          <Tooltip title="Edit" placement="top" arrow>
                                            <Button
                                              color="warning"
                                              onClick={() =>
                                                editFamilyMember(row.actions, {
                                                  ...familyMembers[row.actions],
                                                  name: row.name,
                                                })
                                              }
                                            >
                                              Edit
                                            </Button>
                                          </Tooltip>
                                          <Tooltip title="Set Billing" placement="top" arrow>
                                            <Button
                                              color="primary"
                                              onClick={() => handleBillingPatient(row.actions)}
                                            >
                                              Set Billing
                                            </Button>
                                          </Tooltip>
                                        </>
                                      ) : (
                                        value
                                      )}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 100]}
                      component="div"
                      count={familyRows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2, mr: 2 }}
                    onClick={handleSaveFamily}
                  >
                    Save Family
                  </Button>
                </Box>
              )}
            </div>
          </div>
        </div>
      </Paper>

      <ErrorDialogueBox
        open={errorDialogueBoxOpen}
        handleToClose={handleDialogueClose}
        ErrorTitle="Error"
        ErrorList={errorList}
      />

      { }
      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        PaperProps={{ sx: { width: 700, height: 600 } }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Add Family Member
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <AddPatientWizard
            onPatientAdded={handleNewPatientInFamily}
            onWizardClose={handleCloseModal}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default PatientProfile;
