import moment from 'moment';

/**
 * Generates available slots based on busy events, working hours, and slot duration.
 * @param {Array} busyEvents - Array of busy events with start and end times.
 * @param {String} date - The date for which to generate slots (YYYY-MM-DD).
 * @param {String} workingStart - Start time of working hours (HH:mm).
 * @param {String} workingEnd - End time of working hours (HH:mm).
 * @param {Number} slotDuration - Duration of each slot in minutes.
 * @param {String} timeFormat - Desired output format for time slots (default: 'HH:mm').
 * @returns {Array} - Array of available slot objects with appointmentDate and timeSlots array.
 */
export const generateAvailableSlots = (
    busyEvents = [],
    date,
    workingStart,
    workingEnd,
    slotDuration,
    timeFormat = 'HH:mm'
) => {
    const dayStart = moment(`${date}T${workingStart}`);
    const dayEnd = moment(`${date}T${workingEnd}`);

    const validBusyEvents = (busyEvents || []).filter(event => event.start && event.end);

    if (!validBusyEvents.length) {
        console.warn('No valid busy events provided.');
    }

    const sortedBusyEvents = validBusyEvents
        .map(event => ({
            start: moment(event.start),
            end: moment(event.end),
        }))
        .sort((a, b) => a.start - b.start);

    const mergedBusyEvents = [];
    sortedBusyEvents.forEach(event => {
        if (
            mergedBusyEvents.length &&
            event.start.isSameOrBefore(mergedBusyEvents[mergedBusyEvents.length - 1].end)
        ) {
            mergedBusyEvents[mergedBusyEvents.length - 1].end = moment.max(
                mergedBusyEvents[mergedBusyEvents.length - 1].end,
                event.end
            );
        } else {
            mergedBusyEvents.push(event);
        }
    });

    const timeSlots = [];
    let currentTime = moment(dayStart);

    mergedBusyEvents.forEach(event => {
        while (currentTime.clone().add(slotDuration, 'minutes').isSameOrBefore(event.start)) {
            timeSlots.push(currentTime.format(timeFormat));
            currentTime.add(slotDuration, 'minutes');
        }

        if (currentTime.isBefore(event.end)) {
            currentTime = event.end.clone();
        }
    });

    while (currentTime.clone().add(slotDuration, 'minutes').isSameOrBefore(dayEnd)) {
        timeSlots.push(currentTime.format(timeFormat));
        currentTime.add(slotDuration, 'minutes');
    }

    return [
        {
            appointmentDate: date,
            timeSlots,
        },
    ];
};
