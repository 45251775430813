import styles from './Header.module.css'
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import React, { useContext, useState } from 'react';
import { UserContext } from '../../../Context/UserContext';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import corporateStyles from './CorporateHeader.module.css'
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'userType',
})(({ theme, open, userType }) => ({
  zIndex: theme.zIndex.drawer,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: userType === 'Corporate' ? '#003366' : '#ffffff',
  color: userType === 'Corporate' ? '#ffffff' : '#4E5D71',
  boxShadow: userType === 'Corporate' ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const HelpDrawer = styled(MuiDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: 300,
    padding: theme.spacing(2),
    backgroundColor: '#f4f4f4',
    zIndex: theme.zIndex.drawer + 10,
    position: 'absolute',
  },
}));

const Header = ({ open, handleDrawerOpen }) => {
  const navigate = useNavigate();
  const { isLoggedIn, currentUser, signOutUser } = useContext(UserContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [helpDrawerOpen, setHelpDrawerOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => setDarkMode((prevMode) => !prevMode);

  const redirectToHome = () => {
    navigate("/account");
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    signOutUser();
    handleClose();
  };

  const toggleHelpDrawer = (open) => () => {
    setHelpDrawerOpen(open);
  };

  const goToProfile = () => {
    navigate('/account/profile');
  };

  const goToChat = () => {
    navigate('/account/chat');
  };

  const appliedStyles = currentUser?.userType === "Corporate" ? corporateStyles : styles;

  return (
    <Box>
      {}
      <AppBar className={appliedStyles.appbar} position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="#4E5D71"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h4"
            noWrap
            component="div"
            onClick={redirectToHome}
            sx={{ flexGrow: 1, fontWeight: 'bolder' }}
          >
            {currentUser?.userType === "Corporate" ? (
              <img
                src="https://www.roadcover.co.za/wp-content/uploads/2021/01/RoadCover-Logo-letterbox-option-scaled.jpg"
                alt="Corporate logo"
                height="50px"
                width="auto"
              />
            ) : (
              <img
                src="https://docotela.co.za/wp-content/uploads/2023/02/Docotela-new-logo-600x171.webp"
                alt="Docotela logo"
                height="50px"
                width="auto"
              />
            )}
          </Typography>

          {}
          <IconButton color="inherit" onClick={toggleDarkMode}>
            {darkMode ? <Brightness7Icon style={{ fontSize: 42 }} /> : <Brightness4Icon style={{ fontSize: 42 }} />}
          </IconButton>

          {isLoggedIn && (
            <div className={appliedStyles.accountIcon}>
              {}
              <IconButton onClick={toggleHelpDrawer(true)} color="inherit">
                <HelpOutlineIcon className={appliedStyles.helpicon} style={{ fontSize: 42 }} />
              </IconButton>
              {}
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                {currentUser?.profileImage ? (
                  <img
                    src={currentUser.profileImage}
                    alt="Profile"
                    style={{
                      width: 42,
                      height: 42,
                      borderRadius: '50%',
                      objectFit: 'cover',
                      marginRight: 8,
                    }}
                  />
                ) : (
                  <AccountCircle className={appliedStyles.accounticon} style={{ fontSize: 42, marginRight: 8 }} />
                )}
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                  <span
                    className={appliedStyles.icontext}
                    style={{ fontSize: 19, color: 'grey', marginTop: 3 }}
                  >
                    {currentUser.firstName} {currentUser.lastName}
                  </span>
                  <span className={appliedStyles.icontext} style={{ fontSize: 12, color: 'grey' }}>
                    {currentUser.userType}
                  </span>
                </div>
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={goToProfile}>Profile</MenuItem>
                <MenuItem onClick={goToChat}>Chat to Support</MenuItem>
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>

      {}
      <HelpDrawer anchor="right" open={helpDrawerOpen} onClose={toggleHelpDrawer(false)} sx={{ minWidth: '400px'}}>
        <Box>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Help
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Search for help"
            fullWidth
            sx={{ marginBottom: 2 }}
          />
          <Typography variant="body2" color="textSecondary">
            Enter your query to find support articles or contact support.
          </Typography>
        </Box>
      </HelpDrawer>
    </Box>
  );
};

export default Header;
