import React, { createContext, useState } from "react";
import createCustomTheme from "../theme";

const ThemeContext = createContext();

const ThemeProviderWrapper = ({ children }) => {
  const [themeSettings, setThemeSettings] = useState({
    primaryColor: "#58b29f",
    secondaryColor: "#96D6CE",
    tertiaryColor: "#4E5D71",
    fontColor: "#4E5D71",
  });

  const theme = createCustomTheme(themeSettings);

  return (
    <ThemeContext.Provider value={{ themeSettings, setThemeSettings }}>
      {children(theme)}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProviderWrapper };
