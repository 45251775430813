import React, { useContext, useState } from 'react';
import { UserContext } from '../../Context/UserContext';
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  useMediaQuery,
  Box,
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import ConfirmDeleteDialogue from '../MUIDialogueBox/ConfirmDeleteDialogue';

const OrderTable = ({ orderList, deleteOrder }) => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:768px)');

  const isAdminOrDoctor =
    currentUser?.userType === 'Admin' || currentUser?.userType === 'Doctor';

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openConfirmDeleteDialogue, setOpenConfirmDeleteDialogue] = useState(false);
  const [deleteOrderId, setDeleteOrderId] = useState(null);

  const columns = [
    { id: '_id', label: 'ORDER ID' },
    { id: 'company', label: 'BILLING NAME' },
    { id: 'name', label: 'EMAIL' },
    { id: 'package', label: 'PACKAGE' },
    { id: 'status', label: 'STATUS' },
    { id: 'total', label: 'TOTAL' },
    ...(isAdminOrDoctor
      ? [{ id: 'actions', label: 'ACTIONS' }]
      : []),
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDeleteDialogueOpen = (orderId) => {
    setDeleteOrderId(orderId);
    setOpenConfirmDeleteDialogue(true);
  };

  const handleDeleteDialogueClose = () => {
    setDeleteOrderId(null);
    setOpenConfirmDeleteDialogue(false);
  };

  const getStatusBadgeClass = (status) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return 'custom-badge status-green';
      case 'processing':
        return 'custom-badge status-orange';
      case 'pending':
        return 'custom-badge status-red';
      default:
        return 'custom-badge';
    }
  };

  if (isMobile) {
    return (
      <Box sx={{ padding: 2 }}>
        {orderList
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((order) => (
            <Card
              key={order._id}
              sx={{
                marginBottom: 2,
                boxShadow: '0 12px 24px rgba(0, 0, 0, 0.1)',
                padding: 2,
              }}
            >
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ background: 'white' }}
                >
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Order ID: {order._id}
                  </Typography>
                  {isAdminOrDoctor && (
                    <Box sx={{ background: 'white' }}>
                      <Tooltip title="Edit">
                        <EditIcon
                          sx={{ cursor: 'pointer', color: '#ff6600', mx: 1 }}
                          onClick={() => navigate(`/account/orders/edit/${order._id}`)}
                        />
                      </Tooltip>
                      <Tooltip title="Download">
                        <DownloadIcon
                          sx={{ cursor: 'pointer', color: '#1976d2', mx: 1 }}
                          onClick={() => navigate(`/account/orders/download/${order._id}`)}
                        />
                      </Tooltip>
                      <Tooltip title="Delete">
                        <DeleteIcon
                          sx={{ cursor: 'pointer', color: 'red', mx: 1 }}
                          onClick={() => handleDeleteDialogueOpen(order._id)}
                        />
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                <Box mt={2} sx={{ background: 'white' }}>
                  <Typography>
                    <strong>Billing Name:</strong>{' '}
                    {order.billingDetails
                      ? `${order.billingDetails.name} ${order.billingDetails.surname}`
                      : 'N/A'}
                  </Typography>
                  <Typography>
                    <strong>Email:</strong> {order.email || 'N/A'}
                  </Typography>
                  <Typography>
                    <strong>Package:</strong> {order.productName || 'N/A'}
                  </Typography>
                  <Typography>
                    <strong>Status:</strong>{' '}
                    <span className={getStatusBadgeClass(order.status || '')}>
                      {order.status || 'N/A'}
                    </span>
                  </Typography>
                  <Typography>
                    <strong>Total:</strong> R{(order.total ?? 0).toFixed(2)}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          ))}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={orderList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ConfirmDeleteDialogue
          title="Confirm Delete"
          message="Are you sure you want to delete this order? This action cannot be undone."
          open={openConfirmDeleteDialogue}
          handleClose={handleDeleteDialogueClose}
          handleDelete={() => {
            deleteOrder(deleteOrderId);
            handleDeleteDialogueClose();
          }}
        />
      </Box>
    );
  }

  return (
    <Paper
      sx={{
        width: '100%',
        overflow: 'hidden',
        marginTop: 2,
        boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)',
      }}
    >
      <TableContainer>
        <Table stickyHeader aria-label="Order Table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align || 'left'}
                  sx={{
                    minWidth: 100,
                    fontWeight: 'bold',
                    backgroundColor: '#f5f5f5',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orderList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((order) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={order._id}>
                  {columns.map((column) => {
                    let value;
                    switch (column.id) {
                      case 'company':
                        value = order.billingDetails
                          ? `${order.billingDetails.name} ${order.billingDetails.surname}`
                          : 'N/A';
                        break;
                      case 'name':
                        value = order.email || 'N/A';
                        break;
                      case 'package':
                        value = order.productName || 'N/A';
                        break;
                      case 'total':
                        value = `R${(order.total ?? 0).toFixed(2)}`;
                        break;
                      case 'status':
                        if (order.status) {
                          const badgeClass = getStatusBadgeClass(order.status);
                          value = (
                            <span className={badgeClass}>{order.status}</span>
                          );
                        } else {
                          value = 'N/A';
                        }
                        break;
                      default:
                        value = order[column.id] || 'N/A';
                    }

                    if (column.id === 'actions') {
                      return (
                        <TableCell key={column.id} align="center">
                          <Tooltip title="Edit">
                            <EditIcon
                              sx={{
                                cursor: 'pointer',
                                color: '#ff6600',
                                mx: 1,
                              }}
                              onClick={() =>
                                navigate(`/account/orders/edit/${order._id}`)
                              }
                            />
                          </Tooltip>
                          <Tooltip title="Download">
                            <DownloadIcon
                              sx={{
                                cursor: 'pointer',
                                color: '#1976d2',
                                mx: 1,
                              }}
                              onClick={() =>
                                navigate(`/account/orders/download/${order._id}`)
                              }
                            />
                          </Tooltip>
                          <Tooltip title="Delete">
                            <DeleteIcon
                              sx={{
                                cursor: 'pointer',
                                color: 'red',
                                mx: 1,
                              }}
                              onClick={() => handleDeleteDialogueOpen(order._id)}
                            />
                          </Tooltip>
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell key={column.id} align={column.align || 'left'}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={orderList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          '& .MuiTablePagination-root': {
            display: 'flex',
            justifyContent: 'space-between',
          },
        }}
      />
      <ConfirmDeleteDialogue
        title="Confirm Delete"
        message="Are you sure you want to delete this order? This action cannot be undone."
        open={openConfirmDeleteDialogue}
        handleClose={handleDeleteDialogueClose}
        handleDelete={() => {
          deleteOrder(deleteOrderId);
          handleDeleteDialogueClose();
        }}
      />
    </Paper>
  );
};

export default OrderTable;
