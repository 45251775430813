import React, { useContext, useEffect, useState, useMemo } from 'react';
import { UserContext } from '../../Context/UserContext';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import ResponsiveTable from './ResponsiveTable'; // Import your ResponsiveTable
import axios from 'axios';
import moment from 'moment';

const PrescriptionTable = () => {
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [prescriptions, setPrescriptions] = useState([]);
    const [users, setUsers] = useState({});

    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

    const columns = useMemo(() => [
        { id: 'prescriptionDate', label: 'Prescription Date', minWidth: 170 },
        { id: 'patientName', label: 'Patient Name', minWidth: 170 },
        { id: 'doctorName', label: 'Prescribing Doctor', minWidth: 170 },
        { id: 'medicines', label: 'Medicines', minWidth: 250 },
        { id: 'actions', label: 'Actions', minWidth: 150 },
    ], []);

    useEffect(() => {
        const loadData = async () => {
            try {
                const userResponse = await axios.get(`${baseApi}/users`, {
                    headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                const userMap = userResponse.data.reduce((acc, user) => {
                    acc[user._id] = `${user.firstName} ${user.lastName}`;
                    return acc;
                }, {});
                setUsers(userMap);

                const prescriptionResponse = await axios.get(`${baseApi}/prescriptions`, {
                    headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                const enrichedPrescriptions = prescriptionResponse.data.prescriptions.map((prescription) => ({
                    ...prescription,
                    patientName: userMap[prescription.patientId] || 'Unknown',
                    doctorName: userMap[prescription.prescribingDoctor] || 'Unknown',
                }));
                setPrescriptions(enrichedPrescriptions);
            } catch (error) {
                console.error('Error loading data:', error);
            }
        };

        loadData();
    }, []);

    const formatPrescriptionDate = (date) => {
        return date ? moment(date).format('MM/DD/YYYY') : 'N/A';
    };

    const formatMedicines = (medicines) => {
        if (!medicines || medicines.length === 0) return 'No medications prescribed.';
        return medicines.map((med) => `${med.name || 'Unknown Medicine'} (${med.quantity || 'N/A'})`).join(', ');
    };

    const handleEdit = (id) => {
        navigate(`/account/prescriptions/edit/${id}`);
    };

    const handleDownloadReceipt = async (id) => {
        try {
            const response = await axios.get(`${baseApi}/prescription/invoice/${id}`, {
                responseType: 'blob',
                headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
            });

            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `Prescription_${id}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Error downloading receipt:', error);
        }
    };

    const renderActions = (prescription) => (
        <>
            {(currentUser.userType === 'Admin' || currentUser.userType === 'Doctor') && (
                <Tooltip title="Edit" placement="top" arrow>
                    <EditIcon
                        style={{ color: '#ff6600', fontSize: 24, cursor: 'pointer', marginRight: '10px' }}
                        onClick={() => handleEdit(prescription._id)}
                    />
                </Tooltip>
            )}
            <Tooltip title="Download Receipt" placement="top" arrow>
                <DownloadIcon
                    style={{ color: '#1976d2', fontSize: 24, cursor: 'pointer' }}
                    onClick={() => handleDownloadReceipt(prescription._id)}
                />
            </Tooltip>
        </>
    );

    const rows = prescriptions.map((prescription) => ({
        prescriptionDate: formatPrescriptionDate(prescription.prescriptionDate),
        patientName: prescription.patientName,
        doctorName: prescription.doctorName,
        medicines: formatMedicines(prescription.medicines),
        actions: renderActions(prescription),
    }));

    return (
        <ResponsiveTable
            columns={columns}
            rows={rows}
            renderActions={() => null} // RenderActions is handled inline in the rows
        />
    );
};

export default PrescriptionTable;
