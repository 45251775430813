import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import ToastService from "../../Service/toast.service";
import ErrorDialogueBox from "../MUIDialogueBox/ErrorDialogueBox";

function AddTeams() {
  const navigate = useNavigate();

  /**
   * Team use states
   * @since 0.1
   */
  const [teamId, setTeamId] = useState("");
  const [teamName, setTeamName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [corporateId, setCorporateId] = useState("");
  const [corporates, setCorporates] = useState([]);
  const [users, setUsers] = useState([]);
  const [teamSaved, setTeamSaved] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);

  /**
   * Handle error dialog open
   * @since 0.1
   */
  const handleDialogueOpen = () => {
    setErrorDialogueBoxOpen(true);
  };

  /**
   * Handle error dialog close
   * @since 0.1
   */
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  /**
   * Fetch a list of corporates
   * @since 0.1
   */
  const fetchCorporates = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";
    try {
      const response = await fetch(`${baseApi}/corporates`);
      if (!response.ok) {
        throw new Error("Failed to fetch corporates");
      }
      const data = await response.json();
      setCorporates(data);
    } catch (error) {
      console.error("Error fetching corporates:", error);
    }
  };

  /**
   * Fetch a list of users
   * @since 0.1
   */
  const fetchUsers = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";
    try {
      const response = await fetch(`${baseApi}/users`);
      const data = await response.json();
      setUsers(data.users || []);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  /**
   * Add a team
   * @since 0.1
   * @param {*} event 
   */
  const addTeams = async (event) => {
    event.preventDefault();
    const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";
    const teamData = {
      teamName,
      email,
      phone,
      corporateId: corporateId || null,
    };
  
    try {
      const response = await fetch(`${baseApi}/teams`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(teamData),
      });
  
      const data = await response.json();
      if (data.message === "success") {
        ToastService.success("Team added successfully.");
        setTeamSaved(true);
        setTeamId(data.team._id);
      } else {
        setErrorList(data.errors || ["Failed to save team."]);
        handleDialogueOpen();
      }
    } catch (error) {
      console.error("Error adding team:", error);
      setErrorList(["An unexpected error occurred."]);
      handleDialogueOpen();
    }
  };  

  /**
   * Handle adding of user to team
   * @param {*} user 
   */
  const handleAddUser = async (user) => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";
    try {
      const updateUserResponse = await fetch(`${baseApi}/users/${user._id}`, {
        method: "PATCH",
        headers: {
          'Content-Type': 'application/json',
           authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ linkedTeam: teamId }),
      });

      if (!updateUserResponse.ok) {
        throw new Error("Failed to update user");
      }

      const updateTeamResponse = await fetch(`${baseApi}/teams/${teamId}`, {
        method: "PATCH",
          headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        body: JSON.stringify({ users: [...selectedUsers, user._id] }),
      });

      if (!updateTeamResponse.ok) {
        throw new Error("Failed to update team");
      }

      setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, user]);
      ToastService.success(`User ${user.firstName} ${user.lastName} added to the team.`);
    } catch (error) {
      console.error("Error adding user to team:", error);
      ToastService.error("Failed to add user to the team.");
    }
  };

  /**
   * Use effect to fetch corporates
   * @since 0.1
   */
  useEffect(() => {
    fetchCorporates();
  }, []);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="page-title">Add Team</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                {/* Add team form */}
                <form id="addTeamsForm" onSubmit={addTeams}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Team Name <span className="text-danger">*</span>
                        </label>
                        <input
                          name="teamName"
                          className="form-control"
                          type="text"
                          required
                          value={teamName}
                          onChange={(e) => setTeamName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          name="email"
                          className="form-control"
                          type="email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Phone</label>
                        <input
                          name="phone"
                          className="form-control"
                          type="text"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Corporate</label>
                        <select
                          className="form-control"
                          value={corporateId || ""}
                          onChange={(e) => setCorporateId(e.target.value || null)} 
                        >
                          <option value="">Select Corporate</option>
                          {corporates.map((corp) => (
                            <option key={corp._id} value={corp._id}>
                              {corp.legalName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="m-t-20 text-left">
                    <button
                      id="addTeams"
                      type="submit"
                      className="btn btn-primary submit-btn"
                    >
                      Create Team
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {teamSaved && (
              <div className="row mt-4">
                <div className="col-lg-12">
                  <h5 className="mb-3">Add Users to Team</h5>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedUsers.map((user) => (
                        <tr key={user._id}>
                          <td>{user.firstName}</td>
                          <td>{user.lastName}</td>
                          <td>
                            <button
                              className="btn btn-primary"
                              onClick={() => handleAddUser(user)}
                            >
                              Add
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
        
        {/* Error dialog box */}
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Add Team"
          ErrorList={errorList}
        />
      </div>
    </Box>
  );
}

export default AddTeams;
