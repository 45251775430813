import axios from 'axios';

const baseApi = process.env.REACT_APP_API_BASE_URL || 'http://my.docotela.co.za/api';

export const savedbMedia = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.post(`${baseApi}/dbmedia`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || 'Error saving media');
  }
};

export const getdbMedia = async (mediaId) => {
  try {
    const response = await axios.get(`${baseApi}/dbmedia/${mediaId}`, {
      responseType: 'blob',
    });

    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.error || 'Error retrieving media');
  }
};
