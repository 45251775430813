import React, { useContext, useState } from "react";
import Select from "react-select";
import { UserContext } from '../../Context/UserContext';

function AppointmentForm(props) {
    const { currentUser } = useContext(UserContext);
    const [selectedTime, setSelectedTime] = useState("");
    const [customTime, setCustomTime] = useState("");
    const [selectedPatient, setSelectedPatient] = useState(
        props.patientList.find(patient => patient._id === props.patientSelected) || null
    );

    const handleTimeChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedTime(selectedValue);
        if (selectedValue !== "add_custom_time") {
            props.formOnChange && props.formOnChange(selectedValue);
        }
    };

    const handlePatientChange = (selectedOption) => {
        setSelectedPatient(selectedOption);
        if (props.onPatientChange) {
            props.onPatientChange(selectedOption?.value);
        }
    };

    const patientOptions = props.patientList.map((patient) => ({
        value: patient._id,
        label: `${patient.userId.firstName} ${patient.userId.lastName}`,
    }));

    return (
        <form name={props.formName} onSubmit={props.formOnSubmit}>
            <div className="form-row">
                {}
                <div className="form-group col-11 pl-3 mx-auto">
                    <label htmlFor="doctor">Doctor: </label>
                    <select
                        name="doctor"
                        id="doctor"
                        className="form-control"
                        aria-label="Default select example"
                        required
                        disabled={props.doctorSelected ? "true" : null}
                    >
                        <option value="">Choose Doctor</option>
                        {props.doctorList.length === 0 ? (
                            <option value="">No doctors available</option>
                        ) : (
                            props.doctorList.map((doctor) => (
                                <option
                                    key={doctor._id}
                                    value={doctor._id}
                                    selected={props.doctorSelected === doctor._id}
                                >
                                    {doctor.userId?.firstName} {doctor.userId?.lastName}
                                </option>
                            ))
                        )}
                    </select>
                </div>

                {}
                <div className="form-group col-11 mx-auto">
                    <label htmlFor="appDate">Appointment Date :</label>
                    <input
                        type="date"
                        name="appDate"
                        className="form-control"
                        defaultValue={props.appDate}
                        required
                    ></input>
                </div>

                {}
                <div className="form-group col-11 pl-3 mx-auto">
                    <label htmlFor="appTime">Appointment Time :</label>
                    <select
                        name="appTime"
                        id="appTime"
                        className="form-control"
                        aria-label="Default select example"
                        required
                        onChange={handleTimeChange}
                        value={selectedTime}
                    >
                        <option value="">Choose Time</option>
                        {props.availableSlots.map((slot) => (
                            <option key={slot} value={slot}>
                                {slot}
                            </option>
                        ))}
                        <option value="add_custom_time">Add Custom Time</option>
                    </select>
                    {selectedTime === "add_custom_time" && (
                        <input
                            type="time"
                            name="customTime"
                            className="form-control mt-2"
                            value={customTime}
                            onChange={(e) => setCustomTime(e.target.value)}
                            required
                        />
                    )}
                </div>

                {}
                <div className="form-group col-11 pl-3 mx-auto">
                    <label htmlFor="patient">Patient :</label>
                    {currentUser.userType === "Patient" ? (
                        <input
                            type="text"
                            className="form-control"
                            value={`${currentUser.userId?.firstName} ${currentUser.userId?.lastName}`}
                            readOnly
                        />
                    ) : (
                        <Select
                            id="patient"
                            options={patientOptions}
                            value={selectedPatient}
                            onChange={handlePatientChange}
                            isSearchable
                            placeholder="Choose Patient"
                            required
                        />
                    )}
                </div>
            </div>
            <input type="hidden" name="id" defaultValue={props.appointmentId} />
            <div className="text-center">
                <input
                    type="submit"
                    className="btn btn-primary my-2 mx-4 col-4"
                    id="customBtn"
                    value="Submit"
                ></input>
            </div>
        </form>
    );
}

export default AppointmentForm;
