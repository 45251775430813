import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon, Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import axios from "axios";
import ToastService from "../../Service/toast.service";

const AddPage = () => {
  const [pageName, setPageName] = useState("");
  const [author, setAuthor] = useState("");
  const [status, setStatus] = useState("Draft");
  const [authors, setAuthors] = useState([]);
  const [components, setComponents] = useState([]);

  useEffect(() => {
    fetchAuthors();
  }, []);

  const fetchAuthors = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";

    try {
      const response = await axios.get(`${baseApi}/users`);
      setAuthors(response.data);
    } catch (error) {
      ToastService.error("Failed to fetch authors.");
    }
  };

  const addComponent = () => {
    setComponents([
      ...components,
      {
        id: Date.now(),
        name: "",
        headline: "",
        text: "",
        background: "",
        button: "",
        buttonLink: "",
      },
    ]);
  };

  const updateComponent = (id, field, value) => {
    setComponents(
      components.map((component) =>
        component.id === id ? { ...component, [field]: value } : component
      )
    );
  };

  const removeComponent = (id) => {
    setComponents(components.filter((component) => component.id !== id));
  };

  const savePage = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";

    const pageData = {
      name: pageName,
      author,
      status,
      components,
    };

    try {
      const response = await axios.post(`${baseApi}/pages`, pageData);
      if (response.data.success) {
        ToastService.success("Page added successfully.");
      } else {
        ToastService.error("Failed to add page.");
      }
    } catch (error) {
      ToastService.error("Error saving the page.");
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <Typography variant="h5" gutterBottom>
            Add Page
          </Typography>
          <Box sx={{ mb: 2 }}>
            <TextField
              label="Page Name"
              fullWidth
              variant="outlined"
              value={pageName}
              onChange={(e) => setPageName(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Select
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
            >
              <MenuItem value="" disabled>
                Select Author
              </MenuItem>
              {authors.map((user) => (
                <MenuItem key={user.id} value={user.name}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
            >
              <MenuItem value="Draft">Draft</MenuItem>
              <MenuItem value="Published">Published</MenuItem>
              <MenuItem value="Archived">Archived</MenuItem>
            </Select>
          </Box>
          <Box>
            <Typography variant="h6" gutterBottom>
              Components
            </Typography>
            {components.map((component, index) => (
              <Accordion key={component.id} sx={{ mb: 2 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Component {index + 1}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    label="Component Name"
                    fullWidth
                    variant="outlined"
                    value={component.name}
                    onChange={(e) =>
                      updateComponent(component.id, "name", e.target.value)
                    }
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Headline"
                    fullWidth
                    variant="outlined"
                    value={component.headline}
                    onChange={(e) =>
                      updateComponent(component.id, "headline", e.target.value)
                    }
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Text"
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={3}
                    value={component.text}
                    onChange={(e) =>
                      updateComponent(component.id, "text", e.target.value)
                    }
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Background"
                    fullWidth
                    variant="outlined"
                    value={component.background}
                    onChange={(e) =>
                      updateComponent(component.id, "background", e.target.value)
                    }
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Button"
                    fullWidth
                    variant="outlined"
                    value={component.button}
                    onChange={(e) =>
                      updateComponent(component.id, "button", e.target.value)
                    }
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Button Link"
                    fullWidth
                    variant="outlined"
                    value={component.buttonLink}
                    onChange={(e) =>
                      updateComponent(component.id, "buttonLink", e.target.value)
                    }
                    sx={{ mb: 2 }}
                  />
                  <Box textAlign="right">
                    <IconButton onClick={() => removeComponent(component.id)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={addComponent}
              sx={{ mb: 3 }}
            >
              Add Component
            </Button>
          </Box>
          <Box textAlign="right">
            <Button variant="contained" color="primary" onClick={savePage}>
              Save Page
            </Button>
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default AddPage;
