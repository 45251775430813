import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import axios from 'axios';
import Box from '@mui/material/Box';

function Edittransaction() {
  const navigate = useNavigate();
  const { id } = useParams();

  /** 
   * Transaction use states
   * @since 0.1
   **/
  const [billingName, setBillingName] = useState('');
  const [billingSurname, setBillingSurname] = useState('');
  const [mobile, setMobile] = useState('');
  const [physicalAddress, setPhysicalAddress] = useState('');
  const [billingAddress, setBillingAddress] = useState('');

  const [email, setEmail] = useState('');
  const [productName, setProductName] = useState('');
  const [billingFrequency, setBillingFrequency] = useState('');
  const [price, setPrice] = useState(0);
  const [vat, setVat] = useState(0);
  const [total, setTotal] = useState(0);

  const [tableData, setTableData] = useState([]);

  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);

  /**
   * Handle error dialog open
   * @since 0.1
   * @returns 
   */
  const handleDialogueOpen = () => setErrorDialogueBoxOpen(true);
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  /**
   * useEffect to get transactions by Id on load
   * @since 0.1
   */
  useEffect(() => {
    getTransactionById();
  }, []);

  /**
   * Get transaction by ID
   * @since 0.1
   */
  const getTransactionById = async () => {
    try {
      const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
      const response = await axios.get(`${baseApi}/transactions/${id}`);
      const data = response.data;

      setBillingName(data.billingDetails.name);
      setBillingSurname(data.billingDetails.surname);
      setMobile(data.billingDetails.mobile);
      setPhysicalAddress(data.billingDetails.physicalAddress);
      setBillingAddress(data.billingDetails.billingAddress);

      setEmail(data.email);
      setProductName(data.productName);
      setBillingFrequency(data.billingFrequency);
      setPrice(data.price);
      setVat(data.vat);
      setTotal(data.total);

      const items = [
        {
          name: data.productName,
          price: data.price,
          subscription: data.billingFrequency,
          quantity: 1,
        },
      ];
      setTableData(items);

      calculateTotals(items);
    } catch (error) {
      console.error(error);
      setErrorList([`Failed to fetch transaction: ${error.message}`]);
      handleDialogueOpen();
    }
  };

  /**
   * Update transaction information
   * @since 0.1
   * @param {*} e 
   */
  const updatetransaction = async (e) => {
    e.preventDefault();
    try {
      const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

      const updatedItem = tableData[0] || {};

      await axios.patch(`${baseApi}/transactions/${id}`, {
        email,
        productName: updatedItem.name || productName,
        price: updatedItem.price || price,
        billingFrequency: updatedItem.subscription || billingFrequency,
        vat,
        total,

        billingDetails: {
          name: billingName,
          surname: billingSurname,
          mobile,
          physicalAddress,
          billingAddress,
        },
      });

      navigate('/account/transactions');
    } catch (error) {
      console.error(error);
      setErrorList(error.response?.data?.errors || [error.message]);
      handleDialogueOpen();
    }
  };

  /**
   * Calculate transaction totals
   * @since 0.1
   * @param {*} items 
   */
  const calculateTotals = (items) => {
    const subtotal = items.reduce((sum, item) => sum + (item.price || 0) * (item.quantity || 0), 0);
    const vatCalc = subtotal * 0.15;
    const grandTotal = subtotal + vatCalc;

    setPrice(subtotal);
    setVat(vatCalc);
    setTotal(grandTotal);
  };

  /**
   * Update table when records are added/updated
   * @since 0.1
   * @param {*} index - rows of table
   * @param {*} key - col headings
   * @param {*} value - col values
   */
  const handleTableChange = (index, key, value) => {
    const updatedTableData = [...tableData];
    updatedTableData[index][key] = value;
    setTableData(updatedTableData);
    calculateTotals(updatedTableData);
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="page-title fw-bold">Edit Transaction - {id}</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                {/* Transaction form start */}
                <form id="edittransactionForm" name="edittransactionForm" onSubmit={updatetransaction}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>First Name <span className="text-danger">*</span></label>
                        <input
                          className="form-control"
                          type="text"
                          value={billingName}
                          onChange={(e) => setBillingName(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Surname <span className="text-danger">*</span></label>
                        <input
                          className="form-control"
                          type="text"
                          value={billingSurname}
                          onChange={(e) => setBillingSurname(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Mobile</label>
                        <input
                          className="form-control"
                          type="text"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Email Address <span className="text-danger">*</span></label>
                        <input
                          className="form-control"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Physical Address</label>
                        <textarea
                          className="form-control"
                          value={physicalAddress}
                          onChange={(e) => setPhysicalAddress(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Billing Address</label>
                        <textarea
                          className="form-control"
                          value={billingAddress}
                          onChange={(e) => setBillingAddress(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Price</th>
                          <th>Billing Frequency</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                className="form-control"
                                value={item.name}
                                onChange={(e) => handleTableChange(index, 'name', e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                className="form-control"
                                type="number"
                                value={item.price}
                                onChange={(e) =>
                                  handleTableChange(index, 'price', parseFloat(e.target.value) || 0)
                                }
                              />
                            </td>
                            <td>
                              <input
                                className="form-control"
                                value={item.subscription}
                                onChange={(e) => handleTableChange(index, 'subscription', e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                className="form-control"
                                type="number"
                                value={item.quantity}
                                onChange={(e) =>
                                  handleTableChange(index, 'quantity', parseInt(e.target.value) || 1)
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 text-right">
                      <h5>Subtotal: {price.toFixed(2)}</h5>
                      <h5>VAT (15%): {vat.toFixed(2)}</h5>
                      <h5>Grand Total: {total.toFixed(2)}</h5>
                    </div>
                  </div>

                  <div className="m-t-20 text-left">
                    <button type="submit" className="btn btn-primary submit-btn">
                      Update Transaction
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Error dialog box */}
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Edit Transaction"
          ErrorList={errorList}
        />
      </div>
    </Box>
  );
}

export default Edittransaction;
