import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Box from '@mui/material/Box';
import { UserContext } from '../../Context/UserContext';
import ToastService from '../../Service/toast.service';
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';

function AdminProfile() {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);

  /**
   * Admin profile use states
   * @since 0.1
   */
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userId, setUserId] = useState('');
  const [unhashedPassword, setUnhashedPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordMatchDisplay, setPasswordMatchDisplay] = useState('none');
  const [passwordValidationMessage, setPasswordValidationMessage] = useState('');
  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);

  /**
   * Handle error dialog open
   * @since 0.1
   * @returns 
   */
  const handleDialogueOpen = () => setErrorDialogueBoxOpen(true);

  /**
   * Handle error dialog close
   * @since 0.1
   */
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  /**
   * useEffect to get admin user by id
   * @since 0.1
   */
  useEffect(() => {
    getAdminById();
  }, []);

  /**
   * Get admin by Id
   * @since 0.1
   */
  const getAdminById = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    const adminUserId = currentUser.userId;

    try {
      const response = await axios.get(`${baseApi}/profile/admin/${adminUserId}`);
      setUserId(response.data._id);
      setFirstName(response.data.firstName);
      setLastName(response.data.lastName);
      setEmail(response.data.email);
      setUsername(response.data.username);
      setPassword(response.data.password);
      setConfirmPassword(response.data.password);
      setUnhashedPassword(response.data.unhashedPassword);
    } catch (error) {
      console.error("Error fetching admin data:", error);
    }
  };

  /**
   * Update admin user information
   * @since 0.1
   * @param {*} e 
   */
  const updateAdmin = async (e) => {
    e.preventDefault();
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

    try {
      await axios.patch(`${baseApi}/profile/admin/${userId}`, {
        firstName,
        lastName,
        username,
        email,
        password,
        confirmPassword
      });
      ToastService.success(`Successfully updated ${firstName} ${lastName}`);
      navigate("/account/profile");
    } catch (error) {
      console.error(error.response.data.errors);
      setErrorList(error.response.data.errors);
      handleDialogueOpen();
    }
  };

  /**
   * useEffect for password validation and confirmation
   * @since 0.1
   */
  useEffect(() => {
    if (password.length > 0 && password.trim().length <= 6) {
      setPasswordValidationMessage('Password Length must be greater than 6 characters');
    } else {
      setPasswordValidationMessage('');
    }
    setPasswordMatchDisplay(password === confirmPassword ? 'none' : 'block');
  }, [password, confirmPassword]);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="page-title">Update Profile</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                {/* Admin profile form start */}
                <form id="addAdminForm" name='addAdminForm' onSubmit={updateAdmin}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>First Name <span className="text-danger">*</span></label>
                        <input name="firstName" className="form-control" type="text" required value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input name="lastName" className="form-control" type="text" required value={lastName} onChange={(e) => setLastName(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Username <span className="text-danger">*</span></label>
                        <input name="username" className="form-control" type="text" required value={username} onChange={(e) => setUsername(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Email <span className="text-danger">*</span></label>
                        <input name="email" className="form-control" type="email" required value={email} onChange={(e) => setEmail(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Password</label>
                        <div className="password-field">
                          <input
                            name="password"
                            className="form-control"
                            type={showPassword ? "text" : "password"}
                            value={showPassword ? unhashedPassword : password}
                            autoComplete="new-password"
                            readOnly={!showPassword}
                          />
                          <a
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="btn btn-text-primary"
                          >
                            {showPassword ? "Hide" : "Show"}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <div className="password-field">
                          <input
                            name="confirmPassword"
                            className="form-control"
                            type={showConfirmPassword ? "text" : "password"}
                            value={confirmPassword}
                            autoComplete="new-password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          <a
                            type="button"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            className="btn btn-text-primary"
                          >
                            {showConfirmPassword ? "Hide" : "Show"}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="m-t-20 text-left">
                    <button type="submit" className="btn btn-primary submit-btn">Update Profile</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Error dialog box */}
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Edit Admin"
          ErrorList={errorList}
        />
      </div>
    </Box>
  );
}

export default AdminProfile;
