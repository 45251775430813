import React, { useEffect, useState } from 'react';
import { gapi } from 'gapi-script';
import { Button, Typography } from '@mui/material';
import moment from 'moment';
import RefreshIcon from '@mui/icons-material/Refresh';
import { fetchCalendarEvents } from '../utils/googleCalendarUtil';

const CLIENT_ID = process.env.REACT_APP_GCC_CLIENT_ID || '287009769086-00p4sfgt1cddchgh2ffb6nv9ma44c0hk.apps.googleusercontent.com';
const API_KEY = process.env.REACT_APP_GCC_API_KEY || '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';
const SCOPES = 'https://www.googleapis.com/auth/calendar.events';
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];

const GoogleCalendarIntegration = ({
  onCalendarSync,
  isGoogleConnected,
  setIsGoogleConnected,
  googleAuthEmail,
  setGoogleAuthEmail,
  refreshFlag,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const initClient = async () => {
      try {
        // Initialize the Google API client
        await gapi.client.init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        });

        const auth = gapi.auth2.getAuthInstance();

        // Ensure that gapi.auth2 is initialized before using it
        if (auth) {
          const storedToken = localStorage.getItem('google_access_token');
          if (storedToken) {
            const timeMin = moment().subtract(1, 'months').toISOString();
            const timeMax = moment().add(6, 'months').toISOString();

            const events = await fetchCalendarEvents(storedToken, timeMin, timeMax);
            if (onCalendarSync) onCalendarSync(events);

            setIsGoogleConnected(true);
          }

          // Check if the user is signed in
          if (auth.isSignedIn.get()) {
            const user = auth.currentUser.get();
            const email = user.getBasicProfile().getEmail();
            setIsGoogleConnected(true);
            setGoogleAuthEmail(email);
            handleFetchEvents(auth);
          }
        } else {
          console.error('Google Auth not initialized.');
        }
      } catch (error) {
        console.error('Error initializing Google API client:', error);
      }
    };

    gapi.load('client:auth2', initClient);
  }, [setIsGoogleConnected, setGoogleAuthEmail]);

  const handleAuthClick = async () => {
    try {
      const auth = gapi.auth2.getAuthInstance();
      if (!auth) {
        console.error('Google Auth is not initialized');
        return;
      }
      await auth.signIn();
      const token = auth.currentUser.get().getAuthResponse().access_token;
      const user = auth.currentUser.get();
      const email = user.getBasicProfile().getEmail();

      setIsGoogleConnected(true);
      setGoogleAuthEmail(email);

      localStorage.setItem('google_access_token', token);
      localStorage.setItem('isGoogleConnected', 'true');
      localStorage.setItem('googleAuthEmail', email);

      handleFetchEvents(auth);
    } catch (error) {
      console.error('Error during Google Sign-In:', error);
    }
  };

  const handleFetchEvents = async (auth) => {
    try {
      const token = auth.currentUser.get().getAuthResponse().access_token;

      const timeMin = moment().subtract(1, 'months').toISOString();
      const timeMax = moment().add(6, 'months').toISOString();

      const events = await fetchCalendarEvents(token, timeMin, timeMax);

      if (onCalendarSync) onCalendarSync(events);

      localStorage.setItem('google_access_token', token);
    } catch (error) {
      console.error('Error fetching calendar events:', error);
    }
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <Button
        className="btn btn-primary"
        onClick={handleAuthClick}
        style={{
          backgroundColor: isGoogleConnected ? 'grey' : '#58B29F',
          color: 'white',
        }}
        disabled={isGoogleConnected}
        fullWidth
        startIcon={<RefreshIcon />}
      >
        {isGoogleConnected ? 'Connected to Google' : 'Connect to Google Calendar'}
      </Button>

      {isGoogleConnected && googleAuthEmail && (
        <Typography variant="body2" style={{ marginTop: '10px' }}>
          Connected as: {googleAuthEmail}
        </Typography>
      )}
    </div>
  );
};

export default GoogleCalendarIntegration;
