import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ErrorDialogueBox from "../MUIDialogueBox/ErrorDialogueBox";
import Box from "@mui/material/Box";
import CorporateTable from "../MUITable/CorporateTable";
import ToastService from "../../Service/toast.service";

function CorporateList() {
  const params = new URLSearchParams(window.location.search);
  const name = params.get("name");
  const type = params.get("type");
  const [corporates, setCorporates] = useState([]);
  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleDialogueOpen = () => setErrorDialogueBoxOpen(true);
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  useEffect(() => {
    getCorporates();
  }, []);

  const getCorporates = async () => {
    const baseApi =
      process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";

    try {
      const response = await axios.get(`${baseApi}/corporates`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          name,
          type,
        },
      });
      setCorporates(response.data);
      setLoading(false);
    } catch (error) {
      setErrorList([error.message || "An unexpected error occurred."]);
      handleDialogueOpen();
    }
  };

  const deleteCorporate = async (id) => {
    const baseApi =
      process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";

    try {
      await axios.delete(`${baseApi}/corporates/${id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      ToastService.success('Corporate deleted successfully.')
      getCorporates();
      setLoading(false);
    } catch (error) {
      setErrorList([error.message || "An unexpected error occurred."]);
      handleDialogueOpen();
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-sm-3 col-3">
              <h4 className="page-title">Corporate List</h4>
            </div>
            <div className="col-sm-9 col-9 text-end m-b-20 d-flex justify-content-end">
              <Link
                to="/account/corporates/add"
                className="btn btn-primary float-right btn-rounded"
              >
                <i className="fa fa-plus"></i> Add Corporate
              </Link>
            </div>
          </div>

          {}
          <form action="/corporates" name="corporateFilter">
            <div className="row filter-row pb-4">
              {}
              <div className="col-sm-4 col-md-4">
                <div className="form-floating">
                  <input
                    name="name"
                    type="text"
                    id="corporateNameSearch"
                    className="form-control"
                    placeholder="Name"
                  />
                  <label htmlFor="corporateNameSearch">Corporate Name</label>
                </div>
              </div>

              {}
              <div className="col-sm-4 col-md-4">
                <div className="form-floating">
                  <select name="type" className="form-select floating">
                    <option value="">Select Type</option>
                    <option value="PTY">PTY</option>
                    <option value="CC">Close Corp</option>
                    <option value="NPC">NPC</option>
                    <option value="Public">Public</option>
                  </select>
                  <label htmlFor="type" className="focus-label">
                    Type
                  </label>
                </div>
              </div>

              {}
              <div className="col-sm-4 col-md-4">
                <button type="submit" className="btn btn-primary btn-block">
                  Search
                </button>
              </div>
            </div>
          </form>

          {}
          <CorporateTable
            corporateList={corporates}
            deleteCorporate={deleteCorporate}
            loading={loading}
          />

          {}
          <ErrorDialogueBox
            open={errorDialogueBoxOpen}
            handleToClose={handleDialogueClose}
            ErrorTitle="Error: Corporate List"
            ErrorList={errorList}
          />
        </div>
      </div>
    </Box>
  );
}

export default CorporateList;
