
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';

const ConfirmationPage = () => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
    >
      <CheckCircleIcon sx={{ color: '#58B29F', fontSize: 80 }} />
      <Typography variant="h4" mt={2}>
        Purchase Confirmed!
      </Typography>
      <Typography variant="h6" mt={1}>
        Thank you for your purchase. Your order has been successfully placed.
      </Typography>
      <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Button
        variant="contained"
        sx={{ mt: 4, mx: 2, backgroundColor: '#4e5d71', color: '#fff', '&:hover': { backgroundColor: '#58B29F' } }}
        onClick={() => navigate('/')}
      >
        Back to Home
      </Button>
      <Button
        variant="secondary"
        sx={{ mt: 4, mx: 2, backgroundColor: '#4e5d71', color: '#fff', '&:hover': { backgroundColor: '#58B29F' } }}
        onClick={() => navigate('/')}
      >
        Login to continue
      </Button>
      </Box>
    </Box>
  );
};

export default ConfirmationPage;
