// TransactionTable.jsx

import React, { useContext } from 'react';
import { UserContext } from '../../Context/UserContext';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip';
import ConfirmDeleteDialogue from '../MUIDialogueBox/ConfirmDeleteDialogue';
import { useNavigate } from 'react-router-dom';

export default function TransactionTable({
  transactionList,
  deleteTransaction,
  retryPayment,
  downloadStatements,
}) {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();

  const columns = [
    { id: 'select', label: '', minWidth: 50 },
    { id: 'transactionId', label: 'TRANSACTION ID', minWidth: 170 },
    { id: 'user', label: 'USER', minWidth: 170 },
    { id: 'amount', label: 'AMOUNT', minWidth: 100 },
    { id: 'status', label: 'STATUS', minWidth: 150 },
    { id: 'paymentMethod', label: 'PAYMENT METHOD', minWidth: 170 },
    ...((currentUser.userType === 'Admin' || currentUser.userType === "Doctor")
      ? [{ id: 'actions', label: 'ACTIONS', minWidth: 100 }]
      : []),
  ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selected, setSelected] = React.useState([]);

  const [openConfirmDeleteDialogue, setOpenConfirmDeleteDialogue] =
    React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);

  const handleSelect = (id) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((item) => item !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allIds = transactionList.map((transaction) => transaction._id);
      setSelected(allIds);
    } else {
      setSelected([]);
    }
  };

  const handleDeleteDialogueOpen = (id) => {
    setDeleteId(id);
    setOpenConfirmDeleteDialogue(true);
  };

  const handleDeleteDialogueClose = () => {
    setDeleteId(null);
    setOpenConfirmDeleteDialogue(false);
  };

  const rows = transactionList.map((transaction) => ({
    _id: transaction._id,
    transactionId: transaction.paymentDetails?.transactionId || 'N/A',
    user: `${
      transaction.billingDetails?.name || ''
    } ${transaction.billingDetails?.surname || ''} (${
      transaction.email || ''
    })`,
    amount: transaction.total || 0,
    status: transaction.paymentDetails?.status || 'N/A',
    paymentMethod: transaction.paymentDetails?.paymentMethod || 'N/A',
    actions: transaction,
  }));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Helper function to get status class based on status
  const getStatusClass = (status) => {
    switch (status.toLowerCase()) {
      case 'N/A':
        return 'status-blue';
      case 'paid':
        return 'status-green';
      case 'pending':
        return 'status-orange';
      case 'failed':
      case 'cancelled':
        return 'status-red';
      default:
        return '';
    }
  };

  return (
    <Paper
      sx={{
        width: '100%',
        overflow: 'hidden',
        marginTop: 2,
        boxShadow: '0 12px 24px rgba(0, 0, 0, 0.2)',
      }}
    >
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selected.length > 0 &&
                    selected.length < transactionList.length
                  }
                  checked={selected.length === transactionList.length}
                  onChange={(event) => handleSelectAll(event.target.checked)}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selected.includes(row._id)}
                      onChange={() => handleSelect(row._id)}
                    />
                  </TableCell>
                  {columns.map((column) => {
                    let value = row[column.id];

                    if (column.id === 'status') {
                      if (value) {
                        const statusClass = getStatusClass(value);
                        value = (
                          <span className={statusClass}>{value}</span>
                        );
                      } else {
                        value = 'N/A';
                      }
                    }

                    if (column.id === 'actions') {
                      return (
                        <TableCell key={column.id}>
                          <Tooltip title="Edit" placement="top" arrow>
                            <EditIcon
                              className="mx-2"
                              style={{
                                color: '#ff6600',
                                fontSize: 30,
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                navigate(`/account/transactions/edit/${row._id}`)
                              }
                            />
                          </Tooltip>
                          <Tooltip title="Delete" placement="top" arrow>
                            <DeleteIcon
                              className="mx-2"
                              style={{
                                color: 'red',
                                fontSize: 30,
                                cursor: 'pointer',
                              }}
                              onClick={() => handleDeleteDialogueOpen(row._id)}
                            />
                          </Tooltip>
                          {(row.status.toLowerCase() === 'failed' ||
                            row.status.toLowerCase() === 'cancelled') && (
                            <Tooltip title="Retry Payment" placement="top" arrow>
                              <ReplayIcon
                                className="mx-2"
                                style={{
                                  color: 'blue',
                                  fontSize: 30,
                                  cursor: 'pointer',
                                }}
                                onClick={() => retryPayment(row._id)}
                              />
                            </Tooltip>
                          )}
                          <ConfirmDeleteDialogue
                            title="Confirm Delete"
                            message="Are you sure you want to delete this transaction? This action cannot be undone."
                            open={
                              openConfirmDeleteDialogue && deleteId === row._id
                            }
                            handleClose={handleDeleteDialogueClose}
                            handleDelete={() => {
                              deleteTransaction(deleteId);
                              handleDeleteDialogueClose();
                            }}
                          />
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell key={column.id} align="left">
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', margin: '16px' }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<ReplayIcon />}
          onClick={() => retryPayment(selected)}
          disabled={selected.length === 0}
          style={{ marginRight: '8px' }}
        >
          Retry Payments
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<DownloadIcon />}
          onClick={() => downloadStatements(selected)}
          disabled={selected.length === 0}
        >
          Download Statements
        </Button>
      </div>
    </Paper>
  );
}
