import './css/App.css';
import HomePage from './HomePage';
import { UserContextProvider } from './Context/UserContext';
import { ThemeProviderWrapper } from "./Context/ThemeContext";
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from 'react-toastify';

function App() {

  return (
    <div className="App">
        <ThemeProviderWrapper>
          {(theme) => (
            <ThemeProvider theme={theme}>
              <UserContextProvider>
                <ToastContainer />
                <HomePage />
              </UserContextProvider>
            </ThemeProvider>
          )}
        </ThemeProviderWrapper>
    </div>
  );
}

export default App;
