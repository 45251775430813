import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import Box from '@mui/material/Box';
import PatientTable from '../MUITable/PatientTable';
import ToastService from '../../Service/toast.service';

function PatientList() {
    const params = new URLSearchParams(window.location.search);
    const name = params.get('name');

    const [patients, setPatient] = useState([]);

    const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const handleDialogueOpen = () => {
        setErrorDialogueBoxOpen(true)
    };
    const handleDialogueClose = () => {
        setErrorList([]);
        setErrorDialogueBoxOpen(false)
    };

    useEffect(() => {
        getPatients();
    }, []
    );

    const getPatients = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        const response = await axios.get(`${baseApi}/patients`, {
            params: {
                name: name
            }
        });
        setPatient(response.data);
    };

    const deletePatient = async (id) => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    
        try {
            await axios.delete(`${baseApi}/patients/${id}`);
            ToastService.success("Patient deleted successfully.")
            getPatients();
        } catch (error) {
            const errors = [];
            if (error.response) {
                if (error.response.data && typeof error.response.data === 'object') {
                    errors.push(error.response.data.message || "An error occurred.");
                } else {
                    errors.push("An error occurred while processing your request.");
                    ToastService.error("An error occurred while processing your request.")
                }
            } else if (error.request) {
                errors.push("No response from server. Please try again later.");
                ToastService.error("No response from server. Please try again later.")
            } else {
                errors.push(error.message || "An unexpected error occurred.");
                ToastService.error("An unexpected error occurred.")

            }
    
            setErrorList(errors);
            handleDialogueOpen();
        }
    };
    


    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>

            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-sm-3 col-3">
                            <h4 className="page-title fw-bold">Patient</h4>
                        </div>
                        <div className="col-sm-9 col-9 text-right m-b-20 d-flex justify-content-end">
                            <Link to="/account/patients/add" className="btn btn-primary float-right btn-rounded">
                                <i className="fa fa-plus"></i> Add Patient
                            </Link>
                        </div>
                    </div>
                    <form action="/account/patients" name="userFilter" >
                        <div className="row filter-row">

                            <div className="col-sm-4 col-md-4">
                                <div className="form-floating">
                                    <input type="text" name="name" className="form-control" placeholder='Patient Name' />
                                    <label className="focus-label">Patient Name</label>
                                </div>
                            </div>

                            <div className="col-sm-4 col-md-4">
                                <button type="submit" className="btn btn-primary btn-block"> Search </button>
                            </div>
                        </div>
                    </form>
                    <PatientTable patientList={patients} deletePatient={deletePatient} />
                    {
}
                </div>
                <ErrorDialogueBox
                    open={errorDialogueBoxOpen}
                    handleToClose={handleDialogueClose}
                    ErrorTitle="Error: Add Patient"
                    ErrorList={errorList}
                />
            </div>

        </Box>
    )
}

export default PatientList;
